import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { JwtService } from "src/app/utils/jwt/jwt.service";

@Component({
  selector: "app-deleted",
  templateUrl: "./deleted.component.html",
})
export class DeletedComponent implements OnInit {
  public answerAccept: boolean = false;
  private ngUnsubscribe = new Subject();
  public reCaptchaKey: string;
  private token: string;
  public errorKeyword: string = null;

  constructor(
    private jwtService: JwtService,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
/*
    this.token = this.route.snapshot.queryParamMap.get('token');
    setTimeout(() => {
      this.jwtService.authenticateByToken(
        this.token, ''
      ).subscribe(
        res => {
          if(res['status']=='OK'){
            this.errorKeyword = null;
            this.answerAccept = true;
            //this.goLogin();
          }
          else if(res['status'] == 'EXPIRED'){
            this.errorKeyword = 'Expired';
          }
    
        },
        error => {
          if (error.status == 401) {
            //this.errorKeyword = 'Invalid user or password';
          } else {
            this.errorKeyword = 'Something goes wrong, please try again later';
          }

        },
      );
    },
    1000);
*/
    
  }


  goLogin() {
    this.router.navigate(['/auth/login']);
  }




  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
