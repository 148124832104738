import { Component, OnInit } from "@angular/core";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedTripService } from "src/app/services/reserved-trip.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  collapseShow = "hidden";
  public reservedTrip:ReservedTrip;

  constructor(private rtService:ReservedTripService) {
   
  }

  ngOnInit() {
    this.reservedTrip = null;
    
    this.rtService.getLastItem().subscribe(
      res=> {
        this.reservedTrip = res
      }
    );
  }
  toggleCollapseShow(classes) {
    this.collapseShow = classes;
  }
}
