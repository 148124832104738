import { Inject, Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { JwtConfig, JwtServiceConfig } from "./jwt-config.interface";

@Injectable()
export class JwtNavigationService{

    constructor(
        @Inject(JwtServiceConfig) private config: JwtConfig,
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    public getUserUrl() {
        return this.config.host + this.config.userPath
    }

    public getLoginUrl(): string {
        return this.config.host + this.config.loginPath;
    }

    public getTokenLoginUrl(): string {
        return this.config.host + this.config.tokenLoginPath;
    }


    public getRefresTokenUrl(): string {
        return this.config.host + this.config.refreshTokenPath;
    }
    

    public navigateLoginRoute() {
        let url = this.router.url;
        
        /*
                let amountSlash = 0;
                let slashPosition = url.indexOf("/");
                while (slashPosition != -1 && amountSlash < 3) {
                    amountSlash++;
                    amountSlash = url.indexOf("/", slashPosition + 1);
                }
                let domain = null;
                if (slashPosition > -1) {
                    domain = url.substring(0, slashPosition);
                } else {
                    domain = url;
                }
        
             
                const loginRoute = domain + this.config.loginRoute;
        */

        if (
            (
                url.length < this.config.anomRoute.length ||
                url.substring(0, this.config.anomRoute.length) != this.config.anomRoute
            ) &&
            (
                url.length < this.config.loginRoute.length ||
                url.substring(0, this.config.loginRoute.length) != this.config.loginRoute
            )
        ) {

            this.router.navigate(
                [this.config.loginRoute],
                {
                    queryParams: { returnUrl: this.router.url }
                }

            );
        }
    }

    public navigateLanding() {
        let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        if (returnUrl) {
            this.navigateReturnUrl();
        } else {
            let url = this.router.url;
            if (
                url.length >= this.config.anomRoute.length &&
                url.substring(0, this.config.anomRoute.length) == this.config.anomRoute
            ) {
                this.router.navigate([this.config.landingRoute])
            }
        }
    }


    public navigateReturnUrl() {
        let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        /*
                let amountSlash = 0;
                let slashPosition = returnUrl.indexOf("/");
                while (slashPosition != -1 && amountSlash < 3) {
                    amountSlash++;
                    amountSlash = returnUrl.indexOf("/", slashPosition + 1);
                }
                let domain = null;
                if (slashPosition > -1) {
                    domain = returnUrl.substring(0, slashPosition);
                } else {
                    domain = returnUrl;
                }
    
                const loginRoute = domain + this.config.loginRoute;
        */
        if (
            returnUrl &&
            (
                returnUrl.length < this.config.loginRoute.length ||
                returnUrl.substring(0, this.config.loginRoute.length) != this.config.loginRoute
            )
        ) {
            this.router.navigate(
                [returnUrl]
            );
        }
    }
}