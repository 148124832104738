import { Component, Input, OnInit } from "@angular/core";
import { ReservedDay } from "src/app/models/reserved-day.model";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reserved-activity-card",
  templateUrl: "./reserved-activity-card.component.html",
})
export class ReservedActivityCardComponent implements OnInit {
  @Input()
  public day: ReservedDay;
  
  @Input()
  public stage: ReservedStage;

  @Input()
  public reservedTrip: ReservedTrip;

  @Input()
  public left = true;

  public downloadHost;

  constructor() {
    this.downloadHost = environment.urlApi;
  }

  ngOnInit(): void {
  }
}