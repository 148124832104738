import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from "@angular/core";
import { createPopper } from "@popperjs/core";
import { ClickService } from "src/app/services/click.service";
import { UserService } from "src/app/services/user.service";
import { JwtService } from "src/app/utils/jwt/jwt.service";
import { User } from "src/app/utils/jwt/user.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-user-dropdown",
  templateUrl: "./user-dropdown.component.html",
  styleUrls: ['./user-dropdown.component.css']
})
export class UserDropdownComponent implements OnInit, AfterViewInit {
  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;
  public user: User;
  public name: string = "";
  public firstLetter: string = "";
  public sending = false;
  public showSendConfirmation = false;
  public showConfirmDialog = false;
  public urlApi: string;

  constructor(
    private jwtService: JwtService,
    private userService: UserService,
    private clickService: ClickService
  ) {
    // this.user = this.jwtService.getCurrentUser();
    this.urlApi= environment.urlApi;
  }

  ngOnInit() {
    this.jwtService.getCurrentUserSubject().subscribe(
      res => {
        if (res) {
          this.user = res;
          this.name = this.user.firstName + ' ' + this.user.name;
          this.firstLetter = this.name.substring(0, 1);
        }
      }
    );


    this.clickService.$clickSubject.subscribe(
      () => {
        this.toggleDown();
      }
    );
  }

  logout() {
    this.jwtService.logout();
  }

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }

  toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dropdownPopoverShow) {
      this.toggleDown();
    } else {
      this.dropdownPopoverShow = true;
    }
  }

  toggleDown() {
    this.dropdownPopoverShow = false;
    this.showSendConfirmation = false;
    this.showConfirmDialog = false;
  }

  confirmResetPassword(event) {
    this.showConfirmDialog = true;
    event.preventDefault();
    event.stopPropagation();
  }


  resetPassword(event) {
    event.preventDefault();
    event.stopPropagation();
    this.userService.sendRecoverMail(this.user.username).subscribe(
      res => {
        this.sending=false;
        this.showConfirmDialog = false;
        this.showSendConfirmation = true;
      }
    )
  }
}
