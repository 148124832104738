import { BikeOption } from "./bike-option.model";
import { BikeOptionalItem } from "./bike-optional-item.model";
import { SupplierAccommodation } from "./supplier-accommodation.model";
import { SupplierRating } from "./supplier-rating.model";
import { SupplierRestaurant } from "./supplier-restaurant.model";

export class Supplier {
    public id: string;
    public name: string;
    public rate: string;
    public languagesString: string;
    public phoneArea: string;
    public phoneNumber: string;
    public phoneMobileArea: string;
    public phoneMobileNumber: string;
    public email1: string;
    public email2: string;
    public www: string;
    public address: string;
    public addressComplement: string;
    public postalCode: string;
    public latitude: string;
    public longitude: string;
    public latitudeCenterMap: string;
    public longitudeCenterMap: string;
    public zoomMapLevel: string;
    public pictureFileId: number;
    public voucherCommentKeyword: string;
    public ratings: Array<SupplierRating>;
    public dataAccommodation: SupplierAccommodation;
    public dataRestaurant: SupplierRestaurant;
    public dataBikeOptionalItems:Array<BikeOptionalItem>;
    public dataBikeOptions:Array<BikeOption>;
    public clientCollectionDropOff:boolean;
    
    private replaceRegexp = /\s*-\(\)/;

    getLatitude(restaurant = false) {
        if(!restaurant){
            if (
                this.dataAccommodation &&
                this.dataAccommodation.latitude
            ) {
                return this.dataAccommodation.latitude
            }
        } else {
            if (
                this.dataRestaurant &&
                this.dataRestaurant.latitude
            ) {
                return this.dataRestaurant.latitude
            }
        }
        
        if (this.latitudeCenterMap) {
            return this.latitudeCenterMap
        }
        if (this.latitude) {
            return this.latitude;
        }
    }

    getLongitude(restaurant=false) {

        if(!restaurant){
            if (
                this.dataAccommodation &&
                this.dataAccommodation.longitude
            ) {
                return  this.dataAccommodation.longitude
            }
        } else {
            if (
                this.dataRestaurant &&
                this.dataRestaurant.longitude
            ) {
                return this.dataRestaurant.longitude
            }
        }
        if (this.longitudeCenterMap) {
            return this.longitudeCenterMap
        }
        if (this.longitude) {
            return this.longitude;
        }
    }

    hasMobile() {
        return (this.phoneMobileNumber && !/^\s*$/.test(this.phoneMobileNumber));
    }

    getPhoneNumber() {
        return this.parsePhone(this.phoneArea, this.phoneNumber);
    }

    getPhoneMobileNumber() {
        return this.parsePhone(this.phoneMobileArea, this.phoneMobileNumber);
    }

    private parsePhone(areaCode, number) {
        if (number) {
            let phone = number.replace(this.replaceRegexp, '');

            if (!/^\s*$/.test(phone)) {
                if (areaCode) {
                    let area = areaCode.replace(this.replaceRegexp, '');

                    if (!/^\s*$/.test(area)) {
                        if (/^00\d{1,2}$/.test(area)) {
                            phone = area + '' + phone;
                        } else if (/^\+\d{1,2}$/.test(area)) {
                            phone = area + '' + phone;
                        } else if (/^\d{1,2}$/.test(area)) {
                            phone = '+' + area + '' + phone;
                        }
                    }
                }

                return phone;
            }

        }
        return null;
    }

    getWww() {
        if (this.www && !/^\s*$/.test(this.www)) {
            if (/^http(s)?:\/\/.+/.test(this.www)) {
                return this.www;
            } else {
                return 'https://' + this.www;
            }
        }
    }
}