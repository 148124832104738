import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { PhoneArea } from "src/app/models/phone-area.model";
import { CountryService } from "src/app/services/country.service";
import { PhoneAreaService } from "src/app/services/phone-area.service";
import { TranslateService } from "src/app/translate/translate.service";

@Component({
  selector: "app-phone-code-input",
  templateUrl: "./phone-code-input.component.html",
  styleUrls: ["./phone-code-input.component.css"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PhoneCodeInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PhoneCodeInputComponent
    }
  ]
})
export class PhoneCodeInputComponent implements OnInit, ControlValueAccessor, Validator {
  onChange = (quantity) => { };
  onTouched = () => { };
  touched = false;
  disabled = false;
  private value: PhoneArea;
  public form: FormGroup;

  @Input()
  public placeholder: string;

  @Input()
  public controlName: string;

  @Input()
  public group: FormGroup;

  @Input()
  public historyHeading: string = 'Recently selected';

  public data: Array<PhoneArea>;

  public searchKeyword = 'number';

  constructor(
    private phoneAreaService: PhoneAreaService,
    private translateService: TranslateService,
    private formBuilder: FormBuilder
  ) {


  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      code: [this.value]
    });

    this.form.controls.code.valueChanges.subscribe(
      res => {
        this.value = res;
        this.onChange(res);
      }
    );


    this.phoneAreaService.getList().subscribe(
      res => {
        if (res) {
          this.data = res.items;
          for (let i = 0; i < this.data.length; i++) {
            if (!this.data[i].number) {
              this.data[i].number = '';
            }
            if (!this.data[i].name || this.data[i].name == "") {
              this.data[i].setFlag();

              this.translateService.getItem(this.data[i].countryNameKeyword).subscribe(
                res => {
                  this.data[i].name = res;
                }
              )
            }
          }

        }

      }
    );

  }


  writeValue(value: PhoneArea) {
    this.value = value;
    if (this.form) {
      this.form.controls.code.setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const lcation = control.value;
    if (location == null) {
      return {
        emptyValue: true
      };
    }
  }
}
