import { ReservedStage } from "./reserved-stage.model";
import { RoomAllocation } from "./room-allocation.model";

export class RoomAllocationSelection {
    public dateIn: Date;
    public dateOut: Date;
    public nbNights: number;
    public oneProfileSelection: boolean;
    public stages: Array<ReservedStage>;
    public roomAllocations: Array<RoomAllocation>;
    public baseDropListIndex: number;

    public hasRoomType(type) {
        for (let r of this.roomAllocations) {
            if (r.type.name == type) {
                return true;
            }
        }
        return false;
    }


    public setBaseDropListIndex(index: number) {
        this.baseDropListIndex = index;
    }

    public getBaseDropListIndex() {
        return this.baseDropListIndex;
    }

    public isEditable() {
        for (let stage of this.stages) {
            if (stage.progression != 0 && stage.progression != 8) {
                return false;
            }
        }
        return true;
    }
}