import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { PassengersComponent } from "./views/admin/passengers/passengers.component";
// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";

// components for views and layouts

import { AdminNavbarComponent } from "./components/navbars/admin-navbar/admin-navbar.component";
import { AuthNavbarComponent } from "./components/navbars/auth-navbar/auth-navbar.component";
import { ReservedTripBarComponent } from "./components/reserved-trip-bar/reserved-trip-bar.component";
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { HeaderStatsComponent } from "./components/headers/header-stats/header-stats.component";
import { MapExampleComponent } from "./components/maps/map-example/map-example.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { UserDropdownComponent } from "./components/dropdowns/user-dropdown/user-dropdown.component";

import { TranslateConfig } from "./translate/translate-config.interface";
import { TranslateModule } from "./translate/translate.module";
import { LanguageDropdownComponent } from "./components/dropdowns/language-dropdown/language-dropdown.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { JwtModule } from "./utils/jwt/jwt.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LandingNavbarComponent } from "./components/navbars/landing-navbar/landing-navbar.component";
import { ReservedTripTableComponent } from "./components/reserved-trip-table/reserved-trip-table.component";
import { ItineraryComponent } from "./views/admin/itinerary/itinerary.component";
import { ReservedDayCardComponent } from "./components/reserved-day-card/reserved-day-card.component";
import { ReservedDayFullComponent } from "./components/reserved-day-full/reserved-day-full.component";
import { ReservedPeopleTransferCardComponent } from "./components/reserved-people-transfer-card/reserved-people-transfer-card.component";
import { ReservedAccommodationCardComponent } from "./components/reserved-accommodation-card/reserved-accommodation-card.component";
import { environment } from "src/environments/environment";
import { ReservedRestaurantCardComponent } from "./components/reserved-restaurant-card/reserved-restaurant-card.component";
import { ReservedGuideCardComponent } from "./components/reserved-guide-card/reserved-guide-card.component";
import { ReservedBikeCardComponent } from "./components/reserved-bike-card/reserved-bike-card.component";
import { ListItemMapsComponent } from "./components/list-item-maps/list-item-maps..component";
import { ReservedFlightCardComponent } from "./components/reserved-flight-card/reserved-flight-card.component";
import { ReservedActivityCardComponent } from "./components/reserved-activity-card/reserved-activity-card.component";
import { ReservedPassengerCardComponent } from "./components/reserved-passenger-card/reserved-passenger-card.component";
import { AngularMyDatePickerModule } from "angular-mydatepicker";
import { AutocompleteLibModule } from "angular-ng-autocomplete";
import { CountryInputComponent } from "./components/forms/country-input/country-input.component";
import { PhoneCodeInputComponent } from "./components/forms/phone-code-input/phone-code-input.component";
import { ReservedTripCardComponent } from "./components/reserved-trip-card/reserved-trip-card.component";
import { AcceptDenyComponent } from "./views/auth/accept-deny/accept-deny.component";
import { ResetPasswordComponent } from "./views/auth/reset-password/reset-password.component";
import { ExchangeModule } from "./exchange/exchange.module";
import { CurrencyDropdownComponent } from "./components/dropdowns/currency-dropdown/currency-dropdown.component";
import { AccommodationDropdownComponent } from "./components/dropdowns/accommodation-dropdown/accommodation-dropdown.component";
import { FirstTitleCasePipe } from "./pipes/first-title-case.pipe";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { RoomAllocationsComponent } from "./views/admin/room-allocations/room-allocations.component";
import { LoadingInterceptor } from "./utils/loading/loading.interceptor";
import { ErrorInterceptor } from "./utils/error.interceptor";
import { LocationInputComponent } from "./components/forms/location-input/location-input.component";
import { TaxiDropdownComponent } from "./components/dropdowns/taxi-dropdown/taxi-dropdown.component";
import { RoomAllocationPassengerCardComponent } from "./components/room-allocation-passenger-card/room-allocation-passenger-card.component";
import { RoomAllocationCardComponent } from "./components/room-allocation-card/room-allocation-card.component";
import { RoomAllocationSelectionCardComponent } from "./components/room-allocation-selection-card/room-allocation-selection-card.component";
import { DragDropModule,CDK_DRAG_CONFIG } from "@angular/cdk/drag-drop";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServicesComponent } from "./views/admin/services/services.component";
import { ReservedPassengerServicesCardComponent } from "./components/reserved-passenger-services-card/reserved-passenger-services-card.component";
import { PaymentComponent } from "./views/admin/payment/payment.component";
import { ReservedPassengerPayCardComponent } from "./components/reserved-passenger-pay-card/reserved-passenger-pay-card.component";
import { ForgotPasswordComponent } from "./views/auth/forgot-password/forgot-password.component";
import { HolidayPackPostComponent } from "./views/admin/holiday-pack-post/holiday-pack-post.component";
import { ToastrModule } from "ngx-toastr";
import { HolidayPackDownloadComponent } from "./views/admin/holiday-pack-download/holiday-pack-download.component";
import { InvoiceComponent } from "./views/admin/invoice/invoice.component";
import { ReservedPassengerInvoiceCardComponent } from "./components/reserved-passenger-invoice-card/reserved-passenger-invoice-card.component";
import { AuthTokenComponent } from "./views/auth/auth-token/auth-token.component";
import { EmergencyPhonesComponent } from "./components/emergency-phones/emergency-phones.component";
import { MapComponent } from "./components/map/map.component";
import { MapLayoutComponent } from "./layouts/map/map-layout.component";
import { ServiceWorkerModule } from '@angular/service-worker';
import { ReservedStageLuggageTransferCardComponent } from "./components/reserved-stage-luggage-transfer-card/reserved-stage-luggage-transfer-card.component";
import { DeletedComponent } from "./views/auth/deleted/deleted.component";
import { GroupsComponent } from "./views/groups/groups.component";
import { ReservedGroupDayCardComponent } from "./components/reserved-group-day-card/reserved-group-day-card.component";
import { WeatherWidgetComponent } from './components/weather-widget/weather-widget.component';
import { GalleryModule } from "ng-gallery";
import { LightboxModule } from "ng-gallery/lightbox";
import { CityCardComponent } from "./components/city-card/city-card.component";
import { CityViewComponent } from "./views/city/city-view.component";
import { CarrouselComponent } from "./components/carrousel/carrousel.component";
import { DeleteProfileComponent } from "./views/auth/delete-profile/delete-profile.component";


const translateConfig: TranslateConfig = {
  url: environment.urlApi+'/public/translations/',
  default: 'en'
}
const jwtConfig = {
  host: environment.urlApi,
  loginPath: '/api/login_check',
  tokenLoginPath: '/public/token_login',
  refreshTokenPath: '/api/token/refresh',
  userPath: '/api/user_info',
  loginRoute: '/auth/login',
  landingRoute: '/landing',
  anomRoute: '/auth/'
}
const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 100000
};

@NgModule({
  declarations: [
    AppComponent,
    UserDropdownComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    HeaderStatsComponent,
    MapExampleComponent,
    AuthNavbarComponent,
    AdminNavbarComponent,
    AdminComponent,
    AuthComponent,
    PassengersComponent,
    LoginComponent,
    RegisterComponent,
    LandingComponent,
    ProfileComponent,
    LanguageDropdownComponent,
    LandingNavbarComponent,
    ReservedTripTableComponent,
    ReservedTripBarComponent,
    ItineraryComponent,
    ReservedDayFullComponent,
    ReservedDayCardComponent,
    ReservedPeopleTransferCardComponent,
    ReservedAccommodationCardComponent,
    ReservedRestaurantCardComponent,
    ReservedGuideCardComponent,
    ReservedBikeCardComponent,
    ReservedFlightCardComponent,
    ReservedActivityCardComponent,
    ListItemMapsComponent,
    ReservedPassengerCardComponent,
    CountryInputComponent,
    PhoneCodeInputComponent,
    ReservedTripCardComponent,
    AcceptDenyComponent,
    ResetPasswordComponent,
    CurrencyDropdownComponent,
    AccommodationDropdownComponent,
    FirstTitleCasePipe,
    RoomAllocationsComponent,
    LocationInputComponent,
    TaxiDropdownComponent,
    RoomAllocationSelectionCardComponent,
    RoomAllocationPassengerCardComponent,
    RoomAllocationCardComponent,
    ServicesComponent,
    ReservedPassengerServicesCardComponent,
    PaymentComponent,
    ReservedPassengerPayCardComponent,
    ForgotPasswordComponent,
    HolidayPackPostComponent,
    HolidayPackDownloadComponent,
    ProfileComponent,
    InvoiceComponent,
    ReservedPassengerInvoiceCardComponent,
    AuthTokenComponent,
    EmergencyPhonesComponent,
    MapComponent,
    MapLayoutComponent,
    ReservedStageLuggageTransferCardComponent,
    DeletedComponent,
    GroupsComponent,
    ReservedGroupDayCardComponent,
    WeatherWidgetComponent,
    CityViewComponent,
    CityCardComponent,
    CarrouselComponent,
    DeleteProfileComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot(translateConfig),
    ExchangeModule,
    JwtModule.forRoot(jwtConfig),
    AngularMyDatePickerModule,
    AutocompleteLibModule,
    InfiniteScrollModule,
    DragDropModule,
    BrowserAnimationsModule,
    GalleryModule,
    LightboxModule,
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: CDK_DRAG_CONFIG, useValue: DragConfig }

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
