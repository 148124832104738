import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { JwtService } from "src/app/utils/jwt/jwt.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
})
export class ForgotPasswordComponent implements OnInit {
  public loginForm: FormGroup;
  public submited: boolean = false;
  private ngUnsubscribe = new Subject();
  public reCaptchaKey: string;
  public errorKeyword: string = null;
  public showConfirm = false;

  constructor(
    private authenticationService: JwtService, 
    private resetPasswordService: UserService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {


    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]]
    });
    this.loginForm.valueChanges.subscribe(
      res=>{
        this.showConfirm = false;
      }
    )
  }


  get f() { return this.loginForm.controls; }

  public submit() {
    this.submited = true;

    if (!this.loginForm.invalid) {

      this.errorKeyword = null;
      this.sendMail("");
      
      /*this.recaptchaV3Service.execute('login').subscribe(
        (token) => 
      );*/
    }
  }

  sendMail(token: string) {

    this.resetPasswordService.sendRecoverMail(
      this.f.username.value,
    ).subscribe(
      res => {

        this.showConfirm = true;
    
      },
      error => {

        if (error.status == 401) {
          this.errorKeyword = 'Invalid user or password';
        } else {
          this.errorKeyword = 'Something goes wrong, please try again later';
        }

      }
    )
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
