import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { RoomAllocation } from "src/app/models/room-allocation.model";
import { RoomAllocationSelectionService } from "src/app/services/room-allocation-selection.services";
import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';


@Component({
  selector: "app-room-allocation-card",
  templateUrl: "./room-allocation-card.component.html",
})
export class RoomAllocationCardComponent implements OnInit {

  @Input()
  public roomAllocation: RoomAllocation;

  @Input()
  public dropListId: number;

  @Input()
  public editable: boolean;


  @Output()
  public remove: EventEmitter<ReservedPassenger> = new EventEmitter();

  public numberUnallocated: number = 23;

  public numberAllocated: number = 0;

  public unallocated: Array<number>;

  public size: number = 0;

  constructor(private roomAllocationService: RoomAllocationSelectionService) {

  }

  ngOnInit() {

    this.numberAllocated = 0;
    if (this.roomAllocation.passengers) {
      this.numberAllocated = this.roomAllocation.passengers.length;
    }

    switch (this.roomAllocation.type.name) {
      case 'Single':
        this.size = 1;
        break;
      case 'Double':
        this.size = 2;
        break;
      case 'Twin':
        this.size = 2;
        break;
      case 'Triple':
        this.size = 3;
        break;
      case 'Dorm':
        this.size = 1;
        break;

    }

    this.numberUnallocated = this.size - this.numberAllocated;

    this.unallocated = [];

    for (let i = 0; i < this.numberUnallocated; i++) {
      this.unallocated.push(i + this.numberAllocated);
    }
  }

  removePassenger(passenger: ReservedPassenger) {
    this.roomAllocationService.remove(
      this.roomAllocation,
      passenger
    ).subscribe(
      res => {
        for (var i = 0; i < this.roomAllocation.passengers.length; i++) {
          if (this.roomAllocation.passengers[i] === passenger) {
            this.roomAllocation.passengers.splice(i, 1);
          }
        }

        this.numberAllocated--;
        this.numberUnallocated++;

        this.unallocated.push(this.numberUnallocated - this.numberAllocated);

        this.remove.emit(passenger);
      }
    );
  }

  availablePredicate= (item: CdkDragDrop<Array<ReservedPassenger>>) => {
    return this.numberUnallocated > 0;
  }

  dropped(event: CdkDragDrop<Array<ReservedPassenger>>) {
    if (this.numberUnallocated == 0) {
      console.log("this isn't happening today");
      return;
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      this.roomAllocationService.add(this.roomAllocation, event.item.data).subscribe(
        res => {
          this.numberUnallocated--;
          this.unallocated.shift();

          transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
          );
        }
      );


    }
  }


}
