import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AcceptDenyService } from "src/app/services/accept-deny.service";

@Component({
    selector: "app-accept-deny",
    templateUrl: "./accept-deny.component.html",
})
export class AcceptDenyComponent implements OnInit {
    public answerAccept = false;
    private answer:string;
    public answered = false;
    public expired = false;
    public resetForm: FormGroup;
    private passengerId: string = null;
    private token: string = null;
    public errorKeyword: string = null;
    public submited: boolean = false;
    public ok: boolean = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        private acceptDenyService: AcceptDenyService,
    ) {

    }

    ngOnInit(): void {


        this.passengerId = this.route.snapshot.paramMap.get('passenger');
        this.token = this.route.snapshot.queryParamMap.get('token');
        this.answer = this.route.snapshot.queryParamMap.get('answer');
        this.answerAccept = (this.answer == 'accept');

        this.resetForm = this.formBuilder.group({
            password: ['', [Validators.required]],//,Validators.email
            repassword: ['', [Validators.required]]
          });
          
        if(!this.answerAccept){
            
            this.submited = true;

            this.acceptDenyService.answer(
                this.passengerId,
                this.answer,
                this.token,
                this.f.password.value
              ).subscribe(
                res => {
                    if(res['status']=='OK'){
                        this.errorKeyword = null;
                        this.ok = true;
                        //this.goLogin();
                      }
                      else if(res['status'] == 'EXPIRED'){
                        this.errorKeyword = 'Expired';
                      }
                },
                error => {
                  if (error.status == 401) {
                    //this.errorKeyword = 'Invalid user or password';
                  } else {
                    
                  }

                  this.errorKeyword = 'Something goes wrong, please try again later';

                }
              );
        }
    }

    get f() { return this.resetForm.controls; }

    public submit() {
        this.submited = true;
    
        if (!this.resetForm.invalid) {
    
          if(this.f.password.value!=this.f.repassword.value){
            this.errorKeyword = 'Passwords don\' match';
            return;
          }
    
          this.errorKeyword = null;

    
          this.acceptDenyService.answer(
            this.passengerId,
            this.answer,
            this.token,
            this.f.password.value
          ).subscribe(
            res => {
              if(res['status']=='OK'){
                this.errorKeyword = null;
                this.ok = true;
              }
              else if(res['status'] == 'EXPIRED'){
                this.errorKeyword = 'Expired';
              }
            },
            error => {
              if (error.status == 401) {
                //this.errorKeyword = 'Invalid user or password';
              } else {
                this.errorKeyword = 'Something goes wrong, please try again later';
              }
            }
          )
        }
      }
    

    goLogin(){
        this.router.navigate(['/auth/login']);
    }
}