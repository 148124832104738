import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Allergy } from "../models/allergy.model";
import { Diet } from "../models/diet.model";
import { ListResult } from "../models/list-result.model";

@Injectable({ providedIn: 'root' })
export class DietService {
    private subjectList: BehaviorSubject<ListResult<Diet>> = new BehaviorSubject(null);
    private fetched = false;
    constructor(private http: HttpClient) {
    }

    getList(): Observable<ListResult<Diet>> {
        //const
        if (!this.fetched) {
            this.fetched = true;

            const params = {};// itemsPerPage: "230", page: "1" };

            this.http.get(environment.urlApi + '/public/diets/', { params: params }).pipe(map(
                res => {
                    const result = new ListResult<Diet>();

                    for (let i = 0; i < res['items'].length; i++) {
                        result.items.push(Object.assign(new Diet(), res['items'][i]));
                    }
                    result.totalItems = res['totalItems'];
                    return result;
                }
            )).subscribe(
                res=> this.subjectList.next(res)
            );
        }

        return this.subjectList;
    }
}