import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { GroupBookingMessage } from "../models/group-booking-message.model";
import { GroupBooking } from "../models/group-booking.model";
import { ListResult } from "../models/list-result.model";
import { LocationPoint } from "../models/location-point.model";
import { ReservedDay } from "../models/reserved-day.model";
import { ReservedTripService } from "./reserved-trip.service";

@Injectable({ providedIn: 'root' })

export class GroupBookingService {



    constructor(private http: HttpClient, private reservedTripService: ReservedTripService) {
    }

    getOne(id: number): Observable<GroupBooking> {

        return this.http.get(environment.urlApi + '/public/group_booking/' + id).pipe(map(
            res => {
                return this.unserializeItem(res);
            }
        ));
    }

    unserializeItem(item): GroupBooking {
        if (item.departureDate) {
            item.departureDate = new Date(this.tzString(item.departureDate));
        }

        if (item.message) {
            item.message = Object.assign(new GroupBookingMessage(), item.message);
        }

        if (item.days) {
            for (let j = 0; j < item.days.length; j++) {
                if (item.days[j].date) {
                    item.days[j].date = new Date(this.tzString(item.days[j].date));
                }
                if (item.days[j].accommodationStages) {
                    for (let k = 0; k < item.days[j].accommodationStages.length; k++) {
                        item.days[j].accommodationStages[k] = this.reservedTripService.unserializeStage(item.days[j].accommodationStages[k]);
                    }
                }
                if (item.days[j].peopleTransferStages) {
                    for (let k = 0; k < item.days[j].peopleTransferStages.length; k++) {
                        item.days[j].peopleTransferStages[k] = this.reservedTripService.unserializeStage(item.days[j].peopleTransferStages[k]);
                    }
                }
                if (item.days[j].luggageTransferStages) {
                    for (let k = 0; k < item.days[j].luggageTransferStages.length; k++) {
                        item.days[j].luggageTransferStages[k] = this.reservedTripService.unserializeStage(item.days[j].luggageTransferStages[k]);
                    }
                }
                item.days[j] = Object.assign(new ReservedDay(), item.days[j]);
            }
        }

        return Object.assign(new GroupBooking(), item);
    }

  tzString(dateString: string) {
    dateString = dateString.substring(0, 10);
    const date = new Date(dateString);

    const offsetMinutes = date.getTimezoneOffset(); // Get the timezone offset in minutes

    // Calculate the offset in hours and minutes
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;

    // Determine the sign of the offset
    const offsetSign = offsetMinutes < 0 ? '+' : '-';

    return (
      dateString +
      'T00:00:00' +
      offsetSign +
      this.padNumber(offsetHours, 2) +
      ':' +
      this.padNumber(offsetMinutesRemainder, 2)
    );
  }

    padNumber(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

}
