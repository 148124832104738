import { Injectable } from '@angular/core';
import { Position } from './position.model';

@Injectable({
    providedIn: 'root'
})
export class PositionService {
    private _isIOS;

    private _defaultOrientation: string;

    private _currentPosition = new Position();

    private _error = false;

    private _isCompassAvailable = false;

    private _callbacks = [];

    private _debugData = '';

    constructor() {
        this._isIOS = (
            navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
            navigator.userAgent.match(/AppleWebKit/)
        );

        if (screen.width > screen.height) {
            this._defaultOrientation = 'landscape';
        } else {
            this._defaultOrientation = 'portrait';
        }

        const geoOtions = {
            enableHighAccuracy: true,
            maximumAge: 30000,
            timeout: 27000
        };

        if (navigator.geolocation) {
            const wpid = navigator.geolocation.watchPosition(
                (event) => this.onPositionChange(event),
                () => this._error = true,
                geoOtions
            );

            if (this._isIOS) {
                if (DeviceOrientationEvent['requestPermission']) {
                    DeviceOrientationEvent['requestPermission']()
                        .then((response) => {
                            if (response === "granted") {
                                this._isCompassAvailable = true;
                                window.addEventListener("deviceorientation", (event) => this.onHeadingChange(event), true);
                            } else {
                                //this._error = true;
                            }
                        })
                        .catch(() => { });//this._error = true);
                }

            } else {
                window.addEventListener("deviceorientationabsolute", (event) => this.onHeadingChange(event), true);
            }

            // window.addEventListener(
            //     'deviceorientation',
            //     (event) => this.onHeadingChange(event)
            // );
        } else {
            this._error = true;
        }
    }

    get currentPosition() {
        return this._currentPosition;
    }

    get available() {
        return !this._error;
    }

    get compassAvailable() {
        return this._isCompassAvailable;
    }

    addListener(callback) {
        this._callbacks[this._callbacks.length] = callback;
    }

    removeListener(callback) {
        let index = 0;

        for (const c of this._callbacks) {
            if (c !== callback) {
                index++;
            }

            this._callbacks[index] = c;
        }
    }

    // called on device orientation change
    private onHeadingChange(event) {
        this._isCompassAvailable = true;

        //let heading = event.webkitCompassHeading || Math.abs(event.alpha - 360);

        let heading = event.alpha;

        this._debugData = 'alfa: ' + heading + '\n';

        if (typeof event.webkitCompassHeading !== 'undefined') {
            heading = event.webkitCompassHeading; //iOS non-standard
            this._debugData += 'webkitCompassHeading: ' + heading + '\n';
        }


        const orientation = this.getBrowserOrientation();

        if (typeof heading !== 'undefined' && heading !== null) { // && typeof orientation !== "undefined") {
            // we have a browser that reports device heading and orientation


            // if (this._debug) {
            //     this._debugOrientation.textContent = orientation;
            // }


            // what adjustment we have to add to rotation to allow for current device orientation
            let adjustment = 0;
            if (this._defaultOrientation === 'landscape') {
                adjustment -= 90;
            }

            if (typeof orientation !== 'undefined') {
                const currentOrientation = orientation.split('-');

                if (this._defaultOrientation !== currentOrientation[0]) {
                    if (this._defaultOrientation === 'landscape') {
                        adjustment -= 270;
                    } else {
                        adjustment -= 90;
                    }
                }

                if (currentOrientation[1] === 'secondary') {
                    adjustment -= 180;
                }
            }
            this._debugData += 'adjustment: ' + adjustment + '\n';
            heading = heading + adjustment;

            this._debugData += 'heading: ' + heading + '\n';
            this._currentPosition.heading = (heading < 0 ? 360 + heading : heading) * Math.PI / 180;

            this._debugData += 'position: ' + this._currentPosition.heading + '\n';

            for (const c of this._callbacks) {
                c(this._currentPosition);
            }
            //  positionHng.textContent = (360 - phase | 0) + "°";


            // apply rotation to compass rose
            // if (typeof rose.style.transform !== "undefined") {
            //     rose.style.transform = "rotateZ(" + positionCurrent.hng + "deg)";
            // } else if (typeof rose.style.webkitTransform !== "undefined") {
            //     rose.style.webkitTransform = "rotateZ(" + positionCurrent.hng + "deg)";
            // }
        } else {
            // device can't show heading

            // positionHng.textContent = "n/a";
            // this.showHeadingWarning();
        }
    }

    private onPositionChange(position) {
        this._currentPosition.latitude = position.coords.latitude;
        this._currentPosition.longitude = position.coords.longitude;
        for (const c of this._callbacks) {
            c(this._currentPosition);
        }
    }

    //https://github.com/lamplightdev/compass
    // browser agnostic orientation
    private getBrowserOrientation() {
        let orientation;

        if (screen.orientation && screen.orientation.type) {
            orientation = screen.orientation.type;
        } else {
            orientation = screen.orientation ||
                screen['mozOrientation'] ||
                screen['msOrientation'];
        }

        /*
          'portait-primary':      for (screen width < screen height, e.g. phone, phablet, small tablet)
                                    device is in 'normal' orientation
                                  for (screen width > screen height, e.g. large tablet, laptop)
                                    device has been turned 90deg clockwise from normal
          'portait-secondary':    for (screen width < screen height)
                                    device has been turned 180deg from normal
                                  for (screen width > screen height)
                                    device has been turned 90deg anti-clockwise (or 270deg clockwise) from normal
          'landscape-primary':    for (screen width < screen height)
                                    device has been turned 90deg clockwise from normal
                                  for (screen width > screen height)
                                    device is in 'normal' orientation
          'landscape-secondary':  for (screen width < screen height)
                                    device has been turned 90deg anti-clockwise (or 270deg clockwise) from normal
                                  for (screen width > screen height)
                                    device has been turned 180deg from normal
        */

        return orientation;
    }

    calcRadToPoint(latitude, longitude) {
        // Qibla geolocation
        const point = {
            lat: 21.422487,
            lng: 39.826206
        };
        // (180.0 / Math.PI) *
        const phiK = (point.lat * Math.PI) / 180.0;
        const lambdaK = (point.lng * Math.PI) / 180.0;
        const phi = (latitude * Math.PI) / 180.0;
        const lambda = (longitude * Math.PI) / 180.0;
        const psi =

            Math.atan2(
                Math.sin(lambdaK - lambda),
                Math.cos(phi) * Math.tan(phiK) -
                Math.sin(phi) * Math.cos(lambdaK - lambda)
            );
        return psi;
    }

    debugData() {
        return this._debugData;
    }
}
