import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-list-item-maps",
  templateUrl: "./list-item-maps.component.html",
})
export class ListItemMapsComponent implements OnInit {
  @Input()
  public text: string;

  public urlMaps: string;

  @Input()
  public longitude: number;

  @Input()
  public latitude: number;

  @Input()
  public textClass: string;

  @Input()
  public bgClass: string;

  constructor() { }

  ngOnInit(): void {
    if (this.latitude) {
      this.urlMaps = 'https://www.google.com/maps/search/?api=1&query=' + this.latitude + ',' + this.longitude;

    }
  }
}
