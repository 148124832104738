import { environment } from '../../../environments/environment';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Position } from '../../utils/position/position.model';
import { PositionService } from '../../utils/position/position.service';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import Feature from 'ol/Feature';
import OSM from 'ol/source/OSM';
import XYZ from 'ol/source/XYZ';
import Overlay from 'ol/Overlay';
import * as olProj from 'ol/proj';
import { defaults as defaultControls, ScaleLine, FullScreen } from 'ol/control.js';
import CircleStyle from 'ol/style/Circle';
import GPX from 'ol/format/GPX';
import KML from 'ol/format/KML';
import { TrackFile } from 'src/app/models/track-file.model';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Component({
    selector: 'osm-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

    private _currentPosition: Position;
    private _currentPoint: Point;

    private _currentZoom: number;

    private _isCenterCurrentPosition = true;

    private _centerPosition: Position;

    private _isCompassAvailable = false;

    private _isCompassLocked = false;

    private _iconCompassLocked: Style;
    private _iconCompassUnlocked: Style;
    private _pointer: Feature;

    private _isOrientationLockable: boolean;

    private _isOrientationLocked: boolean;

    private _isNightMode: boolean;

    private _clickPosition: Position;

    private _debugOrientation: string;

    private _debug = false;

    private _map: Map;

    private _maxX: number = Number.MIN_SAFE_INTEGER;
    private _minX: number = Number.MAX_SAFE_INTEGER;
    private _maxY: number = Number.MIN_SAFE_INTEGER;
    private _minY: number = Number.MAX_SAFE_INTEGER;

    public showControls = false;

    private _style = {
        'Point': new Style({
            image: new CircleStyle({
                fill: new Fill({
                    color: 'rgba(255,255,0,0.4)',
                }),
                radius: 5,
                stroke: new Stroke({
                    color: '#ff0',
                    width: 1,
                }),
            }),
        }),
        'LineString': new Style({
            stroke: new Stroke({
                color: '#2979ff',
                width: 4,
            }),
        }),
        'MultiLineString': new Style({
            stroke: new Stroke({
                color: '#2979ff',
                width: 4,
            }),
        }),
    };


    private _features: Array<any>;
    @Input()
    set layers(features: Array<any>) {

        if (this._features) {
            for (let f of this._features) {
                this._map.removeLayer(f);
            }
        }

        this._features = [];


        if (features) {
            for (let f of features) {
                f.getSource().getFeatures().forEach((feat: Feature) => {
                    let geometry = feat.getGeometry();

                    if (geometry instanceof Point) {

                        const xy = geometry.getCoordinates();
                        if (xy[0] >= this._maxX) {
                            this._maxX = xy[0]
                        }
                        if (xy[0] <= this._minX) {
                            this._minX = xy[0]
                        }
                        if (xy[1] >= this._maxY) {
                            this._maxY = xy[1]
                        }
                        if (xy[1] <= this._minY) {
                            this._minY = xy[1]
                        }
                    }
                });

                this._map.addLayer(f);

                this._features.push(
                    f
                );
            }

            const extend = [this._minX, this._maxX, this._minY, this._maxY];
            //this._map.getView().fit(extend, { size: this._map.getSize(), maxZoom: 20 });
            this.fit()
        }
    }

    private _tracks: Array<TrackFile>;
    @Input()
    set tracks(tracks: Array<TrackFile>) {
        this._tracks = tracks;

        if (this._tracks) {
            for (let t of this._tracks) {

                let format = null;
                if (t.type == 'GPX') {
                    format = new GPX();
                } else if (t.type == 'KML') {
                    format = new KML();
                }

                if (format && t.title && t.title != "") {
                    this._map.addLayer(new VectorLayer({
                        source: new VectorSource({
                            url: environment.urlApi + '/public/download/' + t.fileId,
                            format: format,
                        }),
                        style: (feature) => {
                            return this._style[feature.getGeometry().getType()];
                        },
                    }));
                }


            }
        }

    }

    @Input()
    public overlayId: string;

    @Output()
    public click = new EventEmitter<Feature>();


    @Output()
    public close = new EventEmitter<void>();

    //debería tener un MarkerService que devuelva los marcadores según la posicion
    constructor(
        public positionService: PositionService,
        private http: HttpClient
        // public markersService: MarkerServiceInterface
    ) {
        this._currentPosition = positionService.currentPosition;
        positionService.addListener((event) => this.updateCurrentPosition(event));
    }

    ngOnInit() {
        //call to



        document.addEventListener(
            "fullscreenchange",
            () => this.onFullscreenChange()
        );
        document.addEventListener(
            "webkitfullscreenchange",
            () => this.onFullscreenChange()
        );
        document.addEventListener(
            "mozfullscreenchange",
            () => this.onFullscreenChange()
        );
        document.addEventListener(
            "MSFullscreenChange",
            () => this.onFullscreenChange()
        );


        this._currentZoom = 10;

        const attributions =
            '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> ' +
            '<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>';

        this._map = new Map({
            target: 'map',
            controls: defaultControls({ attribution: false, zoom: true, rotateOptions: { label: '' } }), //.extend([]);
            layers: [
                new TileLayer({
                    source: new OSM()
                })
                /*,
                new TileLayer({
                    source: new XYZ({
                        attributions: attributions,
                        url: 'https://tile.openweathermap.org/map/precipitation_new/{z}/{x}/{y}.png?appid=' + environment.apiKeyOWM,
                    })
                })*/
            ],
            view: new View({
                center: olProj.fromLonLat(
                    [
                        -8.5463,
                        42.8804
                    ]
                ),
                zoom: this._currentZoom
            })
        });



        this._currentPoint = new Point(olProj.fromLonLat(
            [
                -8.5463,
                42.8804
            ]
        ));

        this._pointer = new Feature({
            geometry: this._currentPoint
        });


        this._iconCompassLocked = new Style({
            image: new Icon({
                crossOrigin: 'anonymous',
                imgSize: [40, 40],
                src: 'assets/icons/maps/my_position.svg',
            })
        })
        this._iconCompassUnlocked = new Style({
            image: new Icon({
                crossOrigin: 'anonymous',
                imgSize: [40, 40],
                src: 'assets/icons/maps/my_arrow.svg',
            })
        })
        this._pointer.setStyle(this._iconCompassLocked);

        const miPosLayer = new VectorLayer({
            source: new VectorSource({
                features: [this._pointer]
            })
        });


        this._map.addLayer(miPosLayer);

        this._centerPosition = new Position();
        this._centerPosition.longitude = -8.5463;
        this._centerPosition.latitude = 42.8804;
        this._centerPosition.heading = 0;

        this._map.on('pointerdrag', (event) => {
            const view = this._map.getView();

            const centerVector = this._map.getView().getCenter();
            const center = olProj.toLonLat(centerVector);

            this.updateCenter(
                center[0],
                center[1],
                view.getRotation()
            );

            this._isCenterCurrentPosition = false;

            this.lockCompass();
        });

        this._map.on('moveend', (event) => {
            const newZoom = this._map.getView().getZoom();

            if (this._currentZoom !== newZoom) {
                this._currentZoom = newZoom;
                this.updateMarkers();
            }
        });

        this._map.on("click", (e) => {
            let wasFire = false;
            this._map.forEachFeatureAtPixel(e.pixel, (f, layer) => {
                //do something
                if (!wasFire) {
                    if (f instanceof PointerEvent) {
                    }
                    else if (f instanceof Feature) {
                        this.click.emit(f);
                        wasFire = true;
                    }
                }
            })
        });

        if (this.overlayId) {
            const overlay = new Overlay({
                element: document.getElementById(this.overlayId),
                autoPan: true,
                autoPanAnimation: {
                    duration: 250
                }
            });
            this._map.addOverlay(overlay);
        }


    }

    get isCenterCurrentPosition() {
        return this._isCenterCurrentPosition;
    }

    updateCurrentPosition(position: Position): void {
        this._currentPosition.latitude = position.latitude;
        this._currentPosition.longitude = position.longitude;
        this._currentPosition.heading = position.heading;

        this._currentPoint.setCoordinates(olProj.fromLonLat(
            [
                position.longitude,
                position.latitude
            ]
        ));

        if (this._isCenterCurrentPosition) {
            this.moveTo(position.longitude, position.latitude, position.heading);
        }
    }

    closeButton() {
        this.close.emit();
    }

    fit() {
        this._map.getView().fit(
            [this._minX, this._minY, this._maxX, this._maxY],
            {
                padding: [50, 50, 50, 50]

            }
        );

        const coords = olProj.toLonLat([(this._minX + this._maxX) / 2, (this._minY + this._maxY) / 2]);

        this._isCenterCurrentPosition = false;
        this.lockCompass();
        this.moveTo(coords[0], coords[1], 0);
        this.showControls = true;
    }

    moveTo(longitude: number, latitude: number, heading: number) {

        this.updateCenter(
            longitude,
            latitude,
            heading
        );


        if (this._isCenterCurrentPosition) {
            this._currentPosition.longitude = longitude;
            this._currentPosition.latitude = latitude;
            this._currentPosition.heading = heading;
        }

        const view = this._map.getView();
        view.setCenter(
            olProj.fromLonLat(
                [
                    longitude,
                    latitude
                ]
            )
        );

        if (this.positionService.compassAvailable && !this._isCompassLocked) {
            if (heading) {
                view.setRotation(
                    heading
                );
            }
        }
    }

    updateCenter(longitude: number, latitude: number, heading: number): void {
        const updateMarkers = this._centerPosition.longitude !== longitude
            && this._centerPosition.latitude !== latitude;

        this._centerPosition.longitude = longitude;
        this._centerPosition.latitude = latitude;
        this._centerPosition.heading = heading;

        if (updateMarkers) {
            this.updateMarkers();
        }
        //muestro los markers visibles
    }

    updateMarkers() {
        //tendría que definir un área!!!

        const size = this._map.getView().calculateExtent(this._map.getSize());

        const coordArribaIzquierda = olProj.toLonLat([size[0], size[1]]);
        const coordAbajoDerecha = olProj.toLonLat([size[2], size[3]]);
        /*
                this._markers = this.markersService.get(
                    coordArribaIzquierda[0],
                    coordArribaIzquierda[1],
                    coordAbajoDerecha[0],
                    coordAbajoDerecha[1],
                    this._enabledTypes
                );
        */
        //Cuanto más zoom menos tengo que ampliar el buffer 
        // 0..20
        /*
                console.log(this._currentZoom);
                console.log(coordArribaIzquierda);
                console.log(coordAbajoDerecha);
                */
        /*

        
                var marker = new OpenLayers.Marker(lonlat);
                marker.id = "1";
                marker.events.register("click", marker, function () {
                    $('footer').hide();
                });
                */
    }


    center(): void {
        this._isCenterCurrentPosition = true;
        const view = this._map.getView();
        view.setCenter(
            olProj.fromLonLat(
                [
                    this._currentPosition.longitude,
                    this._currentPosition.latitude
                ]
            )
        );
        view.setZoom(17);

        this.updateCenter(
            this._currentPosition.longitude,
            this._currentPosition.latitude,
            this._currentPosition.heading
        );

        if (this.positionService.compassAvailable && !this._isCompassLocked) {
            if (this._currentPosition.heading) {
                view.setRotation(
                    this._currentPosition.heading
                );
            }
        } else {
            view.setRotation(0);
        }
    }

    get isCompassAvailable() {
        return this.positionService.compassAvailable;
    }

    get isCompassLocked() {
        return this._isCompassLocked;
    }

    lockCompass(): void {
        this._isCompassLocked = true;
        this._map.getView().setRotation(0);
        this._pointer.setStyle(this._iconCompassLocked);
    }

    //si llamamos a esta funcion debería estar la brujula disponible
    //Ademas el centro debería ser la posicion actual
    unlockCompass(): void {
        this._isCompassLocked = false;
        this._map.getView().setRotation(
            this._currentPosition.heading
        );

        this._pointer.setStyle(this._iconCompassUnlocked);
    }

    //https://github.com/lamplightdev/compass
    // browser agnostic orientation
    getBrowserOrientation() {
        let orientation;

        if (screen.orientation && screen.orientation.type) {
            orientation = screen.orientation.type;
        } else {
            orientation = screen.orientation ||
                screen['mozOrientation'] ||
                screen['msOrientation'];
        }

        /*
          'portait-primary':      for (screen width < screen height, e.g. phone, phablet, small tablet)
                                    device is in 'normal' orientation
                                  for (screen width > screen height, e.g. large tablet, laptop)
                                    device has been turned 90deg clockwise from normal
          'portait-secondary':    for (screen width < screen height)
                                    device has been turned 180deg from normal
                                  for (screen width > screen height)
                                    device has been turned 90deg anti-clockwise (or 270deg clockwise) from normal
          'landscape-primary':    for (screen width < screen height)
                                    device has been turned 90deg clockwise from normal
                                  for (screen width > screen height)
                                    device is in 'normal' orientation
          'landscape-secondary':  for (screen width < screen height)
                                    device has been turned 90deg anti-clockwise (or 270deg clockwise) from normal
                                  for (screen width > screen height)
                                    device has been turned 180deg from normal
        */

        return orientation;
    }


    browserUnlockOrientation() {
        if (screen.orientation && screen.orientation.unlock) {
            screen.orientation.unlock();
        } else if (screen['unlockOrientation']) {
            screen['unlockOrientation']();
        } else if (screen['mozUnlockOrientation']) {
            screen['mozUnlockOrientation']();
        } else if (screen['msUnlockOrientation']) {
            screen['msUnlockOrientation']();
        }
    }

    // browser agnostic document.fullscreenElement
    getBrowserFullscreenElement() {
        if (typeof document.fullscreenElement !== "undefined") {
            return document.fullscreenElement;
        } else if (
            document['webkitFullscreenElement'] &&
            typeof document['webkitFullscreenElement'] !== "undefined"
        ) {
            return document['webkitFullscreenElement'];
        } else if (
            document['mozFullScreenElement'] &&
            typeof document['mozFullScreenElement'] !== "undefined"
        ) {
            return document['mozFullScreenElement'];
        } else if (
            document['msFullscreenElement'] &&
            typeof document['msFullscreenElement'] !== "undefined"
        ) {
            return document['msFullscreenElement'];
        }
    }

    // browser agnostic document.documentElement.requestFullscreen
    browserRequestFullscreen() {
        if (
            document.documentElement.requestFullscreen
        ) {
            document.documentElement.requestFullscreen();
        } else if (
            document.documentElement['webkitRequestFullscreen']
        ) {
            document.documentElement['webkitRequestFullscreen']();
        } else if (
            document.documentElement['mozRequestFullScreen']
        ) {
            document.documentElement['mozRequestFullScreen']();
        } else if (
            document.documentElement['msRequestFullscreen']
        ) {
            document.documentElement['msRequestFullscreen']();
        }
    }

    // browser agnostic document.documentElement.exitFullscreen
    browserExitFullscreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (
            document['webkitExitFullscreen']
        ) {
            document['webkitExitFullscreen']();
        } else if (
            document['mozCancelFullScreen']
        ) {
            document['mozCancelFullScreen']();
        } else if (
            document['msExitFullscreen']
        ) {
            document['msExitFullscreen']();
        }
    }

    onFullscreenChange() {
        if (this._isOrientationLockable && this.getBrowserFullscreenElement()) {
            if (screen.orientation && screen.orientation.lock) {
                screen.orientation.lock(this.getBrowserOrientation()).then(function () {
                }).catch(function () {
                });
            }
        } else {
            this.lockOrientationRequest(false);
        }
    }

    toggleOrientationLockable(lockable) {
        this._isOrientationLockable = lockable;

        // if (isOrientationLockable) {
        //   btnLockOrientation.classList.remove("btn--hide");

        //   btnNightmode.classList.add("column-25");
        //   btnNightmode.classList.remove("column-33");
        //   btnMap.classList.add("column-25");
        //   btnMap.classList.remove("column-33");
        //   btnInfo.classList.add("column-25");
        //   btnInfo.classList.remove("column-33");
        // } else {
        //   btnLockOrientation.classList.add("btn--hide");

        //   btnNightmode.classList.add("column-33");
        //   btnNightmode.classList.remove("column-25");
        //   btnMap.classList.add("column-33");
        //   btnMap.classList.remove("column-25");
        //   btnInfo.classList.add("column-33");
        //   btnInfo.classList.remove("column-25");
        // }
    }

    checkLockable() {
        if (screen.orientation && screen.orientation.lock) {
            screen.orientation.lock(this.getBrowserOrientation()).then(function () {
                this.toggleOrientationLockable(true);
                this.browserUnlockOrientation();
            }).catch(function (event) {
                if (event.code === 18) { // The page needs to be fullscreen in order to call lockOrientation(), but is lockable
                    this.toggleOrientationLockable(true);
                    this.browserUnlockOrientation(); //needed as chrome was locking orientation (even if not in fullscreen, bug??)
                } else {  // lockOrientation() is not available on this device (or other error)
                    this.toggleOrientationLockable(false);
                }
            });
        } else {
            this.toggleOrientationLockable(false);
        }
    }

    lockOrientationRequest(doLock) {
        if (this._isOrientationLockable) {
            if (doLock) {
                this.browserRequestFullscreen();
                this.lockOrientation(true);
            } else {
                this.browserUnlockOrientation();
                this.browserExitFullscreen();
                this.lockOrientation(false);
            }
        }
    }

    lockOrientation(locked) {
        /*   if (locked) {
               btnLockOrientation.classList.add("active");
           } else {
               btnLockOrientation.classList.remove("active");
           }
   */
        this._isOrientationLocked = locked;
    }

    toggleOrientationLock() {
        if (this._isOrientationLockable) {
            this.lockOrientationRequest(!this._isOrientationLockable);
        }
    }

    setNightmode(on) {
        /*
                if (on) {
                    btnNightmode.classList.add("active");
                } else {
                    btnNightmode.classList.remove("active");
                }
        
                window.setTimeout(function () {
                    if (on) {
                        document.documentElement.classList.add("nightmode");
                    } else {
                        document.documentElement.classList.remove("nightmode");
                    }
                }, 1);
        */

        this._isNightMode = on;
    }

    toggleNightmode() {
        this.setNightmode(!this._isNightMode);
    }

    downloadTracks() {
        if (this._tracks) {
            for (let t of this._tracks) {
                
                if (t.type == 'GPX' || t.type == 'KML') {
                    
                    let fileName = 'follow_track_'+t.id+'.'+t.type;

                    this.http
                    .get(environment.urlApi + '/public/download/' + t.fileId, {
                        observe: 'response',
                        responseType: 'blob' as 'json',
                    })
                    .subscribe((response: HttpResponse<Blob>) => {
                        let downloadLink = document.createElement('a');
                        downloadLink.href = window.URL.createObjectURL(response.body);
        
                        if (
                            typeof window['webkit'] !== 'undefined' &&
                            window['webkit'] !== null &&
                            typeof window['webkit'].messageHandlers !== 'undefined' &&
                            window['webkit'].messageHandlers !== null &&
                            typeof window['webkit'].messageHandlers.sendBase64 !== 'undefined' &&
                            typeof window['webkit'].messageHandlers.sendBase64 !== null
                        ) {
                            var reader = new FileReader();
                            reader.readAsDataURL(response.body);
                            reader.onloadend = function () {
                                window['webkit'].messageHandlers.sendBase64.postMessage({
                                    base64: reader.result,
                                    mime: 'application/pdf',
                                    name: fileName,
                                });
                            };
        
                            return;
                        }
                        
                        downloadLink.setAttribute('download', fileName);
                        
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                    });
    
                }
            }
        }
    }
}
