import { Component, AfterViewInit, ViewChild, ElementRef, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { createPopper } from "@popperjs/core";
import { debounceTime } from "rxjs/operators";
import { ReservedDay } from "src/app/models/reserved-day.model";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ClickService } from "src/app/services/click.service";
import { ReservedTripService } from "src/app/services/reserved-trip.service";

@Component({
  selector: "app-taxi-dropdown",
  templateUrl: "./taxi-dropdown.component.html",
  styleUrls: ["taxi-dropdown.component.css"]
})
export class TaxiDropdownComponent implements OnInit, AfterViewInit {
  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  public showTimeDialog = false;
  public showFlightDialog = false;
  public showRemoveDialog = false;

  @Input()
  public day: ReservedDay;

  @Input()
  public stage: ReservedStage;

  @Input()
  public reservedTrip: ReservedTrip;

  public formTime: FormGroup;
  public controlsTime: FormArray;

  public formFlight: FormGroup;
  public controlsFlight: FormArray;

  public formRemove: FormGroup;
  public controlsRemove: FormArray;

  public onlyOneStage: boolean;

  public newQuote: boolean;

  public sending = false;

  constructor(
    private formBuilder: FormBuilder,
    private reservedTripService: ReservedTripService,
    private clickService: ClickService
  ) {
  }

  ngOnInit(): void {

    
    this.clickService.$clickSubject.subscribe(
      () => {
        this.toggleDown();
      }
    );
  
    if(this.day){
      this.onlyOneStage = this.day.peopleTransferStages.length == 1;
    } else{
      this.onlyOneStage = true;
    }

    this.newQuote = (this.reservedTrip.bookingStatus =='NewQuote');
    
    const controlsTime = []
    const controlsFlight = [];
    const controlsRemove = [];
    if(this.day){
      for (let stage of this.day.peopleTransferStages) {

        controlsTime.push(
          this.formBuilder.group({
            hour: ['', [Validators.min(0), Validators.max(23)]],
            minute: ['', [Validators.min(0), Validators.max(59)]]
          })
        );

        controlsFlight.push([""]);
        controlsRemove.push([false]);
      }
    } else {
      controlsTime.push(
        this.formBuilder.group({
          hour: ['', [Validators.min(0), Validators.max(23)]],
          minute: ['', [Validators.min(0), Validators.max(59)]]
        })
      );

      controlsFlight.push([""]);
      controlsRemove.push([false]);
    }

    this.formTime = this.formBuilder.group({
      timeArray: this.formBuilder.array(controlsTime)
    });
    this.controlsTime = <FormArray>this.formTime.controls['timeArray'];


    for (let control of this.controlsTime.controls) {
      const c =  control as FormGroup;
      
      c.controls.minute.valueChanges.pipe(debounceTime(1000)).subscribe(
        res=>{
          if(res<10){
            c.controls.minute.setValue( '0'+res, { emitEvent: false })
          }
        }
      );

    }

    this.formFlight = this.formBuilder.group({
      flightArray: this.formBuilder.array(controlsFlight)
    });
    this.controlsFlight = <FormArray>this.formFlight.controls['flightArray'];

    this.formRemove = this.formBuilder.group({
      removeArray: this.formBuilder.array(controlsRemove)
    });
    this.controlsRemove = <FormArray>this.formRemove.controls['removeArray'];
    this.updateControls();
  }

  updateControls()
  {

    if(this.day){
      let index = 0;
      for (let stage of this.day.peopleTransferStages) {
        
        stage.dataPeopleTransfer.flightNumber.subscribe(
          res=>{
            this.controlsFlight.controls[index].setValue(res,{eventEmmit:false});
          }
        )

        const c =  this.controlsTime.controls[index] as FormGroup;
          
        stage.dataPeopleTransfer.pickupTime.subscribe(
          res=>{
            c.controls.hour.setValue(res?res.getUTCHours():null,{eventEmmit:false});
            c.controls.minute.setValue(res?res.getUTCMinutes():null,{eventEmmit:false});
          }
        );
  
        index++;
      }
    } else {
      this.controlsFlight.controls[0].setValue(this.stage.dataPeopleTransfer.flightNumber,{emitEvent:false})
        
      this.stage.dataPeopleTransfer.flightNumber.subscribe(
        res=>{
          this.controlsFlight.controls[0].setValue(res,{eventEmmit:false});
        }
      )
        const c =  this.controlsTime.controls[0] as FormGroup;
          
        this.stage.dataPeopleTransfer.pickupTime.subscribe(
          res=>{
            c.controls.hour.setValue(res?res.getUTCHours():null,{eventEmmit:false});
            c.controls.minute.setValue(res?res.getUTCMinutes():null,{eventEmmit:false});
          }
        );
    }
    
  }


  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }
  toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dropdownPopoverShow) {
      this.toggleDown();
    } else {
      this.dropdownPopoverShow = true;
    }
    
  }

  toggleDown(){
    this.dropdownPopoverShow = false;
  }

  toggleShowTimeDialog(event) {
    this.showTimeDialog = !this.showTimeDialog;
    this.toggleDropdown(event);
  }

  toggleShowFlightDialog(event) {
    this.showFlightDialog = !this.showFlightDialog;
    this.toggleDropdown(event);
  }
  toggleShowRemoveDialog(event) {
    this.showRemoveDialog = !this.showRemoveDialog;
    this.toggleDropdown(event);
  }


  changeTime() {
    if(!this.sending && this.formTime.dirty){
      this.sending = true;

      let index = 0;
      let data = { stages: [], event: 'pickupTime'};
      if(this.day){
        for (let stage of this.day.peopleTransferStages) {
          const c =  this.controlsTime.controls[index] as FormGroup;
    
          if(c.controls.hour.value && c.controls.minute.value){
            data.stages.push({
              id: stage.id,
              hour: c.controls.hour.value,
              minute: c.controls.minute.value,
            });
          }
          index++;
        }  
      } else{
        const c =  this.controlsTime.controls[0] as FormGroup;
    
          if(c.controls.hour.value && c.controls.minute.value){
            data.stages.push({
              id: this.stage.id,
              hour: c.controls.hour.value,
              minute: c.controls.minute.value,
            });
          }
      }
       
      this.reservedTripService.setPickupTime(this.reservedTrip.id,data).subscribe(
        res=>{
          this.showTimeDialog = false;
          this.sending = false;
        //  this.update.emit(data);
          if(this.day){
            let index = 0;
            for (let stage of this.day.peopleTransferStages) {
              const pickupTime = new Date();
              pickupTime.setUTCHours(data.stages[index].hour);
              pickupTime.setUTCMinutes(data.stages[index].minute);
              stage.dataPeopleTransfer.pickupTime.next(pickupTime);
              index++;
            }
          }
          else{
            const pickupTime = new Date();
            pickupTime.setUTCHours(data.stages[0].hour);
            pickupTime.setUTCMinutes(data.stages[0].minute);
            this.stage.dataPeopleTransfer.pickupTime.next(pickupTime);
          }
        },
        err=>{
          this.showTimeDialog = false;
          this.sending = false;
          //reemit value of time

          if(this.day){
            let index = 0;
            for (let stage of this.day.peopleTransferStages) {
              stage.dataPeopleTransfer.pickupTime.next(data.stages[index].dataPeopleTransfer.pickupTime.getValue());
              index++;
            }
          }
          else{
            this.stage.dataPeopleTransfer.pickupTime.next(data.stages[0].dataPeopleTransfer.pickupTime.getValues());
          }
        }
      );
    }
    

  }

  changeFlight() {
    if(!this.sending &&this.formFlight.dirty){
      this.sending = true;

      let index = 0;
      let data = { stages: [], event: 'flightNumber' };
      if(this.day){
        for (let stage of this.day.peopleTransferStages) {
          data.stages.push({
            id: stage.id,
            flightNumber: this.controlsFlight.controls[index].value,
          });
          index++;
        }
      } else {
        data.stages.push({
          id: this.stage.id,
          flightNumber: this.controlsFlight.controls[0].value,
        });
      }
      
      
      this.reservedTripService.setFlightNumber(this.reservedTrip.id,data).subscribe(
        res=>{
          this.showFlightDialog = false;
          this.sending = false;
          if(this.day){
            let index = 0;
            for (let stage of this.day.peopleTransferStages) {
              stage.dataPeopleTransfer.flightNumber.next(data.stages[index].flightNumber);
              index++;
            }
          } else{
            this.stage.dataPeopleTransfer.flightNumber.next(data.stages[index].flightNumber);
          }
        },
        err=>{
          this.showFlightDialog = false;
          this.sending = false;

          if(this.day){
            let index = 0;
            for (let stage of this.day.peopleTransferStages) {
              stage.dataPeopleTransfer.flightNumber.next(stage.dataPeopleTransfer.flightNumber.getValue());
              index++;
            }
          } else{
            this.stage.dataPeopleTransfer.flightNumber.next(this.stage.dataPeopleTransfer.flightNumber.getValue());
          }
        }
      );
  
    }
    
  }

  removeTaxi() {

    if(!this.sending){
      this.sending = true;

      let index = 0;
      let data = { stages: [], event: "remove" };
  
      if(this.day){
        for (let stage of this.day.peopleTransferStages) {
          if(this.onlyOneStage || this.controlsRemove.controls[index].value){
            data.stages.push(
              stage.id
            );
          }
          index++;
        }
      } else {
        data.stages.push(
          this.stage.id
        );
      }
      
      
      this.reservedTripService.removeStages(this.reservedTrip.id,data).subscribe(
        res=>{
          this.showRemoveDialog = false;
          this.sending = false;
        },
        err=>{
          this.showRemoveDialog = false;
          this.sending = false;
        }
      );
    }
    
  }
}
