import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable} from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ListResult } from "../models/list-result.model";
import { LocationPoint } from "../models/location-point.model";

@Injectable({ providedIn: 'root' })
export class LocationPointService {

    constructor(private http: HttpClient) {
    }

    getList(where: string): Observable<ListResult<LocationPoint>> {
        const params = { itemsPerPage: "50", page: "1", where: where };

        return this.http.get(environment.urlApi + '/api/location_point', { params: params }).pipe(map(
            res => {
                const result = new ListResult<LocationPoint>();

                for (let i = 0; i < res['items'].length; i++) {
                    result.items.push(Object.assign(new LocationPoint(), res['items'][i]));
                }
                result.totalItems = res['totalItems'];
                return result;
            }
        ));
    }

}