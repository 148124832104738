import { Component, OnInit } from "@angular/core";
import isWebview from "is-ua-webview";

@Component({
  selector: "app-emergency-phones",
  templateUrl: "./emergency-phones.component.html",
  styleUrls: ["./emergency-phones.component.css"]
})
export class EmergencyPhonesComponent implements OnInit {
  public open: boolean = false;
  public isWebView: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.isWebView = isWebview(window.navigator.userAgent);

    if (
      typeof window['webkit'] !== 'undefined' && window['webkit'] !== null &&
      typeof window['webkit'].messageHandlers !== 'undefined' && window['webkit'].messageHandlers !== null &&
      typeof window['webkit'].messageHandlers.sendBase64 !== 'undefined' &&
      typeof window['webkit'].messageHandlers.sendBase64 !== null
    ) {
      this.isWebView = true;
    }
  }

  switchOpen() {
    this.open = !this.open;
    
  }
}
