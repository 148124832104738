import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { ReservedPassengerService } from "src/app/services/reserved-passenger.service";

import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { BikeOptionalItem } from "src/app/models/bike-optional-item.model";
import { animate, style, transition, trigger } from "@angular/animations";

// other imports are here...
@Component({
  selector: "app-reserved-passenger-services-card",
  templateUrl: "./reserved-passenger-services-card.component.html",
  styleUrls: ["./reserved-passenger-services-card.component.css"],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        transition(
          ':enter', 
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out', 
                    style({ height: '*', opacity: 1 }))
          ]
        ),
        
        transition(
          ':leave', 
          [
            style({ height: '*', opacity: 1 }),
            animate('1s ease-in', 
                    style({ height: 0, opacity: 0 }))
          ]
        )
        
      ]
    )
  ]
})
export class ReservedPassengerServicesCardComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public modified = false;

  @Input()
  public passenger: ReservedPassenger;

  @Input()
  public stages: Array<ReservedStage> = [];
  public bikeStages: Array<ReservedStage> = [];
  public commonStages: Array<ReservedStage> = [];

  public form: FormGroup;

  public isLeader: boolean;
  public isMe: boolean;
  public isEditable: boolean;
  
  public sending = false;
  public stageControls: Array<FormControl> = [];
  public bikeOptionControls: Array<FormControl> = [];
  public bikeOptionalItemsControls: Array<Array<FormControl>> = [];


  constructor(
    private formBuilder: FormBuilder,
    private passengerService: ReservedPassengerService

  ) {

  }

  ngOnInit(): void {

    this.bikeStages = [];
    this.commonStages = [];
    this.stageControls = [];
    this.bikeOptionControls = [];
    this.bikeOptionalItemsControls = [];

    this.isLeader = this.passenger.number == 1;
    this.isMe = this.passenger.me;
    this.isEditable = this.passenger.isEditable;

    if (this.isMe || this.isEditable) {


      this.form = this.formBuilder.group({});


      let control: FormControl = null;
      for (let s of this.stages) {

        control = new FormControl('stage-' + s.id, Validators.required);
        this.form.addControl('stage-' + s.id, control);
        this.stageControls.push(control);
        control.valueChanges.subscribe(controlValue => {
          if (controlValue) {
            this.passengerService.addStage(this.passenger.id, s.id).subscribe(
              res => {
                this.passenger = res;
                this.updateControls();
              }
            );
          }
          else{
            this.passengerService.removeStage(this.passenger.id, s.id).subscribe(
              res => {
                this.passenger = res;
                this.updateControls();
              }
            );
          }
        });

        if (s.type != 9) {
          this.commonStages.push(s);
        }
        else {
          this.bikeStages.push(s);

          control = new FormControl('bikeOption-' + this.bikeOptionControls.length, Validators.required);

          this.form.addControl('bikeOption-' + this.bikeOptionControls.length, control);
          this.bikeOptionControls[this.bikeOptionControls.length] = control;
          control.valueChanges.subscribe(controlValue => {

            this.passengerService.setBikeOption(this.passenger.id, s.id, controlValue).subscribe(
              res => {
                this.passenger = res;
                this.updateControls();
              }
            )
          });

          const stageControls: Array<FormControl> = [];
          for (let boi of s.supplier.dataBikeOptionalItems) {

            control = new FormControl('bikeOptionalItem-' + this.bikeOptionControls.length + ' ' + stageControls.length);
            control.setValue(false);
            this.form.addControl('bikeOptionalItem-' + this.bikeOptionControls.length + ' ' + stageControls.length, control);
            control.valueChanges.subscribe(controlValue => {
              if (controlValue) {
                this.passengerService.addBikeOptionalItem(this.passenger.id, s.id, boi.id).subscribe(
                  res => {
                    this.passenger = res;
                    this.updateControls();
                  }
                );
              } else {
                this.passengerService.delBikeOptionalItem(this.passenger.id, s.id, boi.id).subscribe(
                  res => {
                    this.passenger = res;
                    this.updateControls();
                  }
                )
              }

            });
            stageControls[stageControls.length] = control;
          }

          this.bikeOptionalItemsControls[this.bikeOptionalItemsControls.length] = stageControls;
        }
        
      }

      this.updateControls();

      this.form.valueChanges.pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        res => {
          this.modified = true;

        }
      );

    }

  }

  get c() {
    return this.form.controls;
  }

  updateControls() {

    if (this.isMe || this.isEditable) {
      

      let stageIndex = 0;
      for (let s of this.stages) {

        this.stageControls[stageIndex].setValue(false, { emitEvent: false });

        for(let ps of this.passenger.stages){
          if(ps.id +'' == s.id){
            this.stageControls[stageIndex].setValue(true, { emitEvent: false });
            break;
          }
        }

        stageIndex++;
      }

      stageIndex = 0;
      for (let s of this.bikeStages) {
        for (let bikeOption of this.passenger.bikeOptions) {
          if (bikeOption.stageId == s.id) {
            this.bikeOptionControls[stageIndex].setValue(bikeOption.bikeOptionId, { emitEvent: false });
            break;
          }
        }

        let optionalItemIndex = 0;
        for (let boi of s.supplier.dataBikeOptionalItems) {

          this.bikeOptionalItemsControls[stageIndex][optionalItemIndex].setValue(false, { emitEvent: false });

          for (let bikeOptionalItem of this.passenger.bikeOptionalItems) {
            if (
              bikeOptionalItem.stageId == s.id &&
              bikeOptionalItem.optionalItemId == boi.id
            ) {
              this.bikeOptionalItemsControls[stageIndex][optionalItemIndex].setValue(true, { emitEvent: false });
              break;
            }
          }

          optionalItemIndex++;
        }

        stageIndex++;
      }

    }
  }

  enableStage(stage: ReservedStage, event: Event) {
    if(stage.isEditable()){
      this.form.controls['stage-'+stage.id].setValue(true)
    }
    event.preventDefault()
  }

  disableStage(stage: ReservedStage, event: Event) {
    if(stage.isEditable()){
      this.form.controls['stage-'+stage.id].setValue(false)
    }
    event.preventDefault()
  }

  /*
  updateModel() {
    if (this.passenger.isEditable || this.isMe) {
      this.sending = true;


      if (this.isMe) {
      }

      this.passengerService.update(this.passenger).subscribe(
        res => {
          this.passenger = res;
          this.updateControls();
          this.sending = false;
        }
      )
    }
  }



  save() {
    if (
      !this.sending && !this.form.invalid && this.form.dirty &&
      (this.passenger.isEditable || this.isMe)
    ) {
      this.updateModel();
    }
  }
  */

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}