import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { City } from "../models/city.model";
import { map } from "rxjs/operators";
import { LocationPoint } from "../models/location-point.model";
import { CityFile } from "../models/city-file.model";

@Injectable({ providedIn: 'root' })
export class CityService {
    constructor(private http: HttpClient) {
    }

    getOne(id: string): Observable<City> {
        return this.http.get(environment.urlApi + '/public/cities/' + id).pipe(map(
            res => {
                return this.unserialize(res);
            }
        ))
    }

    unserialize(item) {
        if (item.locationPoint) {
            
            item.locationPoint = Object.assign(
                new LocationPoint(),
                item.locationPoint
            );
        }

        if (item.files) {

            for (let j = 0; j < item.files; j++) {
                item.files[j] = Object.assign(
                    new CityFile(),
                    item.files[j]
                );
            }
        }

        return Object.assign(new City(), item);
    }
}