import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { ReservedDay } from "src/app/models/reserved-day.model";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";

@Component({
  selector: "app-reserved-people-transfer-card",
  templateUrl: "./reserved-people-transfer-card.component.html",
})
export class ReservedPeopleTransferCardComponent  {
  @Input()
  public day: ReservedDay;

  @Input()
  public stage: ReservedStage;

  @Input()
  public reservedTrip: ReservedTrip;

  @Input()
  public left = true;

  constructor(
  ) { }

}