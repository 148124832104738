import { ViewportScroller } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ReservedDay } from "src/app/models/reserved-day.model";
import {
  ReservedStage,
  ReservedStageType,
} from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
/*
export enum ReservedStageType {
  Accommodation = 1,
  PeopleTransfer = 2,
  Other = 4,
  Guide = 3,
  Activity = 6,
  Meal = 7,
  Flight = 8,
  Bike = 9,
  LuggageTransfer = -1,
  Walking = -2
}
*/
@Component({
  selector: "app-itinerary",
  templateUrl: "./itinerary.component.html",
})
export class ItineraryComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  // public widgetId = 'widgetFtcHistoricalWeatherMap';

  public reservedTrip: ReservedTrip;
  public showDays = true;
  public nextDayIndex: number = null;
  public previousDayIndex: number = null;
  public dayIndex: number = null;
  public day: ReservedDay;

  public timingStages: Array<ReservedStage>;
  public accommodationStages: Array<ReservedStage>;
  public bikeStages: Array<ReservedStage>;
  public otherStages: Array<ReservedStage>;
  public guideStages: Array<ReservedStage>;
  public activityStages: Array<ReservedStage>;
  private subscribed: boolean = false;
  private rt: ReservedTrip;

  constructor(
    private route: ActivatedRoute,
    private reservedTripService: ReservedTripService,
    private cdRef: ChangeDetectorRef,
    private scroll: ViewportScroller
  ) {}

  ngOnInit() {
    this.reservedTrip = null;

    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((queryParams) => {
        if (queryParams["day"]) {
          this.dayIndex = parseInt(queryParams["day"], 10);
          this.showDays = false;
          if (this.reservedTrip) {
            this.initDay();
          }
        } else {
          this.showDays = true;
        }
      });

    this.reservedTripService
      .getLastItem()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res) {
          this.rt = res;

          this.reservedTripService
            .getItinerary(this.rt.id)
            .pipe(takeUntil(this.destroy$))
            .subscribe((r) => {
              this.reservedTrip = r;
              if (!this.showDays) {
                this.initDay();
              }
            });
        }
      });
  }
  /*
  private loadScripts() {
    let isFound = false;
    const dynamicScripts = ["https://weather.followthecamino.com/widgets/js/get_weather.js"];
    const toLoad = [];

    const scripts = document.getElementsByTagName("script")

    for (let dynScript of dynamicScripts) {
      isFound = false;
      for (var i = 0; i < scripts.length; ++i) {

        if (
          scripts[i].getAttribute('src') != null &&
          scripts[i].getAttribute('src') == dynScript
        ) {
          isFound = true;
          break;
        }
      }

      if (!isFound) {
        toLoad.push(dynScript);
      }
    }

    for (let script of toLoad) {
      let node = document.createElement('script');
      node.src = script;
      node.type="module"; 
      node.id = this.widgetId;
      //node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementById('wheatherForecast').appendChild(node);
    }
  }
*/
  private initDay() {
    this.day = this.reservedTrip.days[this.dayIndex - 1];

    this.nextDayIndex = null;
    if (this.dayIndex < this.reservedTrip.days.length - 1) {
      this.nextDayIndex = this.dayIndex + 1;
    }
    this.previousDayIndex = null;
    if (this.dayIndex > 1) {
      this.previousDayIndex = this.dayIndex - 1;
    }

    this.accommodationStages = [];
    this.bikeStages = [];
    this.timingStages = [];
    this.otherStages = [];
    this.guideStages = [];
    this.activityStages = [];

    const dayString =
      this.day.date.getFullYear() +
      " " +
      this.day.date.getMonth() +
      " " +
      this.day.date.getDate();

    for (let stage of this.reservedTrip.stages) {
      const dateInString =
        stage.dateIn.getFullYear() +
        " " +
        stage.dateIn.getMonth() +
        " " +
        stage.dateIn.getDate();

      let dateOutString = null;
      if (
        stage.dateOut &&
        (stage.type == ReservedStageType.Accommodation ||
          stage.type == ReservedStageType.Bike ||
          stage.type == ReservedStageType.Flight ||
          stage.type == ReservedStageType.Guide)
      ) {
        dateOutString =
          stage.dateOut.getFullYear() +
          " " +
          stage.dateOut.getMonth() +
          " " +
          stage.dateOut.getDate();
      }

      if (dateInString == dayString) {
        this.appendStage(stage);
      } else if (dateOutString == dayString) {
        if (stage.type != ReservedStageType.Accommodation) {
          this.appendStage(stage);
        }
        //is stage is accommodation dont append
      } else if (
        stage.dateIn < this.day.date &&
        stage.dateOut > this.day.date
      ) {
        this.appendStage(stage);
      }
    }

    this.timingStages.sort((a: ReservedStage, b: ReservedStage) => {
      if (a.time > b.time) {
        return 1;
      } else if (a.time < b.time) {
        return 1;
      }
      return 0;
    });
  }

  private appendStage(stage: ReservedStage) {
    switch (stage.type) {
      case ReservedStageType.Accommodation:
        this.accommodationStages.push(stage);
        break;
      case ReservedStageType.Activity:
        this.activityStages.push(stage);
        break;
      case ReservedStageType.Bike:
        this.bikeStages.push(stage);
        break;
      case ReservedStageType.Guide:
        this.guideStages.push(stage);
        break;
      case ReservedStageType.Other:
        this.otherStages.push(stage);
        break;
      case ReservedStageType.Flight:
        this.timingStages.push(stage);
        break;
      case ReservedStageType.Meal:
        this.timingStages.push(stage);
        break;
      case ReservedStageType.PeopleTransfer:
        this.timingStages.push(stage);
        break;
    }
  }

  scrollDown() {
    this.scroll.scrollToPosition([0, document.body.scrollHeight]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
