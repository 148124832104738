
import { HolidayPackItemFileName } from "./holiday-pack-item-file-name.model";
import { HolidayPackItemLink } from "./holiday-pack-item-link.model";
import { HolidayPackItemType } from "./holiday-pack-item-type.model";
import { Language } from "./language.model";

export class HolidayPackItem {
    public id: number;
    public active: boolean;
    public name: string;
    public areaCover: string;
    public price: number;
    public isbn: string;
    public mapEditor:string;
    public reference: string;
    public packBasis: string;
    public scale: string;
    public language: Language;
    public type: HolidayPackItemType;
    public link: HolidayPackItemLink;
    public fileName: HolidayPackItemFileName;
}