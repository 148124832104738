import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ListResult } from "../models/list-result.model";
import { ReservedPassenger } from "../models/reserved-passenger.model";
import { ReservedStage } from "../models/reserved-stage.model";
import { RoomAllocationSelection } from "../models/room-allocation-selection.model";
import { RoomAllocation } from "../models/room-allocation.model";
import { Supplier } from "../models/supplier.model";

@Injectable({ providedIn: 'root' })
export class RoomAllocationSelectionService {

    constructor(private http: HttpClient) {
    }

  tzString(dateString: string) {
    dateString = dateString.substring(0, 10);
    const date = new Date(dateString);

    const offsetMinutes = date.getTimezoneOffset(); // Get the timezone offset in minutes

    // Calculate the offset in hours and minutes
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;

    // Determine the sign of the offset
    const offsetSign = offsetMinutes < 0 ? '+' : '-';

    return (
      dateString +
      'T00:00:00' +
      offsetSign +
      this.padNumber(offsetHours, 2) +
      ':' +
      this.padNumber(offsetMinutesRemainder, 2)
    );
  }

    padNumber(num, size) {
        num = num.toString();
        while (num.length < size) num = "0" + num;
        return num;
    }

    public getSelections(id: string, params?: any): Observable<ListResult<RoomAllocationSelection>> {
        //const
        /*if (params == null) {
            params = { itemsPerPage: "10", page: "1" };
        }
        */

        return this.http.get(environment.urlApi + '/api/reserved_trips/' + id + '/selections', { params: params }).pipe(map(
            res => {
                const result = new ListResult<RoomAllocationSelection>();

                for (let i = 0; i < res['items'].length; i++) {
                    result.items.push(this.unserializeItem(res['items'][i]));
                }
                result.totalItems = res['totalItems'];
                return result;
            }
        ));
    }

    unserializeItem(item): RoomAllocationSelection {
        if(item.dateIn){
            item.dateIn = new Date(this.tzString(item.dateIn));
        }
        if(item.dateOut){
            item.dateOut = new Date(this.tzString(item.dateOut));
        }
        
        if (item.roomAllocations) {
            for (let i = 0; i < item.roomAllocations.length; i++) {
                if (item.roomAllocations[i].passengers) {
                    for (let j = 0; j < item.roomAllocations[i].passengers.length; j++) {
                        item.roomAllocations[i].passengers[j] = Object.assign(
                            new ReservedPassenger(), 
                            item.roomAllocations[i].passengers[j]
                        );
                    }
                }

                item.roomAllocations[i] = Object.assign(new RoomAllocation(), item.roomAllocations[i]);
            }
        }

        if (item.stages) {
            for (let i = 0; i < item.stages.length; i++) {
                if (item.stages[i].supplier) {
                    item.stages[i].supplier = Object.assign(new Supplier(), item.stages[i].supplier);
                }

                if (item.stages[i].roomAllocations) {

                    for (let j = 0; j < item.stages[i].roomAllocations.length; j++) {

                        if (item.stages[i].roomAllocations[j].passengers) {

                            for (let k = 0; k < item.stages[i].roomAllocations[j].passengers.length; k++) {
                                
                                item.stages[i].roomAllocations[j].passengers[k] = Object.assign(
                                    new ReservedPassenger(),
                                    item.stages[i].roomAllocations[j].passengers[k]
                                );
                            }
                        }

                        item.stages[i].roomAllocations[j] = Object.assign(new RoomAllocation(), item.stages[i].roomAllocations[j]);
                    }
                }


                item.stages[i] = Object.assign(new ReservedStage(), item.stages[i]);
            }
        }

        return Object.assign(new RoomAllocationSelection(), item);
    }


    remove(
        roomAllocation:RoomAllocation,
        passenger:ReservedPassenger
    ){
        const data = {
            passenger: passenger.id
        };
        return this.http.put(environment.urlApi + '/api/room_allocations/'+roomAllocation.id+'/remove',data);
    }

    add(
        roomAllocation:RoomAllocation,
        passenger:ReservedPassenger
    ){
        const data = {
            passenger: passenger.id
        };
        return this.http.post(environment.urlApi + '/api/room_allocations/'+roomAllocation.id+'/add',data);
    }
}
