import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { ReservedPassengerService } from "src/app/services/reserved-passenger.service";

import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Diet } from "src/app/models/diet.model";
import { Allergy } from "src/app/models/allergy.model";
import { environment } from "src/environments/environment";

// other imports are here...
@Component({
  selector: "app-reserved-passenger-card",
  templateUrl: "./reserved-passenger-card.component.html",
  styleUrls: ["./reserved-passenger-card.component.css"]
})
export class ReservedPassengerCardComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public modified = false;

  dateOfBirthOptions: IAngularMyDpOptions;

  @Input()
  public passenger: ReservedPassenger;

  @Input()
  public diets: Array<Diet> = [];

  @Input()
  public allergies: Array<Allergy> = [];

  public form: FormGroup;

  public isLeader: boolean;
  public isMe: boolean;
  public isEditable: boolean;
  public sending = false;
  public lightVersion: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private passengerService: ReservedPassengerService

  ) {
    this.lightVersion = environment.lightVersion;

    const dt = new Date();
    this.dateOfBirthOptions = {
      dateRange: false,
      dateFormat: 'dd/mm/yyyy',
      disableSince: {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate()
      }
      // other options...
    };
  }

  ngOnInit(): void {

    this.isLeader = this.passenger.number == 1;
    this.isMe = this.passenger.me;
    this.isEditable = this.passenger.isEditable;

    if (this.isMe && this.passenger.user) {

      const birthDate: IMyDateModel = { isRange: false, singleDate: { jsDate: new Date() }, dateRange: null };

      this.form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        gender: [''],
        numberLuggage: ['', [Validators.required, Validators.min(0)]],
        firstName: ['', [Validators.required, Validators.maxLength(45)]],
        lastName: ['', [Validators.required, Validators.maxLength(45)]],
        birthDate: [birthDate, Validators.required],
        phoneArea: ['', Validators.required],
        phoneNumber: ['', [Validators.required, Validators.maxLength(45)]],
        postalCode: ['', Validators.required],
        city: ['', [Validators.required, Validators.maxLength(45)]],
        street: ['', [Validators.required, Validators.maxLength(45)]],
        country: ['', Validators.required],
        otherDiet: ['', [Validators.maxLength(65535)]],
        otherAllergy: ['', [Validators.maxLength(65535)]],
        noEggs: [false],
        noFish: [false],
        noMilk: [false],
        noMeat: [false],
        noGluten: [false]
      });
    } else {

      this.form = this.formBuilder.group({
        email: ['', Validators.email],
        gender: [''],
        numberLuggage: ['', [Validators.required, Validators.min(0)]],
        firstName: ['', [Validators.required, Validators.maxLength(45)]],
        lastName: ['', [Validators.required, Validators.maxLength(45)]],
        otherDiet: ['', [Validators.maxLength(65535)]],
        otherAllergy: ['', [Validators.maxLength(65535)]],
        noEggs: [false],
        noFish: [false],
        noMilk: [false],
        noMeat: [false],
        noGluten: [false]
      });
    }


    this.form.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      res => {
        this.modified = true;
      }
    );

    this.updateControls();

  }

  get c() {
    return this.form.controls;
  }

  updateControls() {
    let noEggs = false;
    let noFish = false;
    let noMilk = false;
    let noMeat = false;
    let noGluten = false;

    for (let allergy of this.passenger.allergies) {
      if (allergy.name == 'Eggs') {
        noEggs = true;
      } else if (allergy.name == 'Milk') {
        noMilk = true;
      } else if (allergy.name == 'Gluten') {
        noGluten = true;
      }
    }

    for (let diet of this.passenger.diets) {
      if (diet.name == 'No meat') {
        noMeat = true;
      } else if (diet.name == 'No fish') {
        noFish = true;
      }
    }

    this.c.email.setValue(
      this.passenger.email,
      { emitEvent: false }
    );
    this.c.gender.setValue(
      this.passenger.salutation,
      { emitEvent: false }
    );
    this.c.numberLuggage.setValue(
      this.passenger.numberLuggage,
      { emitEvent: false }
    );
    this.c.firstName.setValue(
      this.passenger.firstName,
      { emitEvent: false }
    );
    this.c.lastName.setValue(
      this.passenger.lastName,
      { emitEvent: false }
    );
    this.c.otherDiet.setValue(
      this.passenger.otherDiet,
      { emitEvent: false }
    );
    this.c.otherAllergy.setValue(
      this.passenger.otherAllergy,
      { emitEvent: false }
    );
    this.c.noEggs.setValue(
      noEggs,
      { emitEvent: false }
    );
    this.c.noFish.setValue(
      noFish,
      { emitEvent: false }
    );
    this.c.noMilk.setValue(
      noMilk,
      { emitEvent: false }
    );
    this.c.noMeat.setValue(
      noMeat,
      { emitEvent: false }
    );
    this.c.noGluten.setValue(
      noGluten,
      { emitEvent: false }
    );


    if (this.isMe) {
      let model: IMyDateModel;

      if (this.passenger.user) {
        if (this.passenger.user.birthDate) {
          model = { isRange: false, singleDate: { jsDate: this.passenger.user.birthDate }, dateRange: null };
        } else {
          model = { isRange: false, singleDate: { jsDate: new Date() }, dateRange: null };
        }
        this.c.birthDate.setValue(
          model,
          { emitEvent: false }
        );

        this.c.street.setValue(
          this.passenger.user.street,
          { emitEvent: false }
        );
        this.c.country.setValue(
          this.passenger.user.country,
          { emitEvent: false }
        );

        if (this.passenger.user.clientData) {
          this.c.postalCode.setValue(
            this.passenger.user.clientData.postalCode,
            { emitEvent: false }
          );
          this.c.city.setValue(
            this.passenger.user.clientData.town,
            { emitEvent: false }
          );


          if (this.passenger.user.clientData.phone) {
            this.c.phoneArea.setValue(
              this.passenger.user.clientData.phone.countryCode,
              { emitEvent: false }
            );
            this.c.phoneNumber.setValue(
              this.passenger.user.clientData.phone.number,
              { emitEvent: false }
            );
          }

        }
      }

    } else {
      if (!this.passenger.isEditable) {
        this.c.email.disable({ emitEvent: false });
        this.c.gender.disable({ emitEvent: false });
        this.c.numberLuggage.disable({ emitEvent: false });
        this.c.firstName.disable({ emitEvent: false });
        this.c.lastName.disable({ emitEvent: false });
        this.c.otherDiet.disable({ emitEvent: false });
        this.c.otherAllergy.disable({ emitEvent: false });
        this.c.noEggs.disable({ emitEvent: false });
        this.c.noFish.disable({ emitEvent: false });
        this.c.noMilk.disable({ emitEvent: false });
        this.c.noMeat.disable({ emitEvent: false });
        this.c.noGluten.disable({ emitEvent: false });
      } else {
        this.c.email.enable({ emitEvent: false });
        this.c.gender.enable({ emitEvent: false });
        this.c.numberLuggage.enable({ emitEvent: false });
        this.c.firstName.enable({ emitEvent: false });
        this.c.lastName.enable({ emitEvent: false });
        this.c.otherDiet.enable({ emitEvent: false });
        this.c.otherAllergy.enable({ emitEvent: false });
        this.c.noEggs.enable({ emitEvent: false });
        this.c.noFish.enable({ emitEvent: false });
        this.c.noMilk.enable({ emitEvent: false });
        this.c.noMeat.enable({ emitEvent: false });
        this.c.noGluten.enable({ emitEvent: false });
      }
    }

    if (this.lightVersion) {
      this.c.numberLuggage.disable({ emitEvent: false });
    }
  }

  switchNoEggs() {
    if (this.isMe || this.isEditable) {
      this.c.noEggs.setValue(!this.c.noEggs.value, { emitEvent: true });
    }
  }
  switchNoFish() {
    if (this.isMe || this.isEditable) {
      this.c.noFish.setValue(!this.c.noFish.value, { emitEvent: true });
    }
  }
  switchNoMilk() {
    if (this.isMe || this.isEditable) {
      this.c.noMilk.setValue(!this.c.noMilk.value, { emitEvent: true });
    }
  }
  switchNoMeat() {
    if (this.isMe || this.isEditable) {
      this.c.noMeat.setValue(!this.c.noMeat.value, { emitEvent: true });
    }
  }
  switchNoGluten() {
    if (this.isMe || this.isEditable) {
      this.c.noGluten.setValue(!this.c.noGluten.value, { emitEvent: true });
    }
  }
  updateModel() {
    if (this.passenger.isEditable || this.isMe) {
      this.sending = true;

      this.passenger.allergies = [];
      if (this.c.noEggs.value) {
        for (let a of this.allergies) {
          if (a.name == 'Eggs') {
            this.passenger.allergies.push(a);
            break;
          }
        }
      }
      if (this.c.noMilk.value) {
        for (let a of this.allergies) {
          if (a.name == 'Milk') {
            this.passenger.allergies.push(a);
            break;
          }
        }
      }
      if (this.c.noGluten.value) {
        for (let a of this.allergies) {
          if (a.name == 'Gluten') {
            this.passenger.allergies.push(a);
            break;
          }
        }
      }

      this.passenger.diets = [];

      if (this.c.noMeat.value) {
        for (let d of this.diets) {
          if (d.name == 'No meat') {
            this.passenger.diets.push(d);
            break;
          }
        }
      }

      if (this.c.noFish.value) {
        for (let d of this.diets) {
          if (d.name == 'No fish') {
            this.passenger.diets.push(d);
          }
        }
      }

      this.passenger.email = this.c.email.value;
      this.passenger.salutation = this.c.gender.value;
      if (!this.lightVersion) {
        this.passenger.numberLuggage = this.c.numberLuggage.value;
      }
      this.passenger.firstName = this.c.firstName.value;
      this.passenger.lastName = this.c.lastName.value;
      this.passenger.otherDiet = this.c.otherDiet.value;
      this.passenger.otherAllergy = this.c.otherAllergy.value;

      if (this.passenger.user) {
        this.passenger.user.firstName = this.c.firstName.value;
        this.passenger.user.name = this.c.lastName.value;
      }

      if (this.isMe && this.passenger.user) {

        const dt = this.c.birthDate.value.singleDate.jsDate;
        this.passenger.user.birthDate = new Date(dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset()));
        this.passenger.user.clientData.phone.countryCode = this.c.phoneArea.value;
        this.passenger.user.clientData.phone.number = this.c.phoneNumber.value;
        //this.passenger.user.clientData.phoneNumber = '+' + this.c.phoneArea.value.number + this.c.phoneNumber.value;
        this.passenger.user.clientData.postalCode = this.c.postalCode.value;
        this.passenger.user.clientData.town = this.c.city.value;
        this.passenger.user.street = this.c.street.value;
        this.passenger.user.country = this.c.country.value.name;
      }

      this.passengerService.update(this.passenger).subscribe(
        res => {
          this.modified = false;
          this.passenger = res;
          this.sending = false;
          this.updateControls();
        }
      )
    }
  }

  isInvitable() {
    return this.passenger.isInvitable()
  }


  invite() {
    if (
      this.isInvitable()
    ) {
      this.sending = true;
      this.passengerService.invite(this.passenger).subscribe(
        res => {
          this.passenger = res;
          this.updateControls();
          this.sending = false;
        }
      )
    }

  }

  save() {
    if (
      !this.sending && !this.form.invalid && this.modified &&
      (this.passenger.isEditable || this.isMe)
    ) {
      this.updateModel();
    }
  }

  emptyIfStartWith(control: AbstractControl, value: string) {
    const stringValue: string = control.value;
    if (stringValue.startsWith(value)) {
      control.setValue('');
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}