import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';


@Pipe(
    {
        name: 'firsttitlecase'
    }
)
export class FirstTitleCasePipe implements PipeTransform {

    constructor(
    ) {
    }

    transform(value: string): string {
        if (value && value.length > 0) {
            let first = value.substr(0, 1).toUpperCase();
            if(value.length > 1) {
                return first + value.substr(1).toLowerCase();
            } else {
                return first;
            }
        }
        return '';
    }
}
