import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { User } from './user.model';
import { JwtNavigationService } from './jwt-navigation.service';
import { map } from 'rxjs/operators';


@Injectable()
export class JwtService {
    private token: string;
    private refreshToken: string;
    private refreshTokenSubject: Subject<void> = new Subject<void>();
    private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    private navigationService: JwtNavigationService;

    //puedo hacer subjects para ser notificados?
    private isLogged: boolean = false;
    private fetchingUser: boolean = false;
    private refreshing: boolean = false;
    
    constructor(
        private http: HttpClient,
        private injector: Injector
    ) {

        //I must to obtain the current from the endpoint if available
        this.token = localStorage.getItem('jwtToken');
        this.refreshToken = localStorage.getItem('refreshToken');

        setTimeout(() => {
            this.navigationService = this.injector.get(JwtNavigationService);
            if (this.token != null) {
                this.fetchUserData().subscribe(
                    usr => {

                    }
                );
            } else {
                this.navigationService.navigateLoginRoute();
            }
        });
    }

    private fetchUserData(): Observable<User> {
        this.fetchingUser = true;
        return this.http.get(this.navigationService.getUserUrl()).pipe(
            map(
                res => {
                    this.currentUserSubject.next(Object.assign(new User(), res));
                    this.fetchingUser = false;
                    return this.currentUserSubject.value;
                },
                err => {
                    this.fetchingUser = false;
                }
            )
        );
    }

    public getIsLogged() {
        return this.isLogged;
    }

    public getIsFetchingUser() {
        return this.fetchingUser;
    }


    public setTokens(res) {
        console.log('Set Tokens' + res['refresh_token'])
        this.token = res['token'];
        this.refreshToken = res['refresh_token'];

        localStorage.setItem('jwtToken', this.token);
        localStorage.setItem('refreshToken', this.refreshToken);

        this.notifyAppNewToken();
    }


    public authenticate(
        username: string,
        password: string,
        captchaToken: string
    ): Observable<string> {


        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        return this.http.post(
            this.navigationService.getLoginUrl(),
            { username, password, captchaToken },
            { headers: headers }
        ).pipe(
            map(
                res => {
                    this.setTokens(res);
                    this.fetchUserData().subscribe();
                    this.navigationService.navigateLanding();
                    return this.token;
                }
            )
        );
    }


    public refreshCurrentToken(): Observable<void> {

        if(!this.refreshing){
            this.refreshing = true;
            //        const headers = new HttpHeaders();
            //        headers.append('Content-Type', 'application/json');
            console.log('Refreshing Token');

            this.http.post(
                this.navigationService.getRefresTokenUrl(),
                { refresh_token: this.refreshToken }
            ).pipe(
                map(
                    res => {
                        this.setTokens(res);
                        this.refreshing=false;

                        this.refreshTokenSubject.next();
                    },
                    err => {
                        this.logout();
                        this.refreshing=false;

                        this.refreshTokenSubject.next();
                    }
                )
            ).subscribe()

        }
        
        return this.refreshTokenSubject;
    }

    public notifyAppNewToken() {

        if (
            typeof window['webkit'] !== 'undefined' && window['webkit'] !== null &&
            typeof window['webkit'].messageHandlers !== 'undefined' && window['webkit'].messageHandlers !== null &&
            typeof window['webkit'].messageHandlers.notifyAuthentication !== 'undefined' &&
            typeof window['webkit'].messageHandlers.notifyAuthentication !== null
        ) {
            window['webkit'].messageHandlers.notifyAuthentication.postMessage({
                token: this.token
            });
        }
    }

    public authenticateByToken(
        token: string,
        captchaToken: string
    ): Observable<string> {

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'application/json');


        return this.http.get(
            this.navigationService.getTokenLoginUrl() + '/' + token,
            { headers: headers }
        ).pipe(
            map(
                res => {
                    this.setTokens(res);
                    this.fetchUserData().subscribe();
                    this.navigationService.navigateLanding();
                    return this.token;
                }
            )
        );
    }

    public getToken(): string {
        return this.token;
    }

    public getRefreshToken(): string {
        return this.refreshToken;
    }


    public getCurrentUserSubject(): Subject<User> {
        return this.currentUserSubject;
    }

    public logout(): void {
        // remove user from local storage to log user out
        this.isLogged = false;
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('refreshToken');
        this.currentUserSubject.next(null);
        this.navigationService.navigateLoginRoute();
    }

    public removeCredentials() {
        this.token = '';
        localStorage.removeItem('jwtToken');
    }
}