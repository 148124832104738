import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import isWebview from "is-ua-webview";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import { environment } from "src/environments/environment";


@Component({
  selector: "app-reserved-trip-card",
  templateUrl: "./reserved-trip-card.component.html",
  styleUrls: ["reserved-trip-card.component.css"]
})
export class ReservedTripCardComponent implements OnInit {
  @Input()
  public trip: ReservedTrip;
  
  public modified = true;

  public form: FormGroup;
  
  public departureDateOptions: IAngularMyDpOptions;

  public showDepartureDateDialog = false;
  
  public host:string;

  private isWebView: boolean;
  public showMap: boolean = false;
  private externalMapUrl: string;
  
  constructor(
    private formBuilder: FormBuilder,
    private reservedTripService: ReservedTripService,
    private router: Router
  ) {
    this.isWebView = isWebview(window.navigator.userAgent);

    if (
      typeof window['webkit'] !== 'undefined' && window['webkit'] !== null &&
      typeof window['webkit'].messageHandlers !== 'undefined' && window['webkit'].messageHandlers !== null &&
      typeof window['webkit'].messageHandlers.sendBase64 !== 'undefined' &&
      typeof window['webkit'].messageHandlers.sendBase64 !== null
    ) {
      this.isWebView = true;
    }
    
    this.isWebView = true;

    this.host = environment.urlApi;

    var dt = new Date();
    dt.setMonth(dt.getMonth() + 2);
    this.departureDateOptions = {
      dateRange: false,
      dateFormat: 'dd/mm/yyyy',
      disableUntil: {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate()
      }
      // other options...
    };
  }

  get c(){
    return this.form.controls;
  }

  ngOnInit(): void {

    const model: IMyDateModel = { isRange: false, singleDate: { jsDate: this.trip.departureDate  }, dateRange: null };

    this.form = this.formBuilder.group({
      departureDate: [model, Validators.required],
    });

    this.externalMapUrl = environment.mapPath + this.trip.id;

    this.showMap = this.trip.getShowMap() && this.isWebView
  }

  goToMap(){
    this.router.navigate([this.externalMapUrl]);
  }

  changeDepartureDate(){
    const dt =this.c.departureDate.value.singleDate.jsDate;
    this.reservedTripService.updateDepartureDate(this.trip.id,new Date(dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset()))).subscribe(
      res=>{
        this.trip = res;
        this.showDepartureDateDialog = false;
      }
    )
  }

}
