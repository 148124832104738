import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { RoomAllocationSelection } from "src/app/models/room-allocation-selection.model";
import { ReservedPassengerService } from "src/app/services/reserved-passenger.service";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import { RoomAllocationSelectionService } from "src/app/services/room-allocation-selection.services";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
})
export class ServicesComponent implements OnInit, OnDestroy {
  public reservedTrip: ReservedTrip;
  public loaded = false;
  private destroy$ = new Subject();

  private page = 1;
  private totalItems = null;
  public showList:boolean=false;
  private searchText = '';
  public form: FormGroup;
  public dropListIds: Array<string> = [];


  private totalPassenger: Array<ReservedPassenger> = [];
  public passengers: Array<ReservedPassenger> = [];
  public stages: Array<ReservedStage> = [];


  constructor(
    private reservedTripService: ReservedTripService,
    private reservedPassengersService: ReservedPassengerService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      search: ['']
    });


    this.totalPassenger = [];

    this.reservedTripService.getLastItem().subscribe(
      res => {
        if (res) {
          this.reservedTrip = res;

          this.reservedTripService.getServicesStages(res.id,{page:1 }).subscribe(
            res => {
              if (res) {

                this.stages = res.items;

                this.reservedPassengersService.getList(
                  this.reservedTrip,
                  { page: 1, group: "services" }
                ).subscribe(
                  res => {
                    this.totalPassenger = res.items;
                    this.totalItems = res.totalItems;
                    this.showList = true;
                    this.loaded = true;
                    this.search();
                  }
                );
              }
            }
          );
        }
      }
    );

    this.form.controls['search'].valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(
      res => {
        this.searchText = res.toLowerCase();
        this.search()
      }
    );


  }

  search() {
    this.passengers = [];

    if (this.searchText && this.searchText != "") {
      const words = this.searchText.split(" ");

      for (let w of words) {
        for (let p of this.totalPassenger) {
          if (p.fullName().toLowerCase().indexOf(w) > -1) {
            this.passengers.push(p);
          }
        }
      }

    } else {
      this.passengers = this.totalPassenger;
    }

  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
