import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { RoomAllocationSelection } from "src/app/models/room-allocation-selection.model";
import { ReservedPassengerService } from "src/app/services/reserved-passenger.service";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import { RoomAllocationSelectionService } from "src/app/services/room-allocation-selection.services";

@Component({
  selector: "app-room-allocations",
  templateUrl: "./room-allocations.component.html",
})
export class RoomAllocationsComponent implements OnInit, OnDestroy {
  public reservedTrip: ReservedTrip;
  public loaded = false;
  private destroy$ = new Subject();

  private page = 1;
  private totalItems = null;
  private searchText = '';
  public form: FormGroup;
  public dropListIds: Array<string> = [];

  public selectedSelectionIndex:number;
  public selectedSelection: RoomAllocationSelection;
  public selectionSelectionSubject: Array<Subject<boolean>> = [];
  public selections: Array<RoomAllocationSelection> = [];

  private searchPassengers: Array<ReservedPassenger> = [];
  private totalPassenger: Array<ReservedPassenger> = [];
  private selectedSelectionPassengers: Array<ReservedPassenger> = [];

  public passengers: Array<ReservedPassenger> = [];



  constructor(
    private reservedTripService: ReservedTripService,
    private reservedSelectionService: RoomAllocationSelectionService,
    private reservedPassengersService: ReservedPassengerService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      search: ['']
    });


    this.totalPassenger = [];

    this.reservedTripService.getLastItem().subscribe(
      res => {
        if (res) {
          this.reservedTrip = res;
          this.reservedSelectionService.getSelections(this.reservedTrip.id).subscribe(
            res => {
              this.selections = res.items;
              this.selected(0);


              let selectionIndex = 0;
              for (let selection of this.selections) {

                selection.setBaseDropListIndex(selectionIndex);

                if(selection.isEditable()){
                  if (selection.roomAllocations) {
                    for (let room of selection.roomAllocations) {
                      this.dropListIds.push('room-' + room.id);
  
                    }
                  } else if (selection.stages) {
                    for (let stage of selection.stages) {
                      for (let room of stage.roomAllocations) {
                        this.dropListIds.push('room-' + room.id);
                      }
                    }
                  }
  
                }
                
                selectionIndex++;
              }



              this.reservedPassengersService.getList(
                this.reservedTrip,
                { page: 1, group: "rooms"  }
              ).subscribe(
                res => {
                  this.totalPassenger = res.items;
                  this.totalItems = res.totalItems;

                  this.search();
                }
              );

            }
          );
        }
      }
    );

    this.form.controls['search'].valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(
      res => {
        this.searchText = res.toLowerCase();
        this.search()
      }
    );


  }

  showPassengers() {
    this.passengers = [];

    for (let passenger of this.searchPassengers) {
      let found = false;
      if (this.selectedSelectionPassengers.some(e => e.id === passenger.id)) {
        found = true;
      }
      if (!found) {
        this.passengers.push(passenger);
      }
    }
  }

  selected(selectionIndex) {
    this.selectedSelectionIndex = selectionIndex;

    this.selectedSelection = this.selections[this.selectedSelectionIndex];
    this.selectedSelectionPassengers = [];

    if (this.selectedSelection != null) {
      for (let roomAllocation of this.selectedSelection.roomAllocations) {
        for (let passenger of roomAllocation.passengers) {
          this.selectedSelectionPassengers.push(passenger);
        }
      }
    }

    this.showPassengers();
  }

  passengerRemoved(passenger:ReservedPassenger){
    for (var i = 0; i < this.selectedSelectionPassengers.length; i++) {
      if (this.selectedSelectionPassengers[i].id === passenger.id) {
        this.selectedSelectionPassengers.splice(i, 1);
        break;
      }
    }
    this.search();
  }


  search() {
    this.searchPassengers = [];

    if (this.searchText && this.searchText != "") {
      const words = this.searchText.split(" ");

      for (let w of words) {
        for (let p of this.totalPassenger) {
          if (p.fullName().toLowerCase().indexOf(w) > -1) {
            this.searchPassengers.push(p);
          }
        }
      }

    } else {
      this.searchPassengers = this.totalPassenger;
    }

    this.showPassengers();
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
