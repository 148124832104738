import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from "@angular/core";
import { createPopper } from "@popperjs/core";
import { ClickService } from "src/app/services/click.service";
import { TranslateService } from "src/app/translate/translate.service";

@Component({
  selector: "app-language-dropdown",
  templateUrl: "./language-dropdown.component.html",
})
export class LanguageDropdownComponent implements OnInit ,AfterViewInit {
  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;


  constructor(
    private translateService:TranslateService,
    private clickService: ClickService
  ) {
     }

  changeLanguage(lang:string,event:MouseEvent)
  {
    
    this.translateService.chageLocale(lang);
    this.toggleDropdown(event);
  }

  ngOnInit() {
    this.clickService.$clickSubject.subscribe(
      (event) => {
        this.toggleDown();
      }
    );
  }
  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }
  toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dropdownPopoverShow) {
      this.toggleDown();
    } else {
      this.dropdownPopoverShow = true;
    }
    
  }

  toggleDown(){
    this.dropdownPopoverShow = false;
  }
}
