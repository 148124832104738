import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, } from 'rxjs';
import { catchError, ignoreElements, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private toastService: ToastrService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map(
                (event: HttpEvent<any>) => {

                    if (event instanceof HttpResponse) {
                        if (
                            request.method === 'GET' &&
                            event.status === 200 &&
                            event.headers.get('Content-Type') == 'application/json'
                        ) {
                            const url = this.getUrl(request);
                            localStorage.setItem(url, JSON.stringify(event.body));
                        }
                    }
                    return event;
                }
            ),
            catchError((error: HttpErrorResponse) => {

                if (
                    this.isConnectionError(error) &&
                    request.method === 'GET'
                ) {
                    // A client-side or network error occurred. Handle it accordingly.
                    const url = this.getUrl(request);

                    const cachedBody = localStorage.getItem(url);
                    if (cachedBody) {

                        const newHttResponse = new HttpResponse({
                            headers: new HttpHeaders().append('Content-Type', 'application/json'),
                            status: 200,
                            statusText: 'OK',
                            url: error.url,
                            body: JSON.parse(cachedBody)
                        });

                        return of(newHttResponse);
                    }
                }

                return this.handleError(error, request.method, request.url);
            })
        );

    }

    private handleError(err: HttpErrorResponse, method: string, url: string): Observable<any> {
        if (this.isConnectionError(err)) {
            this.toastService.error('Connection Error ('+err.status+')', undefined, {
                closeButton: true,
                positionClass: 'toast-bottom-right'
            });
        }
        else if (err.status === 401 || err.status === 403) {
        } else {
            this.toastService.error('Error ' + err.status, undefined, {
                closeButton: true,
                positionClass: 'toast-bottom-right'
            });
        }

        return throwError(err);
    }

    private getUrl(request: HttpRequest<any>): string {
        let queryString = '';
        request.params.keys().forEach(key => {
            request.params.getAll(key).forEach(value => {
                queryString += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
            });
        });
        let url = '';
        if (request.url.includes('?')) {
            url = request.url + '&' + queryString;
        } else {
            url = request.url + '?' + queryString;
        }
        return url;
    }

    private isConnectionError(error: HttpErrorResponse): boolean {
        return (
            error.status === 0 && error.error instanceof ProgressEvent ||
            error.status >= 500
        );
    }
}