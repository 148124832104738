import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from "@angular/core";
import { createPopper } from "@popperjs/core";
import { ExchangeService } from "src/app/exchange/exchange.service";
import { ClickService } from "src/app/services/click.service";

@Component({
  selector: "app-currency-dropdown",
  templateUrl: "./currency-dropdown.component.html",
})
export class CurrencyDropdownComponent implements OnInit, AfterViewInit {
  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  public currency: string = 'EUR';

  constructor(
    private exchangeService: ExchangeService,
    private clickService: ClickService
  ) {
    this.currency = this.exchangeService.getCurrency();
  }

  changeCurrency(
    currency: string,
    event: MouseEvent
  ) {
    this.exchangeService.changeCurrency(currency);
    this.currency = currency;
    this.toggleDropdown(event);
  }

  ngOnInit() {
    this.clickService.$clickSubject.subscribe(
      () => {
        this.toggleDown();
      }
    );

  }
  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }
  toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dropdownPopoverShow) {
      this.toggleDown();
    } else {
      this.dropdownPopoverShow = true;
    }
    
  }

  toggleDown(){
    this.dropdownPopoverShow = false;
  }
}
