import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { UserService } from "src/app/services/user.service";
import { JwtService } from "src/app/utils/jwt/jwt.service";

@Component({
  selector: "delete-profile-login",
  templateUrl: "./delete-profile.component.html",
  styleUrls: ["./delete-profile.component.css"]
})
export class DeleteProfileComponent implements OnInit {
  public deleteForm: FormGroup;
  public submited: boolean = false;
  private ngUnsubscribe = new Subject();
  public reCaptchaKey: string;
  public errorKeyword: string = null;
  public showConfirmationDialog = false;
  public sending = false;

  constructor(
    private userService: UserService,
    private jwtService: JwtService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {


    this.deleteForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }


  get f() { return this.deleteForm.controls; }

  public submit() {
    this.submited = true;

    if (!this.deleteForm.invalid) {

      this.errorKeyword = null;
      this.showConfirmationDialog = true;
      
      /*this.recaptchaV3Service.execute('login').subscribe(
        (token) => 
      );*/
    } else{
     
    }
  }

  delete() {
    this.showConfirmationDialog = false;
    this.sending = true;

    this.userService.deleteProfile(
      this.f.username.value,
      this.f.password.value
    ).subscribe(
      res => {
        if (!res || res['status'] != 'OK') {
          this.errorKeyword = 'Invalid user or password';
        } else {
          this.jwtService.removeCredentials();
          this.router.navigate(['/auth/deleted']);
        }
        this.sending = false;
      },
      error => {

        if (error.status == 401) {
          this.errorKeyword = 'Invalid user or password';
        } else {
          this.errorKeyword = 'Something goes wrong, please try again later';
        }
        this.sending = false;
      }
    )
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
