import { Discount } from "./discount.model";
import { PaymentStatus } from "./payment-status.model";
import { ReservedExtra } from "./reserved-extra.model";

export class Pricing{
    public id:number;
    public roomPrice:number;
    public singleSupplement:number;
    public paymentStatus:PaymentStatus;
    public combinedDiscount:number;
    public discount: Discount;
    public commission: Discount;
    public extras:Array<ReservedExtra>;
    public sharedBaseDiscount: number;
    public sharedBasePrice: number; //why strin??
    public sharedDiscount: number;
    public sharedExtraDiscount: number;
    public sharedPrice: number;
    public singleBaseDiscount: number;
    public singleBasePrice: number;
    public singleDiscount: number;
    public singleExtraDiscount: number;
    public singlePrice: number;
    public extrasSingleTot: number;
    public extrasSharedTot: number;
}