import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
})
export class ResetPasswordComponent implements OnInit {
  public resetForm: FormGroup;
  public answerAccept: boolean = false;
  public submited: boolean = false;
  private ngUnsubscribe = new Subject();
  public reCaptchaKey: string;
  private token: string;
  public errorKeyword: string = null;

  constructor(
    private resetPasswordService: UserService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {

    this.token = this.route.snapshot.queryParamMap.get('token');


    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required]],//,Validators.email
      repassword: ['', [Validators.required]]
    });
  }


  get f() { return this.resetForm.controls; }

  public submit() {
    this.submited = true;

    if (!this.resetForm.invalid) {

      if(this.f.password.value!=this.f.repassword.value){
        this.errorKeyword = 'Passwords don\' match';
        return;
      }

      this.errorKeyword = null;


      this.resetPasswordService.setPassword(
        this.f.password.value, this.token
      ).subscribe(
        res => {
          if(res['status']=='OK'){
            this.errorKeyword = null;
            this.answerAccept = true;
            //this.goLogin();
          }
          else if(res['status'] == 'EXPIRED'){
            this.errorKeyword = 'Expired';
          }
     
        },
        error => {
          if (error.status == 401) {
            //this.errorKeyword = 'Invalid user or password';
          } else {
            this.errorKeyword = 'Something goes wrong, please try again later';
          }

        }
      )
    }
  }


  goLogin() {
    this.router.navigate(['/auth/login']);
  }




  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
