import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { ReservedPassengerService } from "src/app/services/reserved-passenger.service";
import { Subject } from "rxjs";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { PassengerBalanceSelection } from "src/app/models/passenger-balance-selection.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// other imports are here...
@Component({
  selector: "app-reserved-passenger-pay-card",
  templateUrl: "./reserved-passenger-pay-card.component.html",
})
export class ReservedPassengerPayCardComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public modified = false;

  @Input()
  public passenger: ReservedPassenger;

  @Input()
  public reservedTrip: ReservedTrip;

  public isLeader: boolean;
  public isMe: boolean;
  public isEditable: boolean;
  public balanceSelected: boolean = false;
  public depositSelected: boolean = false;
  public otherSelected: boolean = false;
  private otherValue: number = 1.00;
  public form: FormGroup;

  @Output()
  public select: EventEmitter<PassengerBalanceSelection> = new EventEmitter();


  constructor(
    private passengerService: ReservedPassengerService,
    private formBuilder: FormBuilder
  ) {

  }

  ngOnInit(): void {

    this.isLeader = this.passenger.number == 1;
    this.isMe = this.passenger.me;
    this.isEditable = true; //this.passenger.isEditable;

    if (this.isMe || this.isEditable) {


    }
    this.form = this.formBuilder.group({
      amount: [this.otherValue, [Validators.required, Validators.min(1)]]
    });

    this.form.controls.amount.valueChanges.subscribe(
      res => {
        if (res > this.passenger.balance) {
          this.otherValue = this.passenger.balance;
          this.form.controls.amount.setValue(this.otherValue, { emitEvent: false });
        } else {
          this.otherValue = res;
        }


        this.emitEvent();
      }
    );
  }

  isPayable() {
    return this.reservedTrip.isPayable()
  }

  isDepositPayable() {
    return this.reservedTrip.isDepositPayable()
  }


  balanceCssClass() {
    if (this.isPayable()) {
      if (this.balanceSelected) {
        return 'cursor-pointer bg-teal-400 text-white';
      } else {
        return 'cursor-pointer bg-white hover:bg-teal-400 hover:text-white text-blueGray-600';
      }
    } else {
      return 'cursor-not-allowed bg-red-200 opacity-50';
    }
  }

  depositCssClass() {
    if (this.isDepositPayable()) {
      if (this.depositSelected) {
        return 'cursor-pointer bg-teal-400 text-white';
      } else {
        return 'cursor-pointer bg-white hover:bg-teal-400 hover:text-white  text-blueGray-600';
      }
    } else {
      return 'cursor-not-allowed bg-red-200 opacity-50';
    }
  }

  otherCssClass() {
    if (this.isPayable()) {
      if (this.otherSelected) {
        return 'cursor-pointer bg-teal-400 text-white';
      } else {
        return 'cursor-pointer bg-white hover:bg-teal-400 hover:text-white  text-blueGray-600';
      }
    } else {
      return 'cursor-not-allowed bg-red-200 opacity-50';
    }
  }

  selectBalance() {
    if (
      this.isPayable()
    ) {
      if (this.balanceSelected) {
        this.balanceSelected = false;
      } else {
        this.balanceSelected = true;
      }
      this.depositSelected = false;
      this.otherSelected = false;
      this.emitEvent();
    }

  }

  selectDeposit() {
    if (
      this.isDepositPayable()
    ) {

      if (this.depositSelected) {
        this.depositSelected = false;
      } else {
        this.depositSelected = true;
      }
      this.balanceSelected = false;
      this.otherSelected = false;
      this.emitEvent();
    }
  }


  selectOther() {
    if (
      this.isPayable()
    ) {
      if (this.otherSelected) {
        this.otherSelected = false;
      } else {
        this.otherSelected = true;
      }
      this.balanceSelected = false;
      this.depositSelected = false;
      this.emitEvent();
    }
  }

  emitEvent() {
    const pbs = new PassengerBalanceSelection();
    pbs.passenger = this.passenger;
    pbs.balanceSelected = this.balanceSelected;
    pbs.depositSelected = this.depositSelected;
    pbs.otherSelected = this.otherSelected;
    pbs.depositValue = this.passenger.amount * this.reservedTrip.depositPercentage / 100 - this.passenger.paid;
    pbs.amount = this.otherValue;

    this.select.emit(
      pbs
    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}