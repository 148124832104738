import { environment } from "src/environments/environment";
import { ReservedStage, ReservedStageType } from "./reserved-stage.model";
import { SupplierAccommodation } from "./supplier-accommodation.model";
import { City } from "./city.model";
import { TransportType } from "./transport-type.model";

export class ReservedDay {

    public date: Date;
    public from: string = null;
    public to: string = null;
    public services: Array<ReservedStageType> = [];
    public pax: number = 0;
    public index: number = null;
    public luggageNumber: number = 0;
    public betweenAccommodationCitiesKm: number = 0;
    public peopleTransferKm: number = 0;
    public guideKm: number = 0;
    public activityKm: number = 0;
    public reservedTripId: string;
    public nbSingle: number;
    public nbDouble: number;
    public nbTriple: number;
    public nbDorm: number;
    public nbTwin: number;
    public accommodationStages: Array<ReservedStage>;
    public luggageTransferStages: Array<ReservedStage>;
    public accommodationSuppliers: Array<SupplierAccommodation>;
    public peopleTransferStages: Array<ReservedStage>;
    public firstDayOfStage: boolean;
    public nightsAccommodationStages: number;
    public meals: string;
    public mealsKeyword: string;
    public thisNightAccommodationCity: City;
    public transportType: TransportType;





    public hasService(service: number) {
        return (this.services.indexOf(service) != -1);
    }

    public getKilometers() {

        if (this.betweenAccommodationCitiesKm)
            return this.betweenAccommodationCitiesKm;
        return 0;
        //+ this.guideKm
        //+ this.activityKm
        //- this.peopleTransferKm;
    }

    public getMiles() {
        return this.getKilometers() * 0.621371;
    }


    public sameCity(): boolean {

        return this.from == this.to;
    }

    public get urlElevation(): string {
        return environment.urlApi + '/api/reserved_trips/' + this.reservedTripId + '/elevation/' + this.index;
    }
}