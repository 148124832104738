import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from "rxjs/operators";
import { ReservedStageType } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import { AbstractControl, ValidatorFn } from "@angular/forms";
import { environment } from "src/environments/environment";
import isWebview from "is-ua-webview";
import { Router } from "@angular/router";

declare var WidgetHistoricalClass;
declare var WidgetForecastClass;
declare var WidgetMapClass;

class InvoiceItem {
  public name: string;
  public amount: number;
  public price: number;
}

export function isLocation(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null =>
    control.value.id ? null : { location: true };
}

@Component({
  selector: "app-header-stats",
  templateUrl: "./header-stats.component.html",
  styleUrls: ["header-stats.component.css"],
})
export class HeaderStatsComponent implements OnInit {
  private destroy$ = new Subject();

  @Input()
  public reservedTrip: ReservedTrip;

  @Input()
  public showStats: boolean;

  public dateForm: FormGroup;
  public departureDateOptions: IAngularMyDpOptions;
  public showDepartureDateDialog = false;

  public pax: number = 0;
  public roomsForm: FormGroup;
  public showChangePaxDialog: boolean = false;
  public showExtraDialog: boolean = false;

  public taxiForm: FormGroup;
  public showTaxiDialog: boolean = false;
  public taxiDateOptions: IAngularMyDpOptions;

  public showForecast: boolean = false;
  public widgetId = "widgetFtcHistoricalWeatherMap";
  public type = null; // 'map'. 'historical', 'forecast'

  public when: Date;
  public hasChanged = false;
  public invoiceItems: Array<InvoiceItem> = [];
  public amountOwed: number;
  public percentage: number;
  public showErrorDialog: boolean = false;
  public errors: Array<string> = [];

  public allLuggageSame = true;
  public luggageTransfer: boolean = null;
  public newQuote = false;

  public sending = false;

  public lightVersion: boolean;

  public widget: any;

  private isWebView: boolean = false;
  public showMap: boolean = false;
  public externalMapUrl: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private reservedTripService: ReservedTripService,
    private router: Router
  ) {
    this.lightVersion = environment.lightVersion;

    const dt = new Date();
    dt.setMonth(dt.getMonth() + 2);
    this.departureDateOptions = {
      dateRange: false,
      dateFormat: "dd/mm/yyyy",
      disableUntil: {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate(),
      },
      // other options...
    };
  }

  get amout() {
    return this.amountOwed;
  }

  get dc() {
    return this.dateForm.controls;
  }

  get rc() {
    return this.roomsForm.controls;
  }

  get tc() {
    return this.taxiForm.controls;
  }

  ngOnInit(): void {
    this.isWebView = isWebview(window.navigator.userAgent);

    if (
      typeof window["webkit"] !== "undefined" &&
      window["webkit"] !== null &&
      typeof window["webkit"].messageHandlers !== "undefined" &&
      window["webkit"].messageHandlers !== null &&
      typeof window["webkit"].messageHandlers.sendBase64 !== "undefined" &&
      typeof window["webkit"].messageHandlers.sendBase64 !== null
    ) {
      this.isWebView = true;
    }
    this.isWebView = true;


    this.reservedTrip = null;

    this.when = new Date();

    this.taxiForm = this.formBuilder.group({
      date: [null, Validators.required],
      hour: ["", [Validators.min(0), Validators.max(23)]],
      minute: ["", [Validators.min(0), Validators.max(59)]],
      from: ["", [Validators.required, isLocation]],
      to: ["", [Validators.required, isLocation]],
      pax: ["", [Validators.required, Validators.min(1)]],
      flightNumber: [],
    });

    this.taxiForm.controls.minute.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((res) => {
        if (res < 10) {
          this.taxiForm.controls.minute.setValue("0" + res, {
            emitEvent: false,
          });
        }
      });

    this.dateForm = this.formBuilder.group({
      departureDate: [null, Validators.required],
    });

    this.roomsForm = this.formBuilder.group({
      nbSingle: [0],
      nbDouble: [0],
      nbTwin: [0],
      nbTriple: [0],
      nbDorm: [0],
    });

    this.roomsForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.updatePax();
      });

    this.reservedTripService.getLastItem().subscribe((res) => {
      if (res) {
        this.reservedTrip = res;

        this.errors = [];
        /*
                    for (let stage of this.reservedTrip.stages) {
                      if (stage.errors && stage.errors.length > 0) {
                        for (let e of stage.errors) {
                          this.errors.push(e);
                        }
                        break;
                      }
                    }
          */
        if (this.errors.length > 0) {
          this.showErrorDialog = true;
        }

        this.initReservedTrip();
      }
    });
  }

  initReservedTrip() {
    this.newQuote = this.reservedTrip.bookingStatus == "NewQuote";

    this.newQuote = false;

    this.showMap = this.reservedTrip.getShowMap() && this.isWebView;

    let firstDay = null;
    for (const stage of this.reservedTrip.stages) {
      if (stage.type === ReservedStageType.Accommodation) {
        if (firstDay != null && firstDay != stage.dateIn.toDateString()) {
          firstDay = stage.dateIn.toDateString();
        } else {
          if (stage.luggageTransfer !== "YES") {
           
          }
          this.luggageTransfer = stage.luggageTransfer == "YES";
        }
        /*
        if (
          this.luggageTransfer == null
        ) {
          
        } else if (
          this.luggageTransfer && (stage.luggageTransfer != 'YES') ||
          !this.luggageTransfer && (stage.luggageTransfer == 'YES')
        ) {
          this.allLuggageSame = false;
        }*/
      }
    }

    const modelDepartureDate: IMyDateModel = {
      isRange: false,
      singleDate: { jsDate: this.reservedTrip.departureDate },
      dateRange: null,
    };

    this.dateForm.controls.departureDate.setValue(modelDepartureDate, {
      emitEvent: false,
    });

    const modelTaxiDate: IMyDateModel = {
      isRange: false,
      singleDate: { jsDate: this.reservedTrip.departureDate },
      dateRange: null,
    };

    this.taxiForm.controls.date.setValue(modelTaxiDate, { emitEvent: false });

    this.externalMapUrl = environment.mapPath + this.reservedTrip.id;
    this.setDateTaxiOptions();
    this.updateControls();
  }

  setFtcWeather() {
    if (this.type == "map" || this.type == "historical") {
      this.reservedTripService
        .getWeatherData(this.reservedTrip.id)
        .subscribe((res) => {
          if (this.type == "historical") {
            this.widget = new WidgetHistoricalClass(this.widgetId, 1);
          } else {
            this.widget = new WidgetMapClass(this.widgetId);
          }

          Object.keys(this.widget).map((key, index) => {
            if (res[key]) {
              this.widget[key] = res[key];
            }
          });

          this.widget.init();

          setTimeout(() => {
            if (this.widget.weatherMap) {
              this.widget.weatherMap.updateSize();
            }
          }, 3000);
        });
    } else {
      this.reservedTripService
        .getForecastData(this.reservedTrip.id)
        .subscribe((res) => {
          this.widget = new WidgetForecastClass(this.widgetId);

          Object.keys(this.widget).map((key, index) => {
            if (res[key]) {
              this.widget[key] = res[key];
            }
          });

          this.widget.init();
        });
    }
  }

  setFtcWeatherType(type: string) {
    if (this.type != type) {
      this.type = type;
      this.setFtcWeather();
    }

    // this.showForecast = true;
  }

  setDateTaxiOptions() {
    const dtIni = new Date(this.reservedTrip.departureDate);
    dtIni.setDate(dtIni.getDate() - 1);
    const dtEnd = new Date(this.reservedTrip.lastDate);
    dtEnd.setDate(dtEnd.getDate() + 1);

    this.taxiDateOptions = {
      dateRange: false,
      dateFormat: "dd/mm/yyyy",
      disableUntil: {
        year: dtIni.getFullYear(),
        month: dtIni.getMonth() + 1,
        day: dtIni.getDate(),
      },
      disableSince: {
        year: dtEnd.getFullYear(),
        month: dtEnd.getMonth() + 1,
        day: dtEnd.getDate(),
      },
      // other options...
    };
  }

  setHasChanged() {
    this.hasChanged =
      this.rc.nbSingle.value != this.reservedTrip.nbSingle ||
      this.rc.nbDouble.value != this.reservedTrip.nbDouble ||
      this.rc.nbTwin.value != this.reservedTrip.nbTwin ||
      this.rc.nbTriple.value != this.reservedTrip.nbTriple ||
      this.rc.nbDorm.value != this.reservedTrip.nbDorm;
  }
  downSingle() {
    if (this.rc.nbSingle.value > 0) {
      this.rc.nbSingle.setValue(this.rc.nbSingle.value - 1);
    }
  }
  downDouble() {
    if (this.rc.nbDouble.value > 0) {
      this.rc.nbDouble.setValue(this.rc.nbDouble.value - 1);
    }
  }

  downTwin() {
    if (this.rc.nbTwin.value > 0) {
      this.rc.nbTwin.setValue(this.rc.nbTwin.value - 1);
    }
  }
  downTriple() {
    if (this.rc.nbTriple.value > 0) {
      this.rc.nbTriple.setValue(this.rc.nbTriple.value - 1);
    }
  }
  downDorm() {
    if (this.rc.nbDorm.value > 0) {
      this.rc.nbDorm.setValue(this.rc.nbDorm.value - 1);
    }
  }
  upSingle() {
    this.rc.nbSingle.setValue(this.rc.nbSingle.value + 1);
  }
  upDouble() {
    this.rc.nbDouble.setValue(this.rc.nbDouble.value + 1);
  }
  upTwin() {
    this.rc.nbTwin.setValue(this.rc.nbTwin.value + 1);
  }
  upTriple() {
    this.rc.nbTriple.setValue(this.rc.nbTriple.value + 1);
  }
  upDorm() {
    this.rc.nbDorm.setValue(this.rc.nbDorm.value + 1);
  }

  updatePax() {
    this.pax =
      this.rc.nbSingle.value +
      this.rc.nbDouble.value * 2 +
      this.rc.nbTwin.value * 2 +
      this.rc.nbDorm.value +
      this.rc.nbTriple.value * 3;
    this.fillInvoice();
    this.setHasChanged();
  }

  updateModel() {
    if (this.pax > 0) {
      this.showChangePaxDialog = true;
    }
  }

  public updateControls() {
    this.rc.nbSingle.setValue(this.reservedTrip.nbSingle, { emitEvent: false });
    this.rc.nbDouble.setValue(this.reservedTrip.nbDouble, { emitEvent: false });
    this.rc.nbTwin.setValue(this.reservedTrip.nbTwin, { emitEvent: false });
    this.rc.nbTriple.setValue(this.reservedTrip.nbTriple, { emitEvent: false });
    this.rc.nbDorm.setValue(this.reservedTrip.nbDorm, { emitEvent: false });
    this.updatePax();
  }

  changeLuggage(value: boolean) {
    this.reservedTripService
      .changeLuggage(this.reservedTrip.id, value ? "YES" : "NO")
      .subscribe((res) => { });
  }
  changeDepartureDate() {
    if (!this.sending) {
      this.sending = true;

      const dt = this.dc.departureDate.value.singleDate.jsDate;

      this.reservedTripService
        .updateDepartureDate(
          this.reservedTrip.id,
          new Date(dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset()))
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res) => {
            //this.reservedTrip = res;
            this.showDepartureDateDialog = false;
            this.sending = false;
          },
          (err) => {
            this.showDepartureDateDialog = false;
            this.sending = false;
          }
        );
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changePax() {
    if (!this.sending) {
      this.sending = true;

      this.reservedTripService
        .updatePassengers(
          this.reservedTrip.id,
          this.rc.nbSingle.value,
          this.rc.nbDouble.value,
          this.rc.nbTwin.value,
          this.rc.nbTriple.value,
          this.rc.nbDorm.value
        )
        .subscribe(
          (res) => {
            this.sending = false;
            //this.reservedTrip = res;
          },
          (err) => {
            this.sending = false;
          }
        );
    }
  }

  fillInvoice() {
    this.invoiceItems = [];
    let baseShared = 0;
    let baseSingle = 0;
    let extraShared = 0;
    let extraSingle = 0;
    let discountBase = 0;
    let discountExtra = 0;
    let sharedPax =
      2 * this.rc.nbDouble.value +
      2 * this.rc.nbTwin.value +
      3 * this.rc.nbTriple.value +
      this.rc.nbDorm.value;
    let singlePax = this.rc.nbSingle.value;

    let priceShared = 0;
    let priceSingle = 0;

    for (let t of this.reservedTrip.trips) {
      if (t.priceRange) {
        priceShared = sharedPax * t.priceRange.priceShared;
        priceSingle =
          singlePax * (t.priceRange.priceShared + t.priceRange.priceSinglePlus);
      } else if (t.highShared) {
        priceShared = sharedPax * parseFloat(t.highShared);
        priceSingle =
          singlePax * parseFloat(t.highShared + t.highSingleSuplement);
      }

      if (sharedPax > 0) {
        baseShared += priceShared;

        this.invoiceItems.push({
          name: t.code + " shared rooms",
          amount: sharedPax,
          price: priceShared,
        });
      }

      if (singlePax > 0) {
        baseSingle += priceSingle;

        this.invoiceItems.push({
          name: t.code + " single rooms",
          amount: singlePax,
          price: priceSingle,
        });
      }
    }



    if (this.reservedTrip.lastPricing) {

      if (this.reservedTrip.lastPricing.combinedDiscount > 0) {
        if (baseShared > 0 && baseSingle > 0) {
          baseShared -= this.reservedTrip.lastPricing.combinedDiscount;
          baseSingle -= this.reservedTrip.lastPricing.combinedDiscount;
          this.invoiceItems.push({
            name: "Combined trip discount",
            amount: 1,
            price: -2 * this.reservedTrip.lastPricing.combinedDiscount,
          });
        } else if (baseShared > 0) {
          baseShared -= this.reservedTrip.lastPricing.combinedDiscount;
          this.invoiceItems.push({
            name: "Combined trip discount",
            amount: 1,
            price: -1 * this.reservedTrip.lastPricing.combinedDiscount,
          });
        } else if (baseSingle > 0) {
          baseSingle -= this.reservedTrip.lastPricing.combinedDiscount;
          this.invoiceItems.push({
            name: "Combined trip discount",
            amount: 1,
            price: -1 * this.reservedTrip.lastPricing.combinedDiscount,
          });
        }
      }

      for (let extra of this.reservedTrip.lastPricing.extras) {
        if (extra.isSingle) {
          extraSingle += extra.price;

          this.invoiceItems.push({
            name: extra.name,
            amount: extra.number,
            price: extra.price,
          });
        }
      }

      for (let extra of this.reservedTrip.lastPricing.extras) {
        if (extra.isShared) {
          extraShared += extra.price;

          this.invoiceItems.push({
            name: extra.name,
            amount: extra.number,
            price: extra.price,
          });
        }
      }

      if (
        this.reservedTrip.lastPricing.discount.base > 0 &&
        baseSingle + baseShared > 0
      ) {
        discountBase =
          (this.reservedTrip.lastPricing.discount.base *
            (baseSingle + baseShared)) /
          100;
        this.invoiceItems.push({
          name: this.reservedTrip.lastPricing.discount.name,
          amount: 1,
          price: -1 * discountBase,
        });
      }

      if (
        this.reservedTrip.lastPricing.discount.extra > 0 &&
        extraShared + extraSingle > 0
      ) {
        discountExtra =
          (this.reservedTrip.lastPricing.discount.extra *
            (extraShared + extraSingle)) /
          100;
        this.invoiceItems.push({
          name: this.reservedTrip.lastPricing.discount.name,
          amount: 1,
          price: -1 * discountExtra,
        });
      }
    }

    let amountOwed =
      baseSingle +
      baseShared +
      extraShared +
      extraSingle -
      discountBase -
      discountExtra;

    this.amountOwed = 0;
    for (let passenger of this.reservedTrip.passengers) {
      this.amountOwed += passenger.amount;

      if (!this.reservedTrip.useAutoPricing) {
        for (let extra of passenger.customExtras) {
          this.invoiceItems.push({
            name: extra.description,
            amount: 1,
            price: extra.amount,
          });
          amountOwed += extra.amount;
        }
      }
    }

    this.percentage = 0;

    if (this.reservedTrip.lastPricing) {
      this.percentage =
        (100 * this.reservedTrip.lastPricing.paymentStatus.amountPaid) /
        this.amountOwed;
    }

  }

  createTaxi() {
    if (!this.sending && !this.taxiForm.invalid) {
      this.sending = true;

      const dt = this.tc.date.value.singleDate.jsDate;

      this.reservedTripService
        .createTaxi(
          this.reservedTrip.id,
          new Date(dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset())),
          this.tc.hour.value,
          this.tc.minute.value,
          this.tc.from.value.id,
          this.tc.to.value.id,
          this.tc.pax.value,
          this.tc.flightNumber.value
        )
        .subscribe(
          (res) => {
            this.showTaxiDialog = false;
            this.sending = false;
          },
          (err) => {
            this.showTaxiDialog = false;
            this.sending = false;
          }
        );
    }
  }

  goToMap() {
    this.router.navigate([this.externalMapUrl]);
  }
}
