import { Component, Input, OnInit } from "@angular/core";
import isWebview from "is-ua-webview";
import { City } from "src/app/models/city.model";
import { environment } from "src/environments/environment";
import {
    GalleryItem,
    ImageItem,
    ImageSize,
    ThumbnailsPosition,
    Gallery,
} from 'ng-gallery';
import { Lightbox } from 'ng-gallery/lightbox';
import { TranslateService } from "src/app/translate/translate.service";

@Component({
    selector: "app-city-card",
    templateUrl: "./city-card.component.html",
    styleUrls: []
})
export class CityCardComponent {
    public _city: City;
    public downloadHost: string;
    public galleryItems: GalleryItem[] = [];
    public slides: any[] = [];
    public text: any = {};

    @Input()
    public set city(c: City) {
        this._city = c;

        this.galleryItems = this._city.files.map(
            (item) => new ImageItem(
                {
                    src: this.downloadHost + '/public/download/' + item.fileId,
                    thumb: this.downloadHost + '/public/download/' + item.fileId
                }
            )
        );

        this.text = {};
        if (this._city.touristAttractionKeywords) {
            for (let key of this._city.touristAttractionKeywords) {
                this.translateService.getItem(key).subscribe(
                    res => {
                        this.text[key] = res;
                        this.updateSlides();
                    }
                );
            }

            /*
        this.slides = this._city.touristAttractionKeywords.map(
            (item) => {
                return { innerHTML: item  }
            }
        )
*/
        }



        /*
            const lightboxRef = this.gallery.ref('lightbox');
        
            // Add custom gallery config to the lightbox (optional)
            lightboxRef.setConfig({
              imageSize: ImageSize.Cover,
              thumbPosition: ThumbnailsPosition.Top,
            });
        
            // Load items into the lightbox gallery ref
            lightboxRef.load(this.galleryItems);
            */
    }

    updateSlides() {
        this.slides = [];
        for (let key in this.text) {
            this.slides.push(
                {
                    innerHTML: this.text[key]
                }
            );
        }
    }

    constructor(
        public gallery: Gallery,
        private translateService: TranslateService
    ) {
        this.downloadHost = environment.urlApi;
    }

}