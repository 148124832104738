import { Component, AfterViewInit, ViewChild, ElementRef, Input, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { createPopper } from "@popperjs/core";
import { ReservedDay } from "src/app/models/reserved-day.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ClickService } from "src/app/services/click.service";
import { ReservedTripService } from "src/app/services/reserved-trip.service";

@Component({
  selector: "app-accommodation-dropdown",
  templateUrl: "./accommodation-dropdown.component.html",
  styleUrls: ["accommodation-dropdown.component.css"]
})
export class AccommodationDropdownComponent implements OnInit, AfterViewInit {
  dropdownPopoverShow = false;
  @ViewChild("btnDropdownRef", { static: false }) btnDropdownRef: ElementRef;
  @ViewChild("popoverDropdownRef", { static: false })
  popoverDropdownRef: ElementRef;

  public showAccommodationDialog = false;
  public showMealsDialog = false;
  public showConfirmationDialog = false;
  public showNightsDialog = false;
  public showLuggageDialog = false;
  public showRemoveConfirmationDialog = false;

  public oldNights = null;
  public nights = 1;

  @Input()
  public day: ReservedDay;

  @Input()
  public reservedTrip: ReservedTrip;

  public formAccommodation: FormGroup;
  public controlAccommodations: FormArray;
  public accommodationTypeOptions: Array<Array<string>> = [];
  public accommodationTypeValues: Array<Array<number>> = [];
  public accommodationTypeSelected: Array<number> = [];
  public customizatedAccommodation: Array<boolean> = [];
  public ownAccommodationSelected: Array<boolean> = [];

  public formMeals: FormGroup;
  public controlsMeals: FormArray;
  public mealOptions: Array<Array<string>> = [];
  public mealValues: Array<Array<string>> = [];;
  public mealSelected: Array<string> = [];

  public formLuggage: FormGroup;
  public constrolsLuggage: FormArray;
  public luggageSelected: Array<string> = [];
  public dateIn: Date;
  public lastIn: Date;
  public onlyOneStage: boolean;
  public onlyOneDay: boolean;
  public locationName: string;
  public accommodationTypeOrder = [4, 3, 2, 5, 6];

  public sending = false;

  constructor(
    private formBuilder: FormBuilder,
    private reservedTripService: ReservedTripService,
    private clickService: ClickService
  ) {
  }

  ngOnInit(): void {

    this.clickService.$clickSubject.subscribe(
      () => {
        this.toggleDown();
      }
    );

    const controlsAccommodation = []
    const controlsMeals = [];
    const controlsLuggage = [];

    let index = 0;
    for (let stage of this.day.accommodationStages) {

      controlsAccommodation.push('');
      controlsMeals.push('');
      controlsLuggage.push('');

      this.mealOptions[index] = [];
      this.mealValues[index] = [];
      this.accommodationTypeOptions[index] = [];
      this.accommodationTypeValues[index] = [];

      index++;
    }


    //all accommodation stage must have the same init and finish dates
    //clone for don't modify originals objects
    this.dateIn = new Date(this.day.accommodationStages[0].dateIn);
    this.lastIn = new Date(this.day.accommodationStages[0].dateOut);
    this.lastIn.setDate(this.lastIn.getDate() - 1);
    this.onlyOneDay = (this.dateIn == this.lastIn);
    this.locationName = this.day.accommodationStages[0].toLocationName;

    this.onlyOneStage = (index == 1);


    this.nights = this.day.nightsAccommodationStages;
    this.oldNights = this.nights;

    this.formAccommodation = this.formBuilder.group({
      accommodationTypesArray: this.formBuilder.array(controlsAccommodation)
    });
    this.controlAccommodations = <FormArray>this.formAccommodation.controls['accommodationTypesArray'];

    this.formMeals = this.formBuilder.group({
      mealsArray: this.formBuilder.array(controlsMeals)
    });
    this.controlsMeals = <FormArray>this.formMeals.controls['mealsArray'];

    this.formLuggage = this.formBuilder.group({
      luggageArray: this.formBuilder.array(controlsLuggage)
    });
    this.constrolsLuggage = <FormArray>this.formLuggage.controls['luggageArray'];

    this.refreshAccommodationTypes();
    this.refreshMeals();
    this.refreshLuggage();
  }

  refreshAccommodationTypes() {
    this.accommodationTypeSelected = [];

    for (let stage of this.day.accommodationStages) {
      if (stage.dataAccommodation.customizationType) {
        this.accommodationTypeSelected.push(stage.dataAccommodation.customizationType);
      } else if (!stage.reservedStep) {
        this.accommodationTypeSelected.push(0);
      }
      else {
        this.accommodationTypeSelected.push(stage.reservedStep.accommodationType.id + 1);
      }
    }

    let index = 0;
    for (let stage of this.day.accommodationStages) {
      this.customizatedAccommodation[index] = (stage.dataAccommodation.customizationType == 1);
      this.ownAccommodationSelected[index] = stage.dataAccommodation.isOwnAccommodation;
      if (this.ownAccommodationSelected[index]) {
        this.controlAccommodations.controls[index].setValue(-1);
      } else {
        this.controlAccommodations.controls[index].setValue(this.accommodationTypeSelected[index]);
      }
      //this.ownAccommodationSelected[index] = stage.dataAccommodation.isOwnAccommodation;
      index++;
    }
    this.updateMealsOptions();
  }

  refreshMeals() {
    this.mealSelected = [];
    for (let stage of this.day.accommodationStages) {
      if (stage.meals) {
        this.mealSelected.push(stage.meals);
      } else if (!stage.reservedStep) {
        this.mealSelected.push("NM");
      }
      else {
        this.mealSelected.push(stage.reservedStep.defautlMeal);
      }
    }

    let index = 0;
    for (let stage of this.day.accommodationStages) {
      this.controlsMeals.controls[index].setValue(this.mealSelected[index]);
      index++;
    }


    this.updateAccommodationOptions();
  }

  updateAccommodationOptions() {
    //depends of meals selected


    let index = 0;
    for (let stage of this.day.accommodationStages) {
      const mealsSelected = this.mealSelected[index];

      const accommodationTypeOptions: Array<string> = [];
      const accommodationTypeValues: Array<number> = [];

      for (let supplier of this.day.accommodationSuppliers) {
        //const mealsSelected = this.mealSelected[index];

        for (let type of supplier.types) {
          if (!accommodationTypeValues.includes(type.type.id + 1)) {
            if (mealsSelected == 'NM') {
              accommodationTypeValues.push(type.type.id + 1);
              accommodationTypeOptions.push(type.type.name);
            }
            else if (mealsSelected == 'BB') {
              if (supplier.hasBreakfast) {
                accommodationTypeValues.push(type.type.id + 1);
                accommodationTypeOptions.push(type.type.name);
              }
            }
            else if (mealsSelected == 'BD') {
              if (supplier.hasBreakfast && supplier.hasDinner) {
                accommodationTypeValues.push(type.type.id + 1);
                accommodationTypeOptions.push(type.type.name);
              }
            }
            else if (mealsSelected == 'BDP') {
              if (supplier.hasBreakfast && supplier.hasDinner && supplier.hasLunch) {
                accommodationTypeValues.push(type.type.id + 1);
                accommodationTypeOptions.push(type.type.name);
              }
            }
          }
        }
      }

      this.accommodationTypeValues[index] = [];
      this.accommodationTypeOptions[index] = [];

      for (let i = 0; i < this.accommodationTypeOrder.length; i++) {
        const pos = accommodationTypeValues.indexOf(this.accommodationTypeOrder[i]);
        if (pos > -1) {

          this.accommodationTypeValues[index].push(accommodationTypeValues[pos]);
          this.accommodationTypeOptions[index].push(accommodationTypeOptions[pos]);
        }
      }

      // this.controlAccommodations[index].controls.setValue(this.accommodationTypeSelected[index]);

      index++;
    }
  }

  updateMealsOptions() {
    //depens of accommodationType selected
    //Si es 1 - Specific accommodation solo me valdrían los del supplier en cuestion!!!

    let index = 0;
    for (let stage of this.day.accommodationStages) {
      const accommodationTypesSelected = this.accommodationTypeSelected[index] - 1;

      if (
        //stage.dataAccommodation.customizationType == null || 
        stage.dataAccommodation.customizationType == 1
      ) {
        const supplier = stage.supplier;

        if (supplier) {
          if (supplier.dataAccommodation.hasBreakfast) {
            if (!this.mealValues[index].includes('BB')) {
              this.mealValues[index].push("BB");
              this.mealOptions[index].push('clientarea.meals.breakfast');
            }

            if (supplier.dataAccommodation.hasDinner) {
              if (!this.mealValues[index].includes('BD')) {
                this.mealValues[index].push("BD");
                this.mealOptions[index].push('clientarea.meals.breakfastanddinner');
              }

              if (supplier.dataAccommodation.hasLunch) {
                if (!this.mealValues[index].includes('BDP')) {
                  this.mealValues[index].push("BDP");
                  this.mealOptions[index].push('clientarea.meals.breakfastdinnerpicnicnextday');
                }
              }
            }
          }
        }

      } else {

        for (let supplier of this.day.accommodationSuppliers) {

          let supplierMatchAccommodation = false;
          for (let accommodationType of supplier.types) {
            if (accommodationType.type.id == accommodationTypesSelected) {
              supplierMatchAccommodation = true;
              break;
            }
          }

          if (supplierMatchAccommodation) {
            if (supplier.hasBreakfast) {
              if (!this.mealValues[index].includes('BB')) {
                this.mealValues[index].push("BB");
                this.mealOptions[index].push('clientarea.meals.breakfast');
              }

              if (supplier.hasDinner) {
                if (!this.mealValues[index].includes('BD')) {
                  this.mealValues[index].push("BD");
                  this.mealOptions[index].push('clientarea.meals.breakfastanddinner');
                }

                if (supplier.hasLunch) {
                  if (!this.mealValues[index].includes('BDP')) {
                    this.mealValues[index].push("BDP");
                    this.mealOptions[index].push('clientarea.meals.breakfastdinnerpicnicnextday');
                  }
                }
              }
            }

          }
        }

      }

      //this.controlsMeals[index].controls.setValue(this.mealSelected[index]);
      index++;
    }

  }

  refreshLuggage() {
    let index = 0;
    for (let stage of this.day.accommodationStages) {
      this.luggageSelected[index] = stage.luggageTransfer;
      this.constrolsLuggage.controls[index].setValue(stage.luggageTransfer);
    }
  }

  ngAfterViewInit() {
    createPopper(
      this.btnDropdownRef.nativeElement,
      this.popoverDropdownRef.nativeElement,
      {
        placement: "bottom-start",
      }
    );
  }

  toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.dropdownPopoverShow) {
      this.toggleDown();
    } else {
      this.dropdownPopoverShow = true;
    }
    
  }

  toggleDown(){
    this.dropdownPopoverShow = false;
  }

  toggleShowAccommodationDialog(event) {
    this.showAccommodationDialog = !this.showAccommodationDialog;
    this.toggleDropdown(event);
  }

  toggleShowMealsDialog(event) {
    this.showMealsDialog = !this.showMealsDialog;
    this.toggleDropdown(event);
  }
  toggleShowConfirmationDialog(event) {
    this.showConfirmationDialog = !this.showConfirmationDialog;
    this.toggleDropdown(event);
  }

  toggleShowNightsDialog(event) {
    this.showNightsDialog = !this.showNightsDialog;
    this.toggleDropdown(event);
  }

  toggleShowLuggageDialog(event) {
    this.showLuggageDialog = !this.showLuggageDialog;
    this.toggleDropdown(event);
  }

  upNights() {
    this.nights++;
  }

  downNights() {
    this.nights--;
  }

  changeNights() {

    if (!this.sending && this.oldNights != this.nights) {
      this.sending = true;

      let index = 0;
      let data = { stages: [], nights: this.nights };

      for (let stage of this.day.accommodationStages) {
        data.stages.push(stage.id);
        index++;
      }


      this.reservedTripService.updateNights(this.reservedTrip.id, data).subscribe(
        res => {
          this.showNightsDialog = false;
          this.sending = false;
          this.oldNights = this.nights;
        },
        err => {
          this.showNightsDialog = false;
          this.sending = false;
        }
      );
    }

  }
  ownAccommodation() {

    if (!this.sending) {
      this.sending = true;
      const data = [];
      for (let stage of this.day.accommodationStages) {
        data.push(stage.id);
      }
      this.reservedTripService.ownAccommodation(this.reservedTrip.id, data).subscribe(
        res => {
          this.showConfirmationDialog = false;
          this.sending = false;
        },
        err => {
          this.showConfirmationDialog = false;
          this.sending = false;
        }
      );
    }
  }


  changeAccommodationType() {
    //Go to customize +1
    if (!this.sending && this.formAccommodation.dirty) {
      this.sending = true;

      let index = 0;
      let data = { stages: [] };
      for (let stage of this.day.accommodationStages) {
        data.stages.push({
          id: stage.id,
          type: this.controlAccommodations.controls[index].value
        });
        index++;
      }
      this.reservedTripService.customizeType(this.reservedTrip.id, data).subscribe(
        res => {
          this.showAccommodationDialog = false;
          this.sending = false;
        },
        err => {
          this.showAccommodationDialog = false;
          this.sending = false;
        }
      );
    }
  }


  changeMeal() {
    if (!this.sending && this.formMeals.dirty) {
      this.sending = true;

      let index = 0;
      let data = { stages: [] };
      for (let stage of this.day.accommodationStages) {
        data.stages.push({
          id: stage.id,
          type: this.controlsMeals.controls[index].value
        });
        index++;
      }
      this.reservedTripService.customizeMeal(this.reservedTrip.id, data).subscribe(
        res => {
          this.showMealsDialog = false;
          this.sending = false;
        },
        err => {
          this.showMealsDialog = false;
          this.sending = false;
        }
      )
    }

  }

  changeLuggage() {

    if (!this.sending && this.formLuggage.dirty) {
      this.sending = true;

      let index = 0;
      let data = { stages: [] };
      for (let stage of this.day.accommodationStages) {

        data.stages.push({
          id: stage.id,
          type: this.constrolsLuggage.controls[index].value
        });
        index++;
      }
      this.reservedTripService.changeLuggage(this.reservedTrip.id, data).subscribe(
        res => {
          this.showLuggageDialog = false;
          this.sending = false;
        },
        err => {
          this.sending = false;
          this.showLuggageDialog = false;
        }
      );

    }

  }
}
