import { InjectionToken } from "@angular/core";

export interface JwtConfig {
    host: string;
    loginPath: string;
    tokenLoginPath: string;
    refreshTokenPath: string;
    userPath: string;
    loginRoute: string;
    landingRoute: string;
    anomRoute:string;
}

export const JwtServiceConfig = new InjectionToken<JwtConfig>("JwtConfig");