import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { LocationPoint } from "src/app/models/location-point.model";
import { LocationPointService } from "src/app/services/location-point.service";

@Component({
    selector: "app-location-input",
    templateUrl: "./location-input.component.html",
    styleUrls: ["./location-input.component.css"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: LocationInputComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: LocationInputComponent
        }
    ]
})
export class LocationInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

    onChange = (quantity) => { };
    onTouched = () => { };
    touched = false;
    disabled = false;
    private value: LocationPoint;
    public form: FormGroup;

    private textChanged: Subject<string> = new Subject<string>();
    private destroy$ = new Subject();

    @Input()
    public placeholder: string;

    @Input()
    public historyHeading: string = 'Recently selected';


    public data: Array<LocationPoint> = [];

    public searchKeyword = 'name';

    constructor(
        private locationService: LocationPointService,
        private formBuilder: FormBuilder
    ) {
    }

    onChangeSearch(search: string) {
        this.textChanged.next(search);
    }

    ngOnInit(): void {

        this.form = this.formBuilder.group({
            point: [this.value]
        });

        this.form.controls.point.valueChanges.subscribe(
            res=>{
                this.value = res;
                this.onChange(res);
            }
        );

        this.textChanged.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe(
            search => {

                this.locationService.getList(search).subscribe(
                    res => {
                        if (res) {
                            for (let item of res.items) {
                                item.setIcon();
                            }
                            this.data = res.items;;
                        }
                    }
                )
            }
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    writeValue(value: LocationPoint) {
        this.value = value;
        if(this.form){
            this.form.controls.point.setValue(value, { emitEvent: false });
        }
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const lcation = control.value;
        if (location == null) {
            return {
                emptyValue: true
            };
        }
    }
}
