import { City } from "./city.model";
import { ReservedDay } from "./reserved-day.model";
import { ReservedStageAccommodation } from "./reserved-stage-accommodation.model";
import { ReservedStageActivity } from "./reserved-stage-activity.model";
import { ReservedStageBike } from "./reserved-stage-bike.model";
import { ReservedStageFlight } from "./reserved-stage-flight.model";
import { ReservedStageGuide } from "./reserved-stage-guide.model";
import { ReservedStageMeal } from "./reserved-stage-meal.model";
import { ReservedStepModificationExtra } from "./reserved-step-modification-extra.model";
import { ReservedStagePeopleTransfer } from "./reserved-stage-people-transfer.model";
import { ReservedStep } from "./reserved-step.model";
import { Supplier } from "./supplier.model";
import { RoomAllocation } from "./room-allocation.model";

export enum ReservedStageType {
    Accommodation = 1,
    PeopleTransfer = 2,
    Other = 4,
    Guide = 5,
    Activity = 6,
    Meal = 7,
    Flight = 8,
    Bike = 9,
    LuggageTransfer = -1,
    Walking = -2
}
export class ReservedStage {
    public id: string;
    public type: ReservedStageType;
    public dateIn: Date;
    public dateOut: Date;
    public supplier: Supplier = null;
    public nbSingle: number;
    public nbDouble: number;
    public nbTriple: number;
    public nbDorm: number;
    public nbTwin: number;
    public nbNights: number;
    public meals: string;
    public mealsKeyword: string;
    public commentary: string;
    public order: number;
    public pax: number;
    public city: City;
    public fromLocationName: string;
    public toLocationName: string;
    public luggageTransfer: string;
    public luggageNumber: number;
    public luggageTransferSupplier: Supplier;
    public time: Date;
    public dataAccommodation: ReservedStageAccommodation;
    public dataPeopleTransfer: ReservedStagePeopleTransfer;
    public dataMeal: ReservedStageMeal;
    public dataGuide: ReservedStageGuide;
    public dataBike: ReservedStageBike;
    public dataFlight: ReservedStageFlight;
    public dataActivity: ReservedStageActivity;
    public reservedStep: ReservedStep;
    public modificationExtras: Array<ReservedStepModificationExtra>;
    public roomAllocations: Array<RoomAllocation>;
    public errors: Array<string>;
    public progression: number;
    public accommodationDistance:number;

    public isOwnAccommodation(): boolean {
        if (this.dataAccommodation) {
            return this.dataAccommodation.isOwnAccommodation
        } else {
            return (this.supplier !== null)
        }
    }


    public isBeginDay(day: ReservedDay): boolean {
        const dayString = day.date.getFullYear() + ' ' + day.date.getMonth() + ' ' + day.date.getDate();
        const initString = this.dateIn.getFullYear() + ' ' + this.dateIn.getMonth() + ' ' + this.dateIn.getDate();
        return dayString == initString;
    }

    public isEndDay(day: ReservedDay): boolean {

        if (this.dateOut) {
            const dayString = day.date.getFullYear() + ' ' + day.date.getMonth() + ' ' + day.date.getDate();
            const endString = this.dateOut.getFullYear() + ' ' + this.dateOut.getMonth() + ' ' + this.dateOut.getDate();
            return dayString == endString;
        } else {
            return this.isBeginDay(day);
        }
    }

    get days(): number {
        // The number of milliseconds in all UTC days (no DST)
        const oneDay = 1000 * 60 * 60 * 24;

        // A day in UTC always lasts 24 hours (unlike in other time formats)
        const start = Date.UTC(this.dateOut.getFullYear(), this.dateOut.getMonth(), this.dateOut.getDate());
        const end = Date.UTC(this.dateIn.getFullYear(), this.dateIn.getMonth(), this.dateIn.getDate());

        // so it's safe to divide by 24 hours
        return ((start - end) / oneDay) + 1;
    }

    public get defaultBikePrice() {
        if (this.dataBike && this.dataBike.defaultBikeOption) {
            return this.dataBike.defaultBikeOption.costPerDay * this.days + this.dataBike.defaultBikeOption.costPerService;
        } else {
            return 0;
        }
    }
    
    public hasRoomType(type) {
        for (let r of this.roomAllocations) {
            if (r.type.name == type) {
                return true;
            }
        }
        return false;
    }

    public hasPassengerRoom() {
        for (let r of this.roomAllocations) {
            if (r.type.name != 'Guide') {
                return true;
            }
        }
        return false;
    }

    public isEditable() {
        return (this.progression == 0 || this.progression == 8);
    }

    public showSupplier() {
        return (this.supplier && this.progression == 2);
    }

}