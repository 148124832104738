import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";
import { ServicesComponent } from "./views/admin/services/services.component";

// admin views
import { ItineraryComponent } from "./views/admin/itinerary/itinerary.component";
import { PassengersComponent } from "./views/admin/passengers/passengers.component";
import { RoomAllocationsComponent } from "./views/admin/room-allocations/room-allocations.component";
import { AcceptDenyComponent } from "./views/auth/accept-deny/accept-deny.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";
import { ResetPasswordComponent } from "./views/auth/reset-password/reset-password.component";

import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";
import { PaymentComponent } from "./views/admin/payment/payment.component";
import { ForgotPasswordComponent } from "./views/auth/forgot-password/forgot-password.component";
import { HolidayPackPostComponent } from "./views/admin/holiday-pack-post/holiday-pack-post.component";
import { HolidayPackDownloadComponent } from "./views/admin/holiday-pack-download/holiday-pack-download.component";
import { InvoiceComponent } from "./views/admin/invoice/invoice.component";
import { AuthTokenComponent } from "./views/auth/auth-token/auth-token.component";
import { MapLayoutComponent } from "./layouts/map/map-layout.component";
import { DeletedComponent } from "./views/auth/deleted/deleted.component";
import { GroupsComponent } from "./views/groups/groups.component";
import { CityViewComponent } from "./views/city/city-view.component";
import { DeleteProfileComponent } from "./views/auth/delete-profile/delete-profile.component";

const routes: Routes = [
  // admin views
  {
    path: "admin",
    component: AdminComponent,
    children: [
      { path: "itinerary/:id", component: ItineraryComponent },
      { path: "passengers/:id", component: PassengersComponent },
      { path: "room-allocation/:id", component: RoomAllocationsComponent },
      { path: "services/:id", component: ServicesComponent },
      { path: "payment/:id", component: PaymentComponent },
      { path: "holiday-pack-posted/:id", component: HolidayPackPostComponent },
      { path: "holiday-pack-download/:id", component: HolidayPackDownloadComponent },
      { path: "invoice/:id", component: InvoiceComponent },
      //{ path: "", redirectTo: "dashboard", pathMatch: "full" },
    ],
  },
  {
    path: "maps/:id",
    component: MapLayoutComponent
  },
  // auth views
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "login", component: LoginComponent },
      { path: "auth-token", component: AuthTokenComponent },
      { path: "register", component: RegisterComponent },
      { path: "reset-password", component: ResetPasswordComponent },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "accept-deny/:passenger", component: AcceptDenyComponent },
      { path: "delete-profile", component: DeleteProfileComponent },
      { path: "deleted", component: DeletedComponent },
      { path: "", redirectTo: "login", pathMatch: "full" },
      { path: "**", redirectTo: "login", pathMatch: "full" },
    ],
  },
  // no layout views
  { path: "profile", component: ProfileComponent },
  { path: "landing", component: LandingComponent },
  { path: "city/:id/:lang", component: CityViewComponent },
  { path: "groups/:id", component: GroupsComponent },
  { path: "", redirectTo: "landing", pathMatch: "full" },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled', // or 'top'
    anchorScrolling: 'enabled',
    scrollOffset: [0, 0], // [x, y] - adjust scroll offset
   // onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
