import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { City } from "src/app/models/city.model";
import { CityService } from "src/app/services/city.service";
import { TranslateService } from "src/app/translate/translate.service";

@Component({
    selector: "app-city-view",
    templateUrl: "./city-view.component.html",
    styleUrls: []
})
export class CityViewComponent implements OnInit {
    public city: City;

    constructor(
        private cityService: CityService,
        private translateService: TranslateService,
        private router: Router
    ) {
    }

    ngOnInit(){
        const parts = this.router.url.split("/");
        let city = parts[parts.length - 2];
        let lang = parts[parts.length - 1];

        this.translateService.chageLocale(lang);

        this.cityService.getOne(city).subscribe(
            res=>{
                this.city = res;
            }
        )
    }
}