import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExchangeService } from './exchange.service';


@Pipe(
    {
        name: 'currency'
    }
)
export class ExchangePipe implements PipeTransform {


    constructor(
        private service: ExchangeService,
        private _ref: ChangeDetectorRef
    ) {
    }

    transform(value: number): Observable<number> {
        return this.service.getExchangeValue(value).pipe(map(res=>res?res:value));
    }
}