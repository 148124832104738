import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ClientData } from "../models/client-data.model";
import { PhoneArea } from "../models/phone-area.model";
import { PhoneNumber } from "../models/phone-number.model";
import { UserPassenger } from "../models/user-passenger.model";

@Injectable({ providedIn: 'root' })
export class UserService {

    constructor(private http: HttpClient) {
    }

    setPassword(password, token): Observable<any> {
        return this.http.put(environment.urlApi +
            '/public/reset_password/' + token, { password: password });
    }

    sendRecoverMail(mail: string) {
        return this.http.post(environment.urlApi +
            '/public/reset_password', { mail: mail });
    }

    createUser(
        email: string,
        gender: string,
        firstName: string,
        lastName: string,
       // birthDate: Date,
        phoneArea: string,
        phoneNumber: string,
        country: string
    ) {

        const data = {
            email: email,
            gender: gender,
            firstName: firstName,
            lastName: lastName,
        //    birthDate: birthDate,
            phoneArea: phoneArea,
            phoneNumber: phoneNumber,
            country: country
        }

        return this.http.post(environment.urlApi +
            '/public/user', data);
    }

    confirmUser(token) {
        return this.http.put(environment.urlApi +
            '/public/user/' + token, {});
    }

    getUserProfile(): Observable<UserPassenger>{
        return this.http.get(environment.urlApi+'/api/user_info?extended=1').pipe(map(
            res=>{
                return this.unserializeUser(res);
            }
        ))
    }

    unserializeUser(item): UserPassenger{
        if (item.birthDate) {
            item.birthDate = new Date(item.birthDate);
        }

        if (item.clientData) {

            if(item.clientData.phone){
                if(item.clientData.phone.countryCode){
                    item.clientData.phone.countryCode = Object.assign(new PhoneArea(), item.clientData.phone.countryCode);
                }
                item.clientData.phone = Object.assign(new PhoneNumber(),item.clientData.phone);
            }

            item.clientData = Object.assign(new ClientData(),item.clientData);
        }

       return Object.assign(new UserPassenger(), item);
    }

    sendProfileImage(form: FormData) {
        return this.http.post(environment.urlApi+'/api/upload_user_profile_image', form);
    }

    updateProfile(user: UserPassenger): Observable<UserPassenger> {
        return this.http.put(environment.urlApi+'/api/user_info', user).pipe(map(
            res=>{
                return this.unserializeUser(res);
            }
        ));
    }

    deleteProfile(username:string,password: string): Observable<Object> {

        console.log('GOING TO DELETE PROFILE');
        return this.http.post(environment.urlApi+'/api/user_delete', { username: username, password: password });
    }

}