import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { JwtService } from "src/app/utils/jwt/jwt.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public submited: boolean = false;
  private ngUnsubscribe = new Subject();
  public reCaptchaKey: string;
  public errorKeyword: string = null;

  constructor(
    private authenticationService: JwtService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {


    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }


  get f() { return this.loginForm.controls; }

  public submit() {
    this.submited = true;

    if (!this.loginForm.invalid) {

      this.errorKeyword = null;
      this.login("");
      
      /*this.recaptchaV3Service.execute('login').subscribe(
        (token) => 
      );*/
    } else{
     
    }
  }

  login(token: string) {

    this.authenticationService.authenticate(
      this.f.username.value,
      this.f.password.value,
      token
    ).subscribe(
      res => {
        if (!res) {
          this.errorKeyword = 'Invalid user or password';
        }
      },
      error => {

        if (error.status == 401) {
          this.errorKeyword = 'Invalid user or password';
        } else {
          this.errorKeyword = 'Something goes wrong, please try again later';
        }

      }
    )
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
