import { SupplierAccommodationType } from "./supplier-accommodation-type.model";

export class SupplierAccommodation {
    public rate: number;
    public type: string;
    public hasBreakfast: boolean;
    public breakfastFromTime: Date;
    public breakfastToTime: Date;
    public breakfastOnSite: boolean;
    public breakfastLocation: string;
    public hasLunch: boolean;
    public lunchFromTime: Date;
    public lunchToTime: Date;
    public lunchOnSite: boolean;
    public lunchLocation: string;
    public hasDinner: boolean;
    public dinnerFromTime: Date;
    public dinnerToTime: Date;
    public dinnerOnSite: boolean;
    public dinnerLocation: string;
    public hasWifi: boolean;
    public wifiPassword: string;
    public hasOutdoorPool: boolean;
    public hasIndoorPool: boolean;
    public hasAirConditioning: boolean;
    public has24hFrontDesk: boolean;
    public hasParkCar: boolean;
    public hasBikeStorage: boolean;
    public hasLaundry: boolean;
    public hasMassage: boolean;
    public latitude: number;
    public longitude: number;
    public trackToCityPedestrian: string;
    public trackToCityBike: string;
    public types:Array<SupplierAccommodationType>;
}