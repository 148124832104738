import { ReservedDay } from "./reserved-day.model";

export class GroupBooking {
    public id: number;
    public reference: string;
    public name: string;
    public priceShared: number;
    public plusSingle: number;
    public pictureId: number;
    public status: number;
    public reservationStatus: number;
    public departureDate: Date;
    public title: string;

    public displayJustEnabledRooms: boolean;

    //show allways except is 0 and displayJustEnabled
    public nbSingle: number;
    public nbTwin: number;
    public nbDouble: number;

    //show only when >0 and displayJustEnabled
    public nbTriple: number;
    public nbDorm: number;

    public days: Array<ReservedDay>;
    public message: GroupBookingMessage;

    get from(): string {

        if (this.days[0]) {
            if (this.days[0].from) {
                return this.days[0].from;
            }
            if (this.days[0].to) {
                return this.days[0].to;
            }
        }
    }
    get to(): string {
        if (this.days[this.days.length - 1]) {
            if (this.days[this.days.length - 1].to) {
                return this.days[this.days.length - 1].to;
            }
            if (this.days[this.days.length - 1].from) {
                return this.days[this.days.length - 1].from;
            }

        }
    }


    isExpired() {
        return false;
    }

    isInProgress() {
        return (this.reservationStatus == GroupBookingReservationStatus.InProgress || this.reservationStatus == GroupBookingReservationStatus.New)
    }

    //we can use the departure date too
    getHideAccommodation() {
        return (this.reservationStatus == GroupBookingReservationStatus.InProgress || this.reservationStatus == GroupBookingReservationStatus.New)
    }
}

export class GroupBookingMessage{
    public id: number;
    public title: string;
    public message: string;
}

export enum GroupBookingStatus {
    Open = 0,
    Full
}

export enum GroupBookingReservationStatus {
    New = 0,
    InProgress,
    Confirmed,
    Cancelled
}

export enum GroupBookingAccommodationStatus {
    NotSet=0,
    InProgress,
    Confirmed,
    Cancelled
}

export enum GroupBookingPaymentStatus {
    NotPaid=0,
    PartiallyPaid,
    FullyPaid
}
