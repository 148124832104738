import { HolidayPackItem } from "./holiday-pack-item.model";
import { ReservedTripHolidayPackItemActivity } from "./reserved-trip-holiday-pack-item-activity.model";

export class HolidayPack {
    public gender: string;
    public firstName: string;
    public lastName: string;
    public street:string;
    public postalCode:string;
    public town:string;
    public country:string;
    public tripItems: HolidayPackItem[];
    public reservedTripActivities: ReservedTripHolidayPackItemActivity[];
    public reservedTripLinks: HolidayPackItem[];
    public isToHotel: number;
}