import { ViewportScroller } from "@angular/common";
import {ChangeDetectorRef, Component, HostListener, OnInit } from "@angular/core";
import { delay } from "rxjs/operators";
import { ClickService } from "./services/click.service";
import { LoadingService } from "./utils/loading/loading.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public showLoading: boolean = true;
  public title = "angular-dashboard-page";

  public loading: boolean = false;
  public scrolling: boolean = false;

  public pageYoffset = 0;

  @HostListener('window:scroll', ['$event']) onScroll(event){
    this.pageYoffset = window.pageYOffset;
  }

  constructor(
    private _loading: LoadingService,
    private _changeDetector: ChangeDetectorRef,
    private scroll: ViewportScroller,
    private clickService: ClickService
  ){ }


  ngOnInit() {
    this.listenToLoading();
    
  }

  scrollTop(){
    
    const top = document.getElementById('offsetTop');
    if(top){
      this.scroll.scrollToPosition([0,top.offsetTop]);  
    } else{
      this.scroll.scrollToPosition([0,0]);
    }
  }

  /**
   * Listen to the loadingSub property in the LoadingService class. This drives the
   * display of the loading spinner.
   */
  listenToLoading(): void {
    this._loading.loadingSub
      //.pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
        this._changeDetector.detectChanges();
      });
  }

  click(){
    this.clickService.click();
  }
}
