import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

import Feature from 'ol/Feature';
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { TrackFile } from "src/app/models/track-file.model";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import * as olProj from 'ol/proj';
import Point from "ol/geom/Point";
import { Fill, Icon, Stroke, Style, Text } from 'ol/style';
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-map-layout",
  templateUrl: "./map-layout.component.html",
  styleUrls: ["./map-layout.component.css"]
})
export class MapLayoutComponent implements OnInit, OnDestroy {

  private routerPath: string;
  public reservedTrip: ReservedTrip;
  public questionIndex = -1;
  public trackFiles: Array<TrackFile>;
  public layers: Array<any>;

  public panelVisible: boolean = false;
  public stage: ReservedStage;
  public type: string;
  public downloadHost: string;

  constructor(
    private router: Router,
    private reservedTripService: ReservedTripService
  ) {
    this.routerPath = this.router.url;
    this.downloadHost = environment.urlApi;

    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          this.routerPath = event.url;
          this.questionIndex = event.url.indexOf('?');
        }
      }
    );

  }

  ngOnDestroy(): void {
    if (
      typeof window['webkit'] !== 'undefined' && window['webkit'] !== null &&
      typeof window['webkit'].messageHandlers !== 'undefined' && window['webkit'].messageHandlers !== null &&
      typeof window['webkit'].messageHandlers.unfollowReservedTrip !== 'undefined' &&
      typeof window['webkit'].messageHandlers.unfollowReservedTrip !== null
    ) {
      window['webkit'].messageHandlers.unfollowReservedTrip.postMessage({});
    }
  }

  ngOnInit(): void {



    const parts = this.routerPath.split("/");
    let last = parts[parts.length - 1];
    this.questionIndex = last.indexOf('?');
    if (this.questionIndex > -1) {
      last = last.substring(0, this.questionIndex);
    }

    this.reservedTrip = null;

    this.reservedTripService.getLastItem().subscribe(
      res => {
        if (res) {
          this.reservedTrip = res;

          if (
            typeof window['webkit'] !== 'undefined' && window['webkit'] !== null &&
            typeof window['webkit'].messageHandlers !== 'undefined' && window['webkit'].messageHandlers !== null &&
            typeof window['webkit'].messageHandlers.followReservedTrip !== 'undefined' &&
            typeof window['webkit'].messageHandlers.followReservedTrip !== null
          ) {
            window['webkit'].messageHandlers.followReservedTrip.postMessage({
              reservedTripId: res.id
            });
          }

          this.reservedTripService.getTracks(res.id).subscribe(
            res => {
              this.trackFiles = res.items;
            }
          );

          this.reservedTripService.getItinerary(res.id).subscribe(
            res => {

              let citiesFeatures = [];
              let accommodationFeatures = [];
              let taxisFeatures = [];
              let restaurantsFeatures = [];
              //accomodations 

              for (let stage of res.stages) {
                if (stage.type == 1) {
                  if (stage.city && stage.city.locationPoint) {


                    citiesFeatures.push(
                      this.getFeature(
                        stage.city.name,
                        stage.city.locationPoint.longitude,
                        stage.city.locationPoint.latitude,
                        'assets/icons/maps/city.svg',
                        "city",
                        stage
                      )
                    );
                  }

                  if (stage.supplier) {

                    accommodationFeatures.push(
                      this.getFeature(
                        stage.supplier.name,
                        stage.supplier.getLongitude(),
                        stage.supplier.getLatitude(),
                        'assets/icons/maps/hotel.svg',
                        "hotel",
                        stage
                      )
                    );

                  }

                } else if (
                  stage.type == 2
                  &&
                  stage.dataPeopleTransfer
                  &&
                  stage.dataPeopleTransfer.fromLocationPoint
                ) {
                  taxisFeatures.push(
                    this.getFeature(
                      "TAXI",
                      stage.dataPeopleTransfer.fromLocationPoint.longitude,
                      stage.dataPeopleTransfer.fromLocationPoint.latitude,
                      'assets/icons/maps/taxi.svg',
                      "taxi",
                      stage
                    )
                  );
                } else if (stage.type == 7) {
                  if (stage.supplier) {
                    restaurantsFeatures.push(
                      this.getFeature(
                        "RESTAURANT",
                        stage.supplier.getLongitude(true),
                        stage.supplier.getLatitude(true),
                        'assets/icons/maps/meals.svg',
                        "meals",
                        stage
                      )
                    );

                  }
                }
              }

              const layers = [];
              if (citiesFeatures.length > 0) {
                layers.push(
                  new VectorLayer({
                    source: new VectorSource({
                      features: citiesFeatures
                    })
                  }));
              }
              if (accommodationFeatures.length > 0) {
                layers.push(
                  new VectorLayer({
                    source: new VectorSource({
                      features: accommodationFeatures
                    })
                  }));
              }
              if (taxisFeatures.length > 0) {
                layers.push(
                  new VectorLayer({
                    source: new VectorSource({
                      features: taxisFeatures
                    })
                  }));
              }
              if (restaurantsFeatures.length > 0) {
                layers.push(
                  new VectorLayer({
                    source: new VectorSource({
                      features: restaurantsFeatures
                    })
                  }));
              }
              this.layers = layers;
            }
          );
        }

      }
    );

    this.reservedTripService.getOne(last).subscribe(
      res => {

        //    this.reservedTrip = res;
      },
      err => {

      }
    );

  }

  getFeature(name, longitude, latitude, icon, type, stage) {
    const feature = new Feature({
      name: name,
      geometry: new Point(olProj.fromLonLat(
        [
          longitude,
          latitude
        ]
      ))
    });

    feature.set("type", type);
    feature.set("stage", stage);

    feature.setStyle(
      new Style({
        text: new Text(
          {
            font: '13px Calibri,sans-serif',
            fill: new Fill({ color: '#000' }),
            text: name,
            backgroundFill: new Fill({
              color: 'rgba(255,255,255,0.7)'
            })
          }
        ),
        image: new Icon({
          crossOrigin: 'anonymous',
          size: type == 'city' ? [80, 50] : [80, 50],
          displacement: type == 'city' ? [0, 35] : [0, 35],
          src: icon,
        },
        )
      })

    );

    return feature;
  }

  showFeature(feature: Feature) {

    if (feature instanceof PointerEvent) {

    } else if (feature.get) {
      this.type = feature.get('type');

      if (this.type && this.type != 'city') {
        this.stage = feature.get('stage');
        this.panelVisible = true;
      }
    }

  }

  closeFeature() {
    this.panelVisible = false;
  }


  getBgClass(type) {
    switch (type) {
      case 1:
        return 'bg-emerald-500';
      case 2: //people transfer
      case 8: //flight
      case 9: //bike
        return 'bg-red-600';
      case 5://guide
      case 6://activity
        return 'bg-orange-500';
      case 7://restaurant
        return 'bg-teal-500';
    }

  }

  closeMap() {
    this.router.navigateByUrl('/admin/itinerary/' + this.reservedTrip.id);
  }
}
