import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { ExchangeService } from './exchange.service';


@Pipe(
    {
        name: 'currencysymbol'
    }
)
export class CurrencySymbolPipe implements PipeTransform {

    constructor(
        private service: ExchangeService
    ) {
    }

    transform(value: string): string {
        switch(this.service.getCurrency()){
            case 'EUR':
                return value + '\xa0€';
            case 'USD':
                return value + '\xa0$';
            case 'GBP':
                return value + '\xa0£';
            case 'RUB':
                return value + '\xa0₽';
            case 'JPY':
                return value + '\xa0¥';
        }
        return ' €';
    }
}