import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { HolidayPack } from "src/app/models/holiday-pack.model";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedPassengerService } from "src/app/services/reserved-passenger.service";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-holiday-pack-post",
  templateUrl: "./holiday-pack-post.component.html",
})
export class HolidayPackPostComponent implements OnInit, OnDestroy {
  public reservedTrip: ReservedTrip;
  public loaded = false;
  private destroy$ = new Subject();
  public color = "light";
  public hp:HolidayPack;
  public urlApi:string;
  public hasActivities: boolean;

  constructor(
    private reservedTripService: ReservedTripService,
  ) {
    this.urlApi = environment.urlApi;
  }

  ngOnInit(): void {
    //this.hp.reservedTripLinks;
    //this.hp.reservedTripActivities;
    //this.hp.tripItems;

    this.reservedTripService.getLastItem().subscribe(
      res => {
        if (res) {
          this.reservedTrip = res;

          this.reservedTripService.getHolidayPack(this.reservedTrip.id).subscribe(
            res => {
              this.hp = res;
              this.hasActivities = false; //(this.hp.reservedTripActivities && this.hp.reservedTripActivities.length >0);
              this.loaded = true;
            }
          );
        }
      }
    );

  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
