import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Allergy } from "../models/allergy.model";
import { BikeOption } from "../models/bike-option.model";
import { ClientData } from "../models/client-data.model";
import { ListResult } from "../models/list-result.model";
import { PassengerBikeOption } from "../models/passenger-bike-option.model";
import { PassengerBikeOptionalItem } from "../models/passenger-bike-optional-item.model";
import { PaymentMade } from "../models/payment-made.model";
import { PhoneArea } from "../models/phone-area.model";
import { PhoneNumber } from "../models/phone-number.model";
import { ReservedPassenger, ReservedPassengerStage } from "../models/reserved-passenger.model";
import { ReservedStageGuide } from "../models/reserved-stage-guide.model";
import { ReservedTrip } from "../models/reserved-trip.model";
import { RoomAllocationsSummary } from "../models/room-allocations-summary.model";
import { RoomAllocation } from "../models/room-allocation.model";
import { RoomType } from "../models/room-type.model";
import { SplitPaymentLink } from "../models/split-payment-link.model";
import { UserPassenger } from "../models/user-passenger.model";
import { UserService } from "./user.service";
import { SplitPayment } from "../models/split-paymnet.model";
import { ReservedPassengerCustomExtra } from "../models/reserved-passenger-custom-extra.model";

@Injectable({ providedIn: 'root' })
export class ReservedPassengerService {
    //private lastItem: ReplaySubject<ReservedTrip> = new ReplaySubject<ReservedTrip>();

    constructor(private http: HttpClient, private userService: UserService) { }

    getList(reservedTrip: ReservedTrip, params?): Observable<ListResult<ReservedPassenger>> {
        //reservedTrip
        if (params == null) {
            params = { itemsPerPage: "10", page: "1" };
        }

        return this.http.get(environment.urlApi + '/api/reserved_passengers/' + reservedTrip.id, { params: params }).pipe(map(
            res => {
                const result = new ListResult<ReservedPassenger>();

                for (let i = 0; i < res['items'].length; i++) {
                    result.items.push(this.unserializeItem(res['items'][i]));
                }
                result.totalItems = res['totalItems'];
                return result;
            }
        ));
    }

    update(passenger: ReservedPassenger): Observable<ReservedPassenger> {
        return this.http.put(environment.urlApi + '/api/reserved_passengers/' + passenger.id, passenger).pipe(
            map(
                res => this.unserializeItem(res)
            )
        );
    }

    invite(passenger: ReservedPassenger): Observable<ReservedPassenger> {
        return this.http.put(environment.urlApi + '/api/reserved_passengers/' + passenger.id + '/invite', passenger).pipe(
            map(
                res => this.unserializeItem(res)
            )
        );
    }

    unserializeItem(item): ReservedPassenger {

        if (item.allergies && item.allergies.length > 0) {
            for (let i = 0; i < item.allergies.length; i++) {
                item.allergies[i] = Object.assign(new Allergy(), item.allergies[i]);
            }
        }


        if (item.diets && item.diets.length > 0) {
            for (let i = 0; i < item.diets.length; i++) {
                item.diets[i] = Object.assign(new Allergy(), item.diets[i]);
            }
        }

        if (item.user) {
            item.user = this.userService.unserializeUser(item.user);
        }
        if (item.bikeOptions) {
            for (let i = 0; i < item.bikeOptions.length; i++) {
                item.bikeOptions[i] = Object.assign(new PassengerBikeOption(), item.bikeOptions[i]);
            }
        }
        if (item.bikeOptionalItems) {
            for (let i = 0; i < item.bikeOptionalItems.length; i++) {
                item.bikeOptionalItems[i] = Object.assign(new PassengerBikeOptionalItem(), item.bikeOptionalItems[i]);
            }
        }
        if (item.stages) {
            for (let i = 0; i < item.stages.length; i++) {
                item.stages[i] = Object.assign(new ReservedPassengerStage(), item.stages[i]);
            }
        }

        if (item.splitPaymentLinks) {
            for (let i = 0; i < item.splitPaymentLinks.length; i++) {

                if (item.splitPaymentLinks[i].splitPayment) {
                    if (item.splitPaymentLinks[i].splitPayment.paymentMade) {
                        item.splitPaymentLinks[i].splitPayment.paymentMade.date = new Date(item.splitPaymentLinks[i].splitPayment.paymentMade.date);
                        item.splitPaymentLinks[i].splitPayment.paymentMade = Object.assign(new PaymentMade(), item.splitPaymentLinks[i].splitPayment.paymentMade);
                    }

                    item.splitPaymentLinks[i].splitPayment = Object.assign(new SplitPayment(), item.splitPaymentLinks[i].splitPayment);
                }

                item.splitPaymentLinks[i] = Object.assign(new SplitPaymentLink(), item.splitPaymentLinks[i]);
            }
        }

        if (item.customExtras) {
            for (let i = 0; i < item.customExtras.length; i++) {
                item.customExtras[i] = Object.assign(new ReservedPassengerCustomExtra(), item.customExtras[i]);
            }
        }

        if (item.room) {
            if (item.room.type) {
                item.room.type = Object.assign(new RoomType(), item.room.type);
            }
            item.room = Object.assign(new RoomAllocation(), item.room);
        }

        if (item.baseRoomAllocationsSummary) {
            item.baseRoomAllocationsSummary = Object.assign(new RoomAllocationsSummary(), item.baseRoomAllocationsSummary);
        }

        if (item.roomAllocationsSummary) {
            item.roomAllocationsSummary = Object.assign(new RoomAllocationsSummary(), item.roomAllocationsSummary);
        }


        return Object.assign(new ReservedPassenger(), item);
    }


    setBikeOption(idPassenger, idStage, idBikeOption) {
        const data = { stage: idStage, bikeOption: idBikeOption };
        return this.http.put(environment.urlApi + '/api/reserved_passengers/' + idPassenger + '/bike_options', data).pipe(
            map(
                res => this.unserializeItem(res)
            )
        );
    }

    addBikeOptionalItem(idPassenger, idStage, idBikeOptionalItem) {
        const data = { stage: idStage, bikeOptionalItem: idBikeOptionalItem };
        return this.http.post(environment.urlApi + '/api/reserved_passengers/' + idPassenger + '/bike_optional_items', data).pipe(
            map(
                res => this.unserializeItem(res)
            )
        );
    }

    delBikeOptionalItem(idPassenger, idStage, idBikeOptionalItem) {
        const data = { stage: idStage, bikeOptionalItem: idBikeOptionalItem };
        return this.http.put(environment.urlApi + '/api/reserved_passengers/' + idPassenger + '/bike_optional_items', data).pipe(
            map(
                res => this.unserializeItem(res)
            )
        );
    }

    addStage(idPassenger, idStage) {
        return this.http.post(environment.urlApi + '/api/reserved_passengers/' + idPassenger + '/stages/' + idStage, {}).pipe(
            map(
                res => this.unserializeItem(res)
            )
        );
    }


    removeStage(idPassenger, idStage) {
        return this.http.delete(environment.urlApi + '/api/reserved_passengers/' + idPassenger + '/stages/' + idStage).pipe(
            map(
                res => this.unserializeItem(res)
            )
        );
    }


}