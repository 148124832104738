import { Inject, Injectable, OnDestroy } from "@angular/core";
import { TranslateServiceConfig } from "./translate-config.interface";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TranslateService implements OnDestroy {
    private map: object = {};
    private observables: object = {};
    private loadedSubject: Subject<boolean> = new Subject<boolean>();


    constructor(
        @Inject(TranslateServiceConfig) private config,
        private http: HttpClient
    ) {
        var lang = localStorage.getItem('Translate-Language');
        if (lang == null) {
            lang = this.config.default;
        }

        this.loadedSubject.subscribe(
            res => {
                for (let key in this.map) {
                    if (!this.observables[key]) {
                        this.observables[key] = new BehaviorSubject<string>("");
                    }

                    this.observables[key].next(this.map[key]);
                }
            }
        )

        this.chageLocale(lang);
    }

    ngOnDestroy(): void {
        this.loadedSubject.unsubscribe();
    }

    chageLocale(lang: string): void {


        this.http.get(this.config.url + lang).subscribe(
            res => {
                //this.map = res;
                for (const k in res) {
                    this.map[k] = res[k].replace('&nbsp;', ' ').replace('<br>', ' ');
                }

                localStorage.setItem('Translate-Language', lang);

                this.loadedSubject.next(true);
            },
            err => {
               
            }
        )
    }

    getLocale(): string{
        var lang = localStorage.getItem('Translate-Language');
        if (lang == null) {
            lang = this.config.default;
        }

        return lang;
    }

    getItem(key: string): Observable<string> {
        if (!this.observables[key]) {
            this.observables[key] = new BehaviorSubject<string>("");
        }

        return this.observables[key];
    }
}