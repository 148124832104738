import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedTripService } from "src/app/services/reserved-trip.service";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
})
export class AdminComponent implements OnInit {

  private routerPath: string;
  public reservedTrip: ReservedTrip;
  public questionIndex = -1;
  public section ="";

  constructor(
    private router: Router,
    private reservedTripService: ReservedTripService
  ) {
    this.routerPath = this.router.url;

    this.router.events.subscribe(
      event => {
        if (event instanceof NavigationEnd) {
          this.routerPath = event.url;
          this.questionIndex =  event.url.indexOf('?');
        }
      }
    );

  }

  ngOnInit(): void {


    const parts = this.routerPath.split("/");
    let last = parts[parts.length - 1];
    this.questionIndex = last.indexOf('?');
    if (this.questionIndex > -1) {
      last = last.substring(0, this.questionIndex);
    }

    this.reservedTrip = null;

    this.reservedTripService.getLastItem().subscribe(
      res=>{
        this.reservedTrip = res;
      }
    );

    this.reservedTripService.getOne(last).subscribe(
      res => {
    
    //    this.reservedTrip = res;
      },
      err=>{
       
      }
    );

  }
}
