import { Allergy } from "./allergy.model";
import { Diet } from "./diet.model";
import { Discount } from "./discount.model";
import { PassengerBikeOption } from "./passenger-bike-option.model";
import { PassengerBikeOptionalItem } from "./passenger-bike-optional-item.model";
import { ReservedPassengerCustomExtra } from "./reserved-passenger-custom-extra.model";
import { RoomAllocationsSummary } from "./room-allocations-summary.model";
import { SplitPaymentLink } from "./split-payment-link.model";
import { UserPassenger } from "./user-passenger.model";

export class ReservedPassengerStage {
    public id: number;
}
export class ReservedPassenger {
    public id: number;
    public number: number;
    public firstName: string;
    public lastName: string;
    public salutation: string = null;
    public numberLuggage: number;
    public email: string;
    public isEditable: boolean;
    public me: boolean = false;

    /*
     * 2  - Accept
     * 1 - Invited
     * -1 - Deny
     */
    public inviteState: number = null;
    public diets: Array<Diet>;
    public allergies: Array<Allergy>;
    public hasOtherDiet: boolean;
    public otherDiet: string;
    public hasOtherAllergies: boolean;
    public otherAllergy: string;
    public user: UserPassenger;
    public bikeOptions: Array<PassengerBikeOption>;
    public bikeOptionalItems: Array<PassengerBikeOptionalItem>;
    public stages: Array<ReservedPassengerStage>;
    public splitPaymentLinks: Array<SplitPaymentLink> = [];

    public customExtras: Array<ReservedPassengerCustomExtra> = [];
    public amount: number = 0;
    public baseGrossPrice: number = 0;
    public basePrice: number = 0;
    public extraPrice: number = 0;
    public adjustmentValue: number = 0;
    /**
     * 0 not paid
     * 1 > %30
     * 2 Payed
     */
    public paymentStatus: number = 0;
    /**
     * 1 normal
     * 2 custom
     * 3 canceled
     *  
     */
    public priceType: number = 0;
    public customBasePrice: number = 0;;
    public discount: Discount;
    public roomAllocationsSummary: RoomAllocationsSummary;
    public baseRoomAllocationsSummary: RoomAllocationsSummary;
    public customExtrasErrors: Array<string> = [];


    isInvitable() {
        return (this.email != null && this.email.length > 0 && this.number > 1 && !this.me && (this.inviteState == 0 || this.inviteState == null));
    }

    fullName() {
        return this.firstName + ' ' + this.lastName;
    }

    public get paid(): number {
        let amount = 0;
        for (let splitPaymentLink of this.splitPaymentLinks) {
            if (splitPaymentLink.splitPayment.paymentMade.confirmed) {
                amount += splitPaymentLink.amount - splitPaymentLink.amountRefunded;
            }
        }
        return amount;
    }

    public get balance(): number {
        return this.amount - this.paid;
    }
/*
    public get deposit(): number {
        return this.amount * 0.3 - this.paid;
    }

    public get depositPaid(): boolean{
        return this.paid > this.deposit;
    }
    */

    public get balancePaid(): boolean {
        return this.paid >= this.amount;
    }

    public get extraAmount(): number {
        let amount = 0;
        for (let item of this.customExtras) {
            amount += item.amount;
        }
        return amount;
    }

    public get nbSingle(): number {
        return this.roomAllocationsSummary.Single;
    }


    public get nbShared(): number {
        return this.roomAllocationsSummary.Double + this.roomAllocationsSummary.Twin + this.roomAllocationsSummary.Triple + this.roomAllocationsSummary.Dorm;
    }

}