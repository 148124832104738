import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtService } from './jwt.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BearerInterceptor } from './bearer.interceptor';
import { JwtConfig, JwtServiceConfig } from './jwt-config.interface';
import { JwtNavigationService } from './jwt-navigation.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule
    ]
})
export class JwtModule {
    static forRoot(config: JwtConfig): ModuleWithProviders<JwtModule> {
        return {
            ngModule: JwtModule,
            providers: [
                {
                    provide: JwtServiceConfig,
                    useValue: config
                },
                { 
                    provide: HTTP_INTERCEPTORS, 
                    useClass: BearerInterceptor, 
                    multi: true 
                },
                JwtNavigationService,
                JwtService,
            ]
        }
    }
}