import { Component, OnInit } from "@angular/core";
import { JwtService } from "src/app/utils/jwt/jwt.service";
import { User } from "src/app/utils/jwt/user.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import isWebview from "is-ua-webview";
import { Router } from "@angular/router";
import { GroupBooking } from "src/app/models/group-booking.model";
import { GroupBookingService } from "src/app/services/group-booking.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-groups",
  templateUrl: "./groups.component.html",
  styleUrls:['./groups.component.css']
})
export class GroupsComponent implements OnInit {
  public group: GroupBooking;
  private today: Date;
  public todayString: string;
  public pax: number = 0;
  public roomsForm: FormGroup;
  public showChangePaxDialog: boolean = false;
  public created = false;
  public sending = false;
  public errorKeyword: string;
  public modified = false;
  /*
  public name: string = "";
  private user: User;
  public nextTrips: Array<ReservedTrip> = [];
  private nextTripsPage: number = 1;
  private nextTripsTotalItems: number = 0;
  public previousTrips: Array<ReservedTrip>;
  private previousTripsPage: number = 1;
  private previousTripsTotalItems: number = 0;
  public nextTrip: ReservedTrip = null;

  private _MS_PER_DAY = 1000 * 60 * 60 * 24;
  public nextTripDaysInterval: number;
  public nextTripReference: string;
  public nextTripCode: string;
  public nextTripId: string;
*/
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private groupBookingService: GroupBookingService
  ) { }

  ngOnInit(): void {

    const parts = this.router.url.split("/");
    let last = parseInt(parts[parts.length - 1], 10);

    this.groupBookingService.getOne(last).subscribe(
      res => {
        this.group = res;
        
        if(this.group.message){
          this.group.message.message = this.group.message.message.replace('â‚¬','€').replace('â‚¬','€');
        }
        this.todayString = res.departureDate.toLocaleDateString("en-US", options);
      }
    )

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };

    this.today = new Date();
    this.todayString = this.today.toLocaleDateString("en-US", options);


    this.roomsForm = this.formBuilder.group({
      nbSingle: [0],
      nbDouble: [0],
      nbTwin: [0],
      nbTriple: [0],
      nbDorm: [0],
      email: ['', [Validators.required, Validators.email]],
      gender: [''],
      firstName: ['', [Validators.required, Validators.maxLength(45)]],
      lastName: ['', [Validators.required, Validators.maxLength(45)]],
      //birthDate: ['', Validators.required],
      phoneArea: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.maxLength(45)]]
    });

  }


  submit(){

  }

  get rc() {
    return this.roomsForm.controls;
  }
  
  downSingle() {
    if (this.rc.nbSingle.value > 0) {
      this.rc.nbSingle.setValue(this.rc.nbSingle.value - 1);

    }
  }
  downDouble() {
    if (this.rc.nbDouble.value > 0) {
      this.rc.nbDouble.setValue(this.rc.nbDouble.value - 1);

    }
  }
  downTwin() {
    if (this.rc.nbTwin.value > 0) {
      this.rc.nbTwin.setValue(this.rc.nbTwin.value - 1);

    }
  }
  downTriple() {
    if (this.rc.nbTriple.value > 0) {
      this.rc.nbTriple.setValue(this.rc.nbTriple.value - 1);

    }
  }
  downDorm() {
    if (this.rc.nbDorm.value > 0) {
      this.rc.nbDorm.setValue(this.rc.nbDorm.value - 1);

    }
  }
  upSingle() {
    this.rc.nbSingle.setValue(this.rc.nbSingle.value + 1);

  }
  upDouble() {
    this.rc.nbDouble.setValue(this.rc.nbDouble.value + 1);

  }
  upTwin() {
    this.rc.nbTwin.setValue(this.rc.nbTwin.value + 1);

  }
  upTriple() {
    this.rc.nbTriple.setValue(this.rc.nbTriple.value + 1);

  }
  upDorm() {
    this.rc.nbDorm.setValue(this.rc.nbDorm.value + 1);

  }
}
