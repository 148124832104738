import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { HolidayPack } from "src/app/models/holiday-pack.model";;
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-holiday-pack-download",
  templateUrl: "./holiday-pack-download.component.html",
})
export class HolidayPackDownloadComponent implements OnInit, OnDestroy {
  public reservedTrip: ReservedTrip;
  public loaded = false;
  private destroy$ = new Subject();
  public color = "light";
  public hp: HolidayPack;
  public urlApi: string;
  public hasActivities: boolean;
  public hideDownload: boolean;
  public voucherDownloadUrl: string;

  constructor(
    private reservedTripService: ReservedTripService,
  ) {
    this.urlApi = environment.urlApi;
  }

  ngOnInit(): void {

    this.reservedTripService.getLastItem().subscribe(
      res => {
        if (res) {
          this.reservedTrip = res;
          // this.voucherDownloadUrl = this.urlApi + '/public/download_file/Vouchers/' + this.reservedTrip.id + '.pdf';

          this.reservedTripService.getHolidayPack(this.reservedTrip.id, true).subscribe(
            res => {
              this.hp = res;
              this.hasActivities = (this.hp.reservedTripActivities && this.hp.reservedTripActivities.length > 0);
              this.hideDownload = (this.reservedTrip.bookingStatus == 'NewQuote' || this.reservedTrip.bookingStatus == 'InProgress')
              this.loaded = true;
            }
          );
        }
      }
    );

  }

  downloadVoucher(event) {
    if (this.reservedTrip.getShowVoucher()) {
      this.reservedTripService.getVoucher(this.reservedTrip.id, "Voucher_" + this.reservedTrip.reference + ".pdf");
    }
    event.preventDefault();
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
