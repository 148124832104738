import { AccommodationType } from "./accommodation-type.model";
import { ActivityOption } from "./activity-option.model";
import { BikeOption } from "./bike-option.model";
import { City } from "./city.model";
import { LocationPoint } from "./location-point.model";
import { Menu } from "./menu.model";
import { Supplier } from "./supplier.model";
import { Trip } from "./trip.model";

export class ReservedStep{
    public id:number;
    public tripFrom:Trip;
    public order:number;
    public day: number;
    public nights:number;
    public type:number;
    public city:City;
    public defaultHotel:Supplier;
    public accommodationType:AccommodationType;
    public defautlMeal:string;
    public defaultLuggageTransfer:string;
    public transferTime:Date;
    public trasnferFromPoint: LocationPoint;
    public transferToPoint:LocationPoint;
    public peopleTransferDay:number;
    public restaurantTime:Date;
    public restaurantMenu:Menu;
    public activityTime:Date;
    public activityOption:ActivityOption;
    public guideFromDay:number;
    public guideMeetingTime:Date;
    public guidetoDay:number;
    public guideFinishingTime:number;
    public bikeDeliveryPoint:LocationPoint;
    public bikeCollectionPoint:LocationPoint;
    public bikeFromDay:number;
    public bikeToDay:number;
    public bikeOption: BikeOption;
    public bikeIncludeOptionalsItems:boolean;
    
    sameCity(){
        return true;
    }
}