export class Position {
    private _latitude: number = null;
    private _longitude: number = null;
    private _heading: number = null;

    get latitude(): number {
        return this._latitude;
    }
    set latitude(l: number) {
        this._latitude = l;
    }

    get longitude(): number {
        return this._longitude;
    }
    set longitude(l: number) {
        this._longitude = l;
    }

    get heading(): number {
        return this._heading;
    }

    set heading(h: number) {
        this._heading = h;
    }

    decimalToSexagesimal(decimal, type) {
        var degrees = decimal | 0;
        var fraction = Math.abs(decimal - degrees);
        var minutes = (fraction * 60) | 0;
        var seconds = (fraction * 3600 - minutes * 60) | 0;

        var direction = "";
        var positive = degrees > 0;
        degrees = Math.abs(degrees);
        switch (type) {
            case "lat":
                direction = positive ? "N" : "S";
                break;
            case "lng":
                direction = positive ? "E" : "W";
                break;
        }

        return degrees + "° " + minutes + "' " + seconds + "\" " + direction;
    }
}
