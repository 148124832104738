import { PriceRange } from "./price-range.model";

export class Trip{
    public id:string;
    public code:string;
    public prictureId:string;
    public titleEng:string;
    public subtitleEng:string;
    public priceRange: PriceRange;
    public highShared:string;
    public highSingleSuplement:string;
}