import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { PassengerBalanceSelection } from "src/app/models/passenger-balance-selection.model";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedPassengerService } from "src/app/services/reserved-passenger.service";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import { UserService } from "src/app/services/user.service";
import { TranslateService } from "src/app/translate/translate.service";
import { JwtService } from "src/app/utils/jwt/jwt.service";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.css"]
})
export class PaymentComponent implements OnInit, OnDestroy {

  public reservedTrip: ReservedTrip;
  public loaded = false;
  private destroy$ = new Subject();
  public sending: boolean = false;
  public modified: boolean = false;

  private page = 1;
  private totalItems = null;
  public showList: boolean = false;
  private searchText = '';
  public form: FormGroup;
  public formPay: FormGroup;

  private totalPassenger: Array<ReservedPassenger> = [];
  public passengers: Array<ReservedPassenger> = [];
  public stages: Array<ReservedStage> = [];

  private selections: any = {};
  public balance = 0;
  public errorMessage = null;

  public bankTransferReference = '[BookingREF]';
  public bankTransferLastName = 'Last Name';
  public clicked = false;

  constructor(
    private reservedTripService: ReservedTripService,
    private reservedPassengersService: ReservedPassengerService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private jwtService: JwtService
  ) { }


  ngOnInit(): void {

    this.jwtService.getCurrentUserSubject().subscribe(
      user => {
        if (user && user.name) {
          this.bankTransferLastName = user.name
        }
      }
    );

    this.form = this.formBuilder.group({
      search: ['']
    });

    this.formPay = this.formBuilder.group({
      balance: [0, [Validators.required, Validators.min(0.01)]],
      // auth1: [false, [Validators.required, Validators.requiredTrue]],
      auth2: [false, [Validators.required, Validators.requiredTrue]],
      subscribe: [false],
    });

    //done

    this.totalPassenger = [];
    this.reservedTripService.getLastItem().pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        if (res) {
          this.reservedTrip = res;

          this.bankTransferReference = res.reference;
          const date = new Date();
          date.setDate(date.getDate() - 1);

          //if (this.reservedTrip.dueDate > date) {
          this.reservedPassengersService.getList(
            this.reservedTrip,
            { page: 1, group: "payment" }
          ).subscribe(
            res => {
              this.totalPassenger = res.items;
              this.totalItems = res.totalItems;
              this.showList = true;
              this.loaded = true;


              this.route.queryParams.pipe(takeUntil(this.destroy$)).subscribe(
                queryParams => {
                  if (queryParams['done']) {
                    if (queryParams['done'] != '1' && queryParams['payment_made_id']) {
                      this.reservedTripService.getOrderError(this.reservedTrip.id, queryParams['payment_made_id']).subscribe(
                        error => {
                          if(error!='NONE'){
                            this.errorMessage = error;
                          }
                        }
                      )
                    } else {

                    }
                  }
                }
              );

              this.search();
            }
          );
          //}
        }
      }
    );

    this.form.controls['search'].valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(
      res => {
        this.searchText = res.toLowerCase();
        this.search()
      }
    );


  }

  search() {
    this.passengers = [];

    if (this.searchText && this.searchText != "") {
      const words = this.searchText.split(" ");

      for (let w of words) {
        for (let p of this.totalPassenger) {
          if (p.fullName().toLowerCase().indexOf(w) > -1) {
            this.passengers.push(p);
          }
        }
      }

    } else {
      this.passengers = this.totalPassenger;
    }

  }

  select(passenger: PassengerBalanceSelection) {
    if (passenger.depositSelected) {
      this.selections[passenger.passenger.id] = passenger.depositValue;
    } else if (passenger.balanceSelected) {
      this.selections[passenger.passenger.id] = passenger.passenger.balance;
    } else if (passenger.otherSelected) {
      this.selections[passenger.passenger.id] = passenger.amount;
    } else {
      this.selections[passenger.passenger.id] = 0;
    }

    this.balance = 0;
    for (let prop in this.selections) {
      this.balance += this.selections[prop];
    }

    this.formPay.controls.balance.setValue(this.balance);
  }

  pay() {
    this.clicked = true;

    if (!this.sending && !this.formPay.invalid) {
      this.sending = true;

      const data = {
        amount_selected: [],
        current_language: this.translateService.getLocale()
      }

      let totalAmount = 0;
      for (let prop in this.selections) {
        data.amount_selected.push(
          {
            idreserved_trip_passenger: prop,
            amount: this.selections[prop]
          }
        )
        totalAmount += this.selections[prop];
      }

      if (totalAmount > 0) {
        this.reservedTripService.createOrder(this.reservedTrip.id, data).subscribe(
          res => {
            if (res['url']) {
              window.location.href = res['url'];
            }
            this.sending = false;
          },
          err => {
            this.sending = false;
          }
        )
      }

    } else if (this.formPay.invalid) {
      if (this.formPay.controls.auth2.invalid) {

      } else if (this.formPay.controls.balance.invalid) {

      }
    }

  }

  closeError() {
    this.errorMessage = null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
