import { ViewportScroller } from "@angular/common";
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ReservedDay } from "src/app/models/reserved-day.model";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { RouteChunk } from "src/app/models/route-chunk.model";
import { ReservedTripService } from "src/app/services/reserved-trip.service";


@Component({
  selector: "app-reserved-day-full",
  templateUrl: "./reserved-day-full.component.html",
  styleUrls: ["./reserved-day-full.component.css"]
})
export class ReservedDayFullComponent implements AfterViewInit {

  @ViewChild('svgContainer')
  public svgContainer: ElementRef;

  @ViewChild('svgTitles')
  public svgTitles: ElementRef;

  private _afterView = false;

  public _day: ReservedDay;

  public km: number;

  @Input()
  public set day(day: ReservedDay) {
    this._day = day;
    if (this._afterView) {
      this.routeChunk = null;
      this.svgContainer.nativeElement.src = '';
      this.loadDay();
    }
  }

  @Input()
  public timingStages: Array<ReservedStage> = [];
  @Input()
  public accommodationStages: Array<ReservedStage> = [];
  @Input()
  public bikeStages: Array<ReservedStage> = [];
  @Input()
  public otherStages: Array<ReservedStage> = [];
  @Input()
  public guideStages: Array<ReservedStage> = [];
  @Input()
  public activityStages: Array<ReservedStage> = [];

  @Input()
  public reservedTrip: ReservedTrip;

  @Input()
  public nextDayIndex: number = null;

  @Input()
  public previousDayIndex: number = null;

  public routeChunk: RouteChunk = null;


  constructor(
    private scroll: ViewportScroller, 
    private reservedTripService: ReservedTripService
  ) { 
  }
  
  ngAfterViewInit(): void {
    this._afterView = true;

    this.loadDay();
  }



  loadDay() {

    if (!this._day.sameCity() && this._day.to) {
      this.reservedTripService.getElevationSvg(this.reservedTrip.id, this._day.index).subscribe(
        res => {
          this.svgContainer.nativeElement.src = "data:image/svg+xml;base64," + window.btoa(res);
          /*  this.svgContainer.nativeElement.style.marginTop = "5rem";
            this.svgTitles.nativeElement.style.marginTop="-30rem";
            this.svgTitles.nativeElement.style.marginBottom="30rem";*/
        }
      );
      this.reservedTripService.getRouteData(this.reservedTrip.id, this._day.index).subscribe(
        res => {
          if (res.totalItems > 0) {
            this.routeChunk = res.items[0];
        //    this._day.betweenAccommodationCitiesKm = this.routeChunk.distance;
          }
        }
      );
    }


  }



  scrollTop() {

    const top = document.getElementById('offsetTop');
    if (top) {
      this.scroll.scrollToPosition([0, top.offsetTop]);
    } else {
      this.scroll.scrollToPosition([0, 0]);
    }
  }


}
