import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of, throwError } from 'rxjs';
import { catchError, first, map, mergeAll, switchMap, tap } from 'rxjs/operators';
import { JwtNavigationService } from './jwt-navigation.service';
import { JwtService } from './jwt.service';
import { M } from 'angular-mydatepicker';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {

    //tengo que subscribirme a la respuesta

    constructor(
        private navigationService: JwtNavigationService,
        private jwtService: JwtService,
        private http: HttpClient
    ) {
    }


    isLoginRequest(request: HttpRequest<any>) {
        return request.url == this.navigationService.getRefresTokenUrl() ||
            request.url == this.navigationService.getLoginUrl() ||
            request.url == this.navigationService.getTokenLoginUrl();
    }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
    
        const token = localStorage.getItem('jwtToken');// this.authenticationService.getToken();

        if (
            this.isLoginRequest(request)
        ) {
            return next.handle(request).pipe(
                catchError(
                    x => this.handleAuthError(request, x, next)
                )
            );
        }
        else if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });

            return next.handle(request).pipe(
                catchError( err=>  of(err) ),
                map(
                    (res:HttpEvent<any>) => {
                    
                        if (res instanceof HttpErrorResponse) {
                            
                            if (res.status === 401) {
                                const refreshToken = localStorage.getItem('refreshToken');
                                if (refreshToken) { 
                                    return this.jwtService.refreshCurrentToken().pipe(
                                        switchMap(() => {
                            
                                            const newToken = localStorage.getItem('jwtToken');
                                            if (newToken) {
                                                request = request.clone({
                                                    setHeaders: {
                                                        Authorization: `Bearer ${newToken}`
                                                    }
                                                });
                                                return next.handle(request);
                                            }
                                            return of(res);
                                        })
                                    );
                                }
                            }
                        }
                        return of(res);
                    }
                ),
                switchMap(
                    x => x
                )
            );

        }

        return next.handle(request);
    }


    private handleAuthError(request: HttpRequest<any>, err: HttpErrorResponse, next: HttpHandler): Observable<HttpEvent<any>> {
        if (err.status === 401 || err.status == 403) {
            this.jwtService.logout();
        }
        return throwError(err);
    }

}