import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { Subject } from "rxjs";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
})
export class RegisterComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();

  public form: FormGroup;
  public sending = false;
  public modified = false;
  public created = false;
  public errorKeyword = null;

  public dateOfBirthOptions: IAngularMyDpOptions;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService
  ) {
    const dt = new Date();
    this.dateOfBirthOptions = {
      dateRange: false,
      dateFormat: 'dd/mm/yyyy',
      disableSince: {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate()
      }
    };
  }

  get c() {
    return this.form.controls
  }

  ngOnInit(): void {
    const birthDate: IMyDateModel = { isRange: false, singleDate: { jsDate: new Date() }, dateRange: null };

    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      gender: [''],
      firstName: ['', [Validators.required, Validators.maxLength(45)]],
      lastName: ['', [Validators.required, Validators.maxLength(45)]],
      //birthDate: ['', Validators.required],
      phoneArea: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.maxLength(45)]],
      country: ['', Validators.required]
    });

    this.form.valueChanges.subscribe(
      res => {
        //this.modified = true;
      }
    )
  }



  submit() {
    this.modified = true;
    
    if (!this.form.invalid && !this.sending) {
      this.errorKeyword = null;
      this.sending = true;
      //const dt = this.c.birthDate.value.singleDate.jsDate;
      //const birthDate = new Date(dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset()));


      this.userService.createUser(
        this.c.email.value,
        this.c.gender.value,
        this.c.firstName.value,
        this.c.lastName.value,
        //birthDate,
        this.c.phoneArea.value.number,
        this.c.phoneNumber.value,
        this.c.country.value.name
      ).subscribe(
        res => {

          if(res['status']=='OK'){
            this.created = true;
          } else if(res['status']=='ERROR'){
            this.errorKeyword = 'Email already has account';
          }
          
          this.sending = false;
        },
        err => {
          this.errorKeyword = 'Something goes wrong, please try again later';
          this.sending = false;
        }
      )
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
