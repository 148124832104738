import { Component, Input, OnInit } from "@angular/core";
import { ReservedDay } from "src/app/models/reserved-day.model";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";

@Component({
  selector: "app-reserved-guide-card",
  templateUrl: "./reserved-guide-card.component.html",
})
export class ReservedGuideCardComponent implements OnInit {
  @Input()
  public day: ReservedDay;
  
  @Input()
  public stage: ReservedStage;

  @Input()
  public reservedTrip: ReservedTrip;

  @Input()
  public left = true;
  
  constructor() {}

  ngOnInit(): void {}
}