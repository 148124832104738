import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { IAngularMyDpOptions, IMyDateModel } from "angular-mydatepicker";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserPassenger } from "src/app/models/user-passenger.model";
import { ClickService } from "src/app/services/click.service";
import { UserService } from "src/app/services/user.service";
import { JwtService } from "src/app/utils/jwt/jwt.service";
import { User } from "src/app/utils/jwt/user.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit {
  private destroy$ = new Subject();

  public dateOfBirthOptions: IAngularMyDpOptions;
  public showUpload: boolean = false;
  public showSendConfirmation = false;
  public user: UserPassenger;
  public loggedUser: User;
  public firstLetter: string;
  public urlApi: string;
  public form: FormGroup;
  public formPassword: FormGroup;
  public updateMode: boolean = false;
  public modified: boolean = false;
  public sending: boolean = false;
  public ts: number;
  public showDeleteConfirmDialog = false;
  public showPasswordRequest = false;
  public errorKeyword: string = null;

  constructor(
    private userService: UserService,
    private toastService: ToastrService,
    private jwtService: JwtService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.urlApi = environment.urlApi
    const dt = new Date();
    this.dateOfBirthOptions = {
      dateRange: false,
      dateFormat: 'dd/mm/yyyy',
      disableSince: {
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
        day: dt.getDate()
      }
      // other options...
    };

    this.ts = (new Date()).getTime();

  }


  ngOnInit(): void {
    const birthDate: IMyDateModel = { isRange: false, singleDate: { jsDate: new Date() }, dateRange: null };

    this.form = this.formBuilder.group({
      gender: [''],
      firstName: ['', [Validators.required, Validators.maxLength(45)]],
      lastName: ['', [Validators.required, Validators.maxLength(45)]],
      birthDate: [birthDate, Validators.required],
      phoneArea: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.maxLength(45)]],
      postalCode: ['', Validators.required],
      city: ['', [Validators.required, Validators.maxLength(45)]],
      street: ['', [Validators.required, Validators.maxLength(45)]],
      country: ['', Validators.required],
    });

    this.formPassword = this.formBuilder.group(
      {
        password: ['']
      }
    )

    this.jwtService.getCurrentUserSubject().subscribe(
      res => {
        this.loggedUser = res;
      }
    );
    this.userService.getUserProfile().subscribe(
      res => {
        this.user = res;
        this.firstLetter = this.user.firstName.substring(0, 1);
        this.updateControls();
      }
    )

    this.form.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(
      res => {
        this.modified = true;
      }
    );



  }

  get c() {
    return this.form.controls;
  }



  resetPassword() {
    this.userService.sendRecoverMail("").subscribe(
      res => {
        this.showSendConfirmation = true;
      }
    )
  }

  public onFileChange(event) {

    var reader = new FileReader();

    reader.onload = (e: any) => {

      //https://filesignatures.net/index.php
      const pdfFile = [0x25, 0x50, 0x44, 0x46];

      const arrayBuffer = e.target.result as ArrayBuffer;

      if (arrayBuffer && arrayBuffer.byteLength >= 3) {
        const slice = arrayBuffer.slice(0, 4);
        const view = new Uint8Array(slice);

        //if (pdfFile[0] === view[0] && pdfFile[1] === view[1] && pdfFile[2] === view[2] && pdfFile[3] === view[3]) {

        let form = new FormData();
        //event.target.files[0]
        form.append('file', event.target.files[0]);
        form.append('fileName', event.target.files[0].name);

        this.userService.sendProfileImage(form).subscribe( //.pipe(takeUntil(this.ngUnsubscribe))
          res => {
            event.target.value = '';;
            this.user.profileImageId = res['id'];
            this.loggedUser.profileImageId = res['id'];

            this.ts = (new Date()).getTime();

            this.toastService.success('Success', undefined, {
              closeButton: true,
              positionClass: 'toast-bottom-right'
            });
          },
          err => {
            event.target.value = '';
            this.toastService.error('Error', undefined, {
              closeButton: true,
              positionClass: 'toast-bottom-right'
            });
          }
        );
        /*
                } else {
        
                  event.target.value = '';
                  this.toastService.error('Los ficheros deben ser PDFs', undefined, {
                    closeButton: true,
                    positionClass: 'toast-bottom-right'
                  });
                }*/

      }

    };

    if (event.target.files[0].size <= 3 * 1014 * 1024) {
      reader.readAsArrayBuffer(event.target.files[0]);
    } else {
      event.target.value = '';
      this.toastService.error('El tamaño no puede ser superior a 3MB', undefined, {
        closeButton: true,
        positionClass: 'toast-bottom-right'
      });
    }
  }


  updateControls() {


    this.c.firstName.setValue(
      this.user.firstName,
      { emitEvent: false }
    );
    this.c.lastName.setValue(
      this.user.name,
      { emitEvent: false }
    );

    let model: IMyDateModel;


    if (this.user.birthDate) {
      model = { isRange: false, singleDate: { jsDate: this.user.birthDate }, dateRange: null };
    } else {
      model = { isRange: false, singleDate: { jsDate: new Date() }, dateRange: null };
    }
    this.c.birthDate.setValue(
      model,
      { emitEvent: false }
    );

    this.c.street.setValue(
      this.user.street,
      { emitEvent: false }
    );
    this.c.country.setValue(
      this.user.country,
      { emitEvent: false }
    );

    this.c.gender.setValue(
      this.user.clientData.gender,
      { emitEvent: false }
    );

    this.c.postalCode.setValue(
      this.user.clientData.postalCode,
      { emitEvent: false }
    );
    this.c.city.setValue(
      this.user.clientData.town,
      { emitEvent: false }
    );

    this.c.phoneArea.setValue(
      this.user.clientData.phone.countryCode,
      { emitEvent: false }
    );
    this.c.phoneNumber.setValue(
      this.user.clientData.phone.number,
      { emitEvent: false }
    );

  }


  save() {
    if (
      !this.sending && !this.form.invalid && this.form.dirty
    ) {
      this.updateModel();
    }
  }


  updateModel() {

    this.sending = true;
    this.user.firstName = this.c.firstName.value;
    this.user.name = this.c.lastName.value;

    const dt = this.c.birthDate.value.singleDate.jsDate;
    this.user.birthDate = new Date(dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset()));
    this.user.clientData.gender = this.c.gender.value;
    this.user.clientData.phone.countryCode = this.c.phoneArea.value;
    this.user.clientData.phone.number = this.c.phoneNumber.value;
    this.user.clientData.phoneNumber = '+' + this.c.phoneArea.value.number + this.c.phoneNumber.value;
    this.user.clientData.postalCode = this.c.postalCode.value;
    this.user.clientData.town = this.c.city.value;
    this.user.street = this.c.street.value;
    this.user.country = this.c.country.value.name;

    this.userService.updateProfile(this.user).subscribe(
      res => {

        this.user = res;
        this.updateControls();
        this.sending = false;
        this.modified = false;
        this.updateMode = false
      }
    );

  }

  deleteUser(event) {
    event.preventDefault();

    this.errorKeyword = null;

    this.userService.deleteProfile(
      this.user.username,
      this.formPassword.controls.password.value
    ).subscribe(
      (res) => {

        if (!res || res['status'] != 'OK') {
          this.errorKeyword = 'Wrong password';
        }
        else {
          this.jwtService.removeCredentials();
          this.router.navigate(['/auth/deleted']);
        }
      },
      err => {
        this.errorKeyword = 'Something goes wrong, please try again later';
        //this.errorKeyword = 'clientarea.setpasswd.error';
      }
    )
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


}
