import { Component, OnInit } from "@angular/core";
import { JwtService } from "src/app/utils/jwt/jwt.service";

@Component({
  selector: "app-landing-navbar",
  templateUrl: "./landing-navbar.component.html",
})
export class LandingNavbarComponent implements OnInit {
  navbarOpen = false;

  constructor( private jwtService: JwtService) {}

  ngOnInit(): void {}

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }

  logout() {
    this.jwtService.logout();
  }
}
