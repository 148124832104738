import { PhoneNumber } from "./phone-number.model";

export class ClientData{
    public userId: number;
    public gender:string;
    public town:string;
    public postalCode:string;
    public phone: PhoneNumber;
    public phoneNumber:string;
    public mobilePhone: PhoneNumber;
}