// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //urlApi: 'http://localhost',
  //urlApi: 'https://ftc-api.nedaware.eu',
  urlApi: 'https://api.followthecamino.com',
  apiKeyOWM: 'd8651f75186d8cd4dea1b60a06922775',
  mapPath: '/maps/',
  //mapPath: '/goToExternalMap/',
  lightVersion: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
