import { animate, style, transition, trigger, useAnimation } from "@angular/animations";
import { Component, Input } from "@angular/core";
import { fadeIn, fadeOut } from "./carousel.animations";

@Component({
    selector: "carrousel",
    templateUrl: "./carrousel.component.html",
    styleUrls: ["./carrousel.component.css"],
    animations: [
        trigger("carouselAnimation", [
            transition("void => *", [useAnimation(fadeIn, { params: { time: '1300ms' } })]),
            /*          transition("* => void", [useAnimation(fadeOut, {params: { time: '500ms' }} )]),*/
        ])
    ]
})
export class CarrouselComponent {
    public _slides = [];
    
    @Input()
    public set slides(ss) {
        this._slides = ss;

        if (this.currentSlide >= this._slides.length) {
            this.currentSlide = this._slides.length - 1;
        }
    }

    public currentSlide = 0;

    constructor() { }

    onPreviousClick() {
        const previous = this.currentSlide - 1;
        this.currentSlide = previous < 0 ? this._slides.length - 1 : previous;
        console.log("previous clicked, new current slide is: ", this.currentSlide);
    }

    onNextClick() {
        const next = this.currentSlide + 1;
        this.currentSlide = next === this._slides.length ? 0 : next;
        console.log("next clicked, new current slide is: ", this.currentSlide);
    }
}