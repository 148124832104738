import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Allergy } from "src/app/models/allergy.model";
import { Diet } from "src/app/models/diet.model";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { AllergyService } from "src/app/services/allergy.service";
import { DietService } from "src/app/services/diet.service";
import { ReservedPassengerService } from "src/app/services/reserved-passenger.service";
import { ReservedTripService } from "src/app/services/reserved-trip.service";

@Component({
  selector: "app-passengers",
  templateUrl: "./passengers.component.html",
})
export class PassengersComponent implements OnInit, OnDestroy {

  public reservedTrip: ReservedTrip;
  public reservedTripId: string;
  public passengers: Array<ReservedPassenger> = [];
  public loaded = false;
  private destroy$ = new Subject();

  public diets: Array<Diet> = [];
  public allergies: Array<Allergy> = [];
  private page = 1;
  private totalItems = null;
  private searchText = '';
  public form: FormGroup;
  public showList = false;

  constructor(
    private reservedTripService: ReservedTripService,
    private reservedPassengersService: ReservedPassengerService,
    private allergyService: AllergyService,
    private dietService: DietService,
    private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      search: ['']
    });

    this.passengers = [];
    this.reservedTripService.getLastItem().pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        if (res) {

          if (
            !this.reservedTrip ||
            res.id !== this.reservedTrip.id ||
            this.reservedTrip.passengers.length != res.passengers.length
          ) {
            this.reservedTrip = res;
            this.firstPage();
          } else {
            this.reservedTrip = res;
          }
        }
      }
    )


    this.allergyService.getList().pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        if (res) {
          this.allergies = res.items;
          this.showList = this.allergies.length > 0 && this.diets.length > 0;
        }
      }
    );
    this.dietService.getList().pipe(takeUntil(this.destroy$)).subscribe(
      res => {
        if (res) {
          this.diets = res.items
          this.showList = this.allergies.length > 0 && this.diets.length > 0;
        }
      }
    );


    this.form.controls['search'].valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(
      res => {
        if (this.reservedTrip) {
          this.page = 1;
          this.searchText = res;
          this.passengers = [];
          this.search()
        }
      }
    );
  }

  onScroll() {
    if (
      this.reservedTrip &&
      (
        this.totalItems == null ||
        this.passengers.length < this.totalItems
      )
    ) {
      this.page++;
      this.search();
    }
  }

  search() {
    this.reservedPassengersService.getList(
      this.reservedTrip,
      { page: this.page, itemsPerPage: 10, search: this.searchText }
    ).subscribe(
      res => {
        this.passengers = this.passengers.concat(res.items);
        this.totalItems = res.totalItems;
      }
    );
  }

  firstPage() {
    this.page = 1;
    this.passengers = [];
    this.search();
  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
