import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { PhoneArea } from "src/app/models/phone-area.model";
import { PhoneAreaService } from "src/app/services/phone-area.service";
import { TranslateService } from "src/app/translate/translate.service";

@Component({
    selector: "app-country-input",
    templateUrl: "./country-input.component.html",
    styleUrls: ["./country-input.component.css"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CountryInputComponent
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: CountryInputComponent
        }
    ]
})
export class CountryInputComponent implements OnInit, ControlValueAccessor, Validator {
    onChange = (quantity) => { };
    onTouched = () => { };
    touched = false;
    disabled = false;
    public form: FormGroup;

    @Input()
    public placeholder: string;

    @Input()
    public historyHeading: string = 'Recently selected';


    public value: PhoneArea;
    public data: Array<PhoneArea> = [];

    public searchKeyword = 'name';

    constructor(
        private countryService: PhoneAreaService,
        private translateService: TranslateService,
        private formBuilder: FormBuilder
    ) {
    }


    ngOnInit(): void {


        this.form = this.formBuilder.group({
            code: [this.value]
        });

        this.form.controls.code.valueChanges.subscribe(
            res => {
                this.value = res;
                this.onChange(res);
            }
        );

        this.countryService.getList().subscribe(
            res => {
                if (res) {
                    this.data = res.items;
                    for (let i = 0; i < this.data.length; i++) {
                        this.data[i].setFlag();

                        if (!this.data[i].name || this.data[i].name == "") {
                            this.data[i].name = "";

                            this.translateService.getItem(this.data[i].countryNameKeyword).subscribe(
                                res => {
                                    this.data[i].name = res;
                                }
                            )
                        }
                    }
                }
            }
        );
    }

    writeValue(value: PhoneArea) {
        this.value = value;
        if (this.form && this.form.controls) {
            this.form.controls.code.setValue(value, { emitEvent: false });
        }
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const lcation = control.value;
        if (location == null) {
            return {
                emptyValue: true
            };
        }
    }
}
