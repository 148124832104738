import { Component, Input, OnInit } from "@angular/core";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedStageType } from "src/app/models/reserved-stage.model";
import isWebview from "is-ua-webview";
import { environment } from "src/environments/environment";

declare var WidgetHistoricalClass;
declare var WidgetForecastClass;
declare var WidgetMapClass;

@Component({
  selector: "app-weather-widget",
  templateUrl: "./weather-widget.component.html",
  styleUrls: ["./weather-widget.component.css"],
})
export class WeatherWidgetComponent implements OnInit {
  @Input()
  public reservedTrip: ReservedTrip;

  public widgetId = "widgetFtcHistoricalWeatherMap";
  public type = null; // 'map'. 'historical', 'forecast'
  public widget: any;
  public errors: Array<string> = [];
  public showErrorDialog: boolean = false;
  public newQuote = false;
  private isWebView: boolean = false;
  public showMap: boolean = false;
  public externalMapUrl: string = "";

  constructor(private reservedTripService: ReservedTripService) {}

  ngOnInit(): void {
    this.isWebView = isWebview(window.navigator.userAgent);
    if (
      typeof window["webkit"] !== "undefined" &&
      window["webkit"] !== null &&
      typeof window["webkit"].messageHandlers !== "undefined" &&
      window["webkit"].messageHandlers !== null &&
      typeof window["webkit"].messageHandlers.sendBase64 !== "undefined" &&
      typeof window["webkit"].messageHandlers.sendBase64 !== null
    ) {
      this.isWebView = true;
    }

    this.reservedTripService.getLastItem().subscribe((res) => {
      if (res) {
        this.reservedTrip = res;

        this.errors = [];
        /*
                    for (let stage of this.reservedTrip.stages) {
                      if (stage.errors && stage.errors.length > 0) {
                        for (let e of stage.errors) {
                          this.errors.push(e);
                        }
                        break;
                      }
                    }
          */
        if (this.errors.length > 0) {
          this.showErrorDialog = true;
        }

        this.initReservedTrip();
      }
    });
  }

  initReservedTrip() {
    this.newQuote = this.reservedTrip.bookingStatus == "NewQuote";

    this.newQuote = false;

    this.showMap = this.reservedTrip.getShowMap() && this.isWebView;

    this.externalMapUrl = environment.mapPath + this.reservedTrip.id;

    this.setFtcWeatherType("map");
  }

  setFtcWeatherType(type: string) {
    if (this.type != type) {
      this.type = type;
      this.setFtcWeather();
    }
  }

  setFtcWeather() {
    if (this.type == "map" || this.type == "historical") {
      this.reservedTripService
        .getWeatherData(this.reservedTrip.id)
        .subscribe((res) => {
          if (this.type == "historical") {
            //
            this.widget = new WidgetHistoricalClass(this.widgetId, 0);
          } else {
            this.widget = new WidgetMapClass(this.widgetId);
          }

          Object.keys(this.widget).map((key, index) => {
            if (res[key]) {
              this.widget[key] = res[key];
            }
          });

          this.widget.init();

          setTimeout(() => {
            if (this.widget.weatherMap) {
              this.widget.weatherMap.updateSize();
            }
          }, 3000);
        });
    } else {
      this.reservedTripService
        .getForecastData(this.reservedTrip.id)
        .subscribe((res) => {
          this.widget = new WidgetForecastClass(this.widgetId);

          Object.keys(this.widget).map((key, index) => {
            if (res[key]) {
              this.widget[key] = res[key];
            }
          });

          this.widget.init();
        });
    }
    console.log(this.widgetId);
  }
}
