import { Component, Input, OnInit } from "@angular/core";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reserved-trip-table",
  templateUrl: "./reserved-trip-table.component.html",
})
export class ReservedTripTableComponent implements OnInit {
  @Input()
  public trips: Array<ReservedTrip>=[];
  public host;

  @Input()
  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color !== "light" && color !== "dark" ? "light" : color;
  }
  private _color = "light";

  constructor() {
    this.host = environment.urlApi;
  }

  ngOnInit() {

  }

}
