import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Allergy } from "../models/allergy.model";
import { ListResult } from "../models/list-result.model";

@Injectable({ providedIn: 'root' })
export class AllergyService {
    private subjectList: BehaviorSubject<ListResult<Allergy>> = new BehaviorSubject(null);
    //private list: ListResult<Allergy> = null;
    private fectched = false;

    constructor(private http: HttpClient) {
    }

    getList(): Observable<ListResult<Allergy>> {
        //const
        if (!this.fectched) {
            this.fectched = true;

            const params = {};// itemsPerPage: "230", page: "1" };

            this.http.get(environment.urlApi + '/public/allergies/', { params: params }).pipe(map(
                res => {
                    const result = new ListResult<Allergy>();

                    for (let i = 0; i < res['items'].length; i++) {
                        result.items.push(Object.assign(new Allergy(), res['items'][i]));
                    }
                    result.totalItems = res['totalItems'];
                    return result;
                },
                err => {
                    this.fectched = false;
                }
            )).subscribe(
                res=> this.subjectList.next(res)
            );
        }


        return this.subjectList;
    }
}