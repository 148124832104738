import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ListResult } from "../models/list-result.model";
import { PhoneArea } from "../models/phone-area.model";

@Injectable({ providedIn: 'root' })
export class PhoneAreaService {
    private subjectList: BehaviorSubject<ListResult<PhoneArea>> = new BehaviorSubject(null);
    private fetched = false;
    
    constructor(private http: HttpClient) {
    }

    getList(): Observable<ListResult<PhoneArea>> {
        //const
        if(!this.fetched){
            
            this.fetched = true;        
            const params = {};// itemsPerPage: "230", page: "1" };
            
            this.http.get(environment.urlApi + '/public/phone_areas/', { params: params }).pipe(map(
                res => {
                    const result = new ListResult<PhoneArea>();

                    for (let i = 0; i < res['items'].length; i++) {
                        result.items.push(Object.assign(new PhoneArea(),res['items'][i]));
                    }
                    result.totalItems = res['totalItems'];
                    return result;
                }
            )).subscribe(
                res=> this.subjectList.next(res)
            );
        }
        return this.subjectList;
    }
}