import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";

@Component({
    selector: "app-room-allocation-passenger-card",
    templateUrl: "./room-allocation-passenger-card.component.html",
    styleUrls: ["./room-allocation-passenger-card.component.css"],
})
export class RoomAllocationPassengerCardComponent implements OnInit {
    @Input()
    public passenger: ReservedPassenger;

    @Input()
    get color(): string {
        return this._color;
    }
    set color(color: string) {
        this._color = color !== "light" && color !== "dark" ? "light" : color;
    }
    private _color = "light";

    //stages same day
    ngOnInit() {

    }

}
