import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ListResult } from '../models/list-result.model';
import { PaymentStatus } from '../models/payment-status.model';
import { Pricing } from '../models/pricing.model';
import { ReservedStage } from '../models/reserved-stage.model';
import { ReservedTrip } from '../models/reserved-trip.model';
import { Supplier } from '../models/supplier.model';
import { Trip } from '../models/trip.model';
import { ReservedDay } from '../models/reserved-day.model';
import { SectionPicture } from '../models/section-picture.model';
import { Section } from '../models/section.model';
import { SupplierAccommodation } from '../models/supplier-accommodation.model';
import { SupplierRating } from '../models/supplier-rating.model';
import { SupplierRestaurant } from '../models/supplier-restaurant.model';
import { ReservedStageMeal } from '../models/reserved-stage-meal.model';
import { Menu } from '../models/menu.model';
import { LocationPoint } from '../models/location-point.model';
import { ReservedStageGuide } from '../models/reserved-stage-guide.model';
import { ReservedStageFlight } from '../models/reserved-stage-flight.model';
import { ReservedStageActivity } from '../models/reserved-stage-activity.model';
import { Activity } from '../models/activity.model';
import { ActivityOption } from '../models/activity-option.model';
import { Discount } from '../models/discount.model';
import { ReservedStepModificationExtra } from '../models/reserved-step-modification-extra.model';
import { BikeOption } from '../models/bike-option.model';
import { BikeOptionalItem } from '../models/bike-optional-item.model';
import { ReservedStageBike } from '../models/reserved-stage-bike.model';
import { ReservedStagePeopleTransfer } from '../models/reserved-stage-people-transfer.model';
import { HolidayPack } from '../models/holiday-pack.model';
import { HolidayPackItem } from '../models/holiday-pack-item.model';
import { ReservedTripHolidayPackItemActivity } from '../models/reserved-trip-holiday-pack-item-activity.model';
import { HolidayPackItemType } from '../models/holiday-pack-item-type.model';
import { HolidayPackItemLink } from '../models/holiday-pack-item-link.model';
import { HolidayPackItemFileName } from '../models/holiday-pack-item-file-name.model';
import { ReservedPassengerService } from './reserved-passenger.service';
import { ReservedExtra } from '../models/reserved-extra.model';
import { ReservedStep } from '../models/reserved-step.model';
import { DBFile } from '../models/dbfile.model';
import { TrackFile } from '../models/track-file.model';
import { ReservedStageAccommodation } from '../models/reserved-stage-accommodation.model';
import { TransportType } from '../models/transport-type.model';
import { RouteChunk } from '../models/route-chunk.model';
import { CityService } from './city.service';

@Injectable({ providedIn: 'root' })
export class ReservedTripService {
  private lastItem: BehaviorSubject<ReservedTrip> =
    new BehaviorSubject<ReservedTrip>(null);

  constructor(
    private http: HttpClient,
    private reservedPassengerService: ReservedPassengerService,
    private cityService: CityService
  ) { }

  tzString(dateString: string) {
    dateString = dateString.substring(0, 10);
    const date = new Date(dateString);

    const offsetMinutes = date.getTimezoneOffset(); // Get the timezone offset in minutes

    // Calculate the offset in hours and minutes
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
    const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;

    // Determine the sign of the offset
    const offsetSign = offsetMinutes < 0 ? '+' : '-';

    return (
      dateString +
      'T00:00:00' +
      offsetSign +
      this.padNumber(offsetHours, 2) +
      ':' +
      this.padNumber(offsetMinutesRemainder, 2)
    );
  }

  getList(params?): Observable<ListResult<ReservedTrip>> {
    //const
    if (params == null) {
      params = { itemsPerPage: '10', page: '1' };
    }

    return this.http
      .get(environment.urlApi + '/api/reserved_trips/', { params: params })
      .pipe(
        map((res) => {
          const result = new ListResult<ReservedTrip>();

          for (let i = 0; i < res['items'].length; i++) {
            result.items.push(this.unserializeItem(res['items'][i]));
          }
          result.totalItems = res['totalItems'];
          return result;
        })
      );
  }

  getServicesStages(id, params?): Observable<ListResult<ReservedStage>> {
    //const
    if (params == null) {
      params = { itemsPerPage: '10', page: '1' };
    }

    return this.http
      .get(
        environment.urlApi + '/api/reserved_trips/' + id + '/services_stages',
        { params: params }
      )
      .pipe(
        map((res) => {
          const result = new ListResult<ReservedStage>();

          for (let i = 0; i < res['items'].length; i++) {
            result.items.push(this.unserializeStage(res['items'][i]));
          }
          result.totalItems = res['totalItems'];
          return result;
        })
      );
  }

  padNumber(num, size) {
    num = num.toString();
    while (num.length < size) num = '0' + num;
    return num;
  }

  unserializeItem(item): ReservedTrip {
    const codes = [];
    if (item.trips) {
      for (let j = 0; j < item.trips.length; j++) {
        if (item.trips[j].sections) {
          for (let k = 0; k < item.trips[j].sections.length; k++) {
            if (item.trips[j].sections[k].pictures) {
              for (
                let l = 0;
                l < item.trips[j].sections[k].pictures.length;
                l++
              ) {
                item.trips[j].sections[k].pictures[l] = Object.assign(
                  new SectionPicture(),
                  item.trips[j].sections[k].pictures[l]
                );
              }
            }
            item.trips[j].sections[k] = Object.assign(
              new Section(),
              item.trips[j].sections[k]
            );
          }
        }

        item.trips[j] = Object.assign(new Trip(), item.trips[j]);
        codes.push(item.trips[j].code);
      }
    }

    if (item.stages) {
      for (let j = 0; j < item.stages.length; j++) {
        item.stages[j] = this.unserializeStage(item.stages[j]);
        if (item.lastDate == null || item.lastDate < item.stages[j].dateOut) {
          item.lastDate = item.stages[j].dateOut;
        }
      }
    }

    if (item.days) {
      for (let j = 0; j < item.days.length; j++) {
        if (item.days[j].date) {
          item.days[j].date = new Date(this.tzString(item.days[j].date));
        }
        if (item.days[j].accommodationStages) {
          for (let k = 0; k < item.days[j].accommodationStages.length; k++) {
            item.days[j].accommodationStages[k] = this.unserializeStage(
              item.days[j].accommodationStages[k]
            );
          }
        }
        if (item.days[j].peopleTransferStages) {
          for (let k = 0; k < item.days[j].peopleTransferStages.length; k++) {
            item.days[j].peopleTransferStages[k] = this.unserializeStage(
              item.days[j].peopleTransferStages[k]
            );
          }
        }
        if (item.days[j].luggageTransferStages) {
          for (let k = 0; k < item.days[j].luggageTransferStages.length; k++) {
            item.days[j].luggageTransferStages[k] = this.unserializeStage(
              item.days[j].luggageTransferStages[k]
            );
          }
        }
        if (item.days[j].transportType) {
          item.days[j].transportType = Object.assign(
            new TransportType(),
            item.days[j].transportType
          );
        }

        if (item.days[j].thisNightAccommodationCity) {
          item.days[j].thisNightAccommodationCity = this.cityService.unserialize(item.days[j].thisNightAccommodationCity);
        }

        item.days[j] = Object.assign(new ReservedDay(), item.days[j]);
      }
    }

    if (item.steps) {
      for (let j = 0; j < item.steps.length; j++) {
        if (item.steps[j].date) {
          item.steps[j].date = new Date(this.tzString(item.steps[j].date));
        }
        if (item.steps[j].transferTime) {
          item.steps[j].transferTime = new Date(item.steps[j].transferTime);
        }
        if (item.steps[j].restaurantTime) {
          item.steps[j].restaurantTime = new Date(item.steps[j].restaurantTime);
        }
        if (item.steps[j].activityTime) {
          item.steps[j].activityTime = new Date(item.steps[j].activityTime);
        }
        if (item.steps[j].guideMeetingTime) {
          item.steps[j].guideMeetingTime = new Date(
            item.steps[j].guideMeetingTime
          );
        }
        if (item.steps[j].guideFinishingTime) {
          item.steps[j].guideFinishingTime = new Date(
            item.steps[j].guideFinishingTime
          );
        }

        item.steps[j] = Object.assign(new ReservedStep(), item.steps[j]);
      }
    }

    if (item.passengers) {
      for (let j = 0; j < item.passengers.length; j++) {
        item.passengers[j] = this.reservedPassengerService.unserializeItem(
          item.passengers[j]
        );
      }
    }

    if (codes.length > 1) {
      item.code = 'combined ' + codes.join(' / ');
    } else if (codes.length > 0) {
      item.code = codes[0];
    }

    if (item.lastPricing != null) {
      if (item.lastPricing.paymentStatus != null) {
        item.lastPricing.paymentStatus.amountOwed = parseFloat(
          item.lastPricing.paymentStatus.amountOwed
        );
        item.lastPricing.paymentStatus.amountPaid = parseFloat(
          item.lastPricing.paymentStatus.amountPaid
        );
        item.lastPricing.paymentStatus = Object.assign(
          new PaymentStatus(),
          item.lastPricing.paymentStatus
        );
      }
      if (item.lastPricing.commission != null) {
        item.lastPricing.commission = Object.assign(
          new Discount(),
          item.lastPricing.commission
        );
      }
      if (item.lastPricing.discount != null) {
        item.lastPricing.discount = Object.assign(
          new Discount(),
          item.lastPricing.discount
        );
      }

      if (item.lastPricing.extras != null) {
        for (let j = 0; j < item.lastPricing.extras; j++) {
          item.lastPricing.extras[j] = Object.assign(
            new ReservedExtra(),
            item.lastPricing.extras[j]
          );
        }
      }

      item.lastPricing = Object.assign(new Pricing(), item.lastPricing);
    }

    if (item.departureDate) {
      item.departureDate = new Date(this.tzString(item.departureDate));
    }
    if (item.dueDate) {
      item.dueDate = new Date(this.tzString(item.dueDate));
    }

    if (item.voucherGeneratedDatetime) {
      item.voucherGeneratedDatetime = new Date(item.voucherGeneratedDatetime);
    }

    return Object.assign(new ReservedTrip(), item);
  }

  unserializeStage(stage): ReservedStage {
    stage.type = parseInt(stage.type, 10);

    if (stage.dateIn) {
      stage.dateIn = new Date(this.tzString(stage.dateIn));
    }
    if (stage.dateOut) {
      stage.dateOut = new Date(this.tzString(stage.dateOut));
    }

    if (stage.time) {
      stage.time = new Date(stage.time);
    }

    if (stage.city) {
      stage.city = this.cityService.unserialize(stage.city);
    }

    if (stage.supplier) {
      stage.supplier = this.unserializeSupplier(stage.supplier);
    }

    if (stage.luggageTransferSupplier) {
      stage.luggageTransferSupplier = Object.assign(
        new Supplier(),
        stage.luggageTransferSupplier
      );
    }
    if (stage.pax) {
      stage.pax = parseInt(stage.pax, 10);
    }

    if (stage.dataFlight) {
      if (stage.dataFlight.departureTime) {
        stage.dataFlight.departureTime = new Date(
          stage.dataFlight.departureTime
        );
      }
      if (stage.dataFlight.arrivalTime) {
        stage.dataFlight.arrivalTime = new Date(stage.dataFlight.arrivalTime);
      }
      stage.dataFlight = Object.assign(
        new ReservedStageFlight(),
        stage.dataFlight
      );
    }

    if (stage.dataPeopleTransfer) {
      if (stage.dataPeopleTransfer.pickupTime) {
        stage.dataPeopleTransfer.pickupTime = new BehaviorSubject<Date>(
          new Date(stage.dataPeopleTransfer.pickupTime)
        );
      } else {
        stage.dataPeopleTransfer.pickupTime = new BehaviorSubject<Date>(null);
      }

      stage.dataPeopleTransfer.flightNumber = new BehaviorSubject<string>(
        stage.dataPeopleTransfer.flightNumber
      );

      if (stage.dataPeopleTransfer.fromLocationPoint) {
        stage.dataPeopleTransfer.fromLocationPoint = Object.assign(
          new LocationPoint(),
          stage.dataPeopleTransfer.fromLocationPoint
        );
      }
      if (stage.dataPeopleTransfer.toLocationPoint) {
        stage.dataPeopleTransfer.toLocationPoint = Object.assign(
          new LocationPoint(),
          stage.dataPeopleTransfer.toLocationPoint
        );
      }

      stage.dataPeopleTransfer = Object.assign(
        new ReservedStagePeopleTransfer(),
        stage.dataPeopleTransfer
      );
    }

    if (stage.dataActivity) {
      if (stage.dataActivity.activityOption) {
        if (stage.dataActivity.activityOption.activity) {
          stage.dataActivity.activityOption.activity = Object.assign(
            new Activity(),
            stage.dataActivity.activityOption.activity
          );
        }
        stage.dataActivity.activityOption = Object.assign(
          new ActivityOption(),
          stage.dataActivity.activityOption
        );
      }
      stage.dataActivity = Object.assign(
        new ReservedStageActivity(),
        stage.dataActivity
      );
    }

    if (stage.dataAccommodation) {

      if (stage.dataAccommodation.luggagePoint) {
        stage.dataAccommodation.luggagePoint = Object.assign(
          new LocationPoint(),
          stage.dataAccommodation.luggagePoint
        );
      };

      if (stage.dataAccommodation.TransportType) {
        stage.dataAccommodation.TransportType = Object.assign(
          new TransportType(),
          stage.dataAccommodation.TransportType
        );
      }
      //walking ciclyng, public transport{id,transpor_type}

      stage.dataAccommodation = Object.assign(
        new ReservedStageAccommodation(),
        stage.dataActivity
      );
    }

    if (stage.dataMeal) {
      if (stage.dataMeal.time) {
        stage.dataMeal.time = new Date(stage.dataMeal.time);
      }
      if (stage.dataMeal.menu) {
        stage.dataMeal.menu = Object.assign(new Menu(), stage.dataMeal.menu);
      }

      stage.dataMeal = Object.assign(new ReservedStageMeal(), stage.dataMeal);
    }

    if (stage.dataGuide) {
      if (stage.dataGuide.meetingTime) {
        stage.dataGuide.meetingTime = new Date(stage.dataGuide.meetingTime);
      }
      if (stage.dataGuide.finishingTime) {
        stage.dataGuide.finishingTime = new Date(stage.dataGuide.finishingTime);
      }
      if (stage.dataGuide.meetingPoint) {
        stage.dataGuide.meetingPoint = Object.assign(
          new LocationPoint(),
          stage.dataGuide.meetingPoint
        );
      }

      if (stage.dataGuide.finishingPoint) {
        stage.dataGuide.finishingPoint = Object.assign(
          new LocationPoint(),
          stage.dataGuide.finishingPoint
        );
      }

      stage.dataGuide = Object.assign(
        new ReservedStageGuide(),
        stage.dataGuide
      );
    }

    if (stage.dataBike) {
      if (stage.dataBike.deliveryPoint) {
        stage.dataBike.deliveryPoint = Object.assign(
          new LocationPoint(),
          stage.dataBike.deliveryPoint
        );
      }
      if (stage.dataBike.collectionPoint) {
        stage.dataBike.collectionPoint = Object.assign(
          new LocationPoint(),
          stage.dataBike.collectionPoint
        );
      }
      if (stage.dataBike.defaultBikeOption) {
        stage.dataBike.defaultBikeOption = Object.assign(new BikeOption());
      }

      stage.dataBike = Object.assign(new ReservedStageBike(), stage.dataBike);
    }

    if (stage.modificationExtras) {
      for (let k = 0; k < stage.modificationExtras.length; k++) {
        stage.modificationExtras[k] = Object.assign(
          new ReservedStepModificationExtra(),
          stage.modificationExtras[k]
        );
      }
    }

    return Object.assign(new ReservedStage(), stage);
  }

  getOne(id): Observable<ReservedTrip> {
    const params = {};

    this.lastItem.next(null);
    return this.http
      .get(environment.urlApi + '/api/reserved_trips/' + id, { params: params })
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          this.lastItem.next(item);

          return item;
        })
      );
  }

  getItinerary(id): Observable<ReservedTrip> {
    const params = {};

    return this.http
      .get(environment.urlApi + '/api/reserved_trips/' + id + '/itinerary', {
        params: params,
      })
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          return item;
        })
      );
  }
  getTracks(id): Observable<ListResult<TrackFile>> {
    const params = {};

    return this.http
      .get(environment.urlApi + '/api/reserved_trips/' + id + '/tracks', {
        params: params,
      })
      .pipe(
        map((res) => {
          const result = new ListResult<TrackFile>();

          for (let i = 0; i < res['items'].length; i++) {
            result.items.push(Object.assign(new TrackFile(), res['items'][i]));
          }
          result.totalItems = res['totalItems'];
          return result;
        })
      );
  }

  getLastItem(): Observable<ReservedTrip> {
    return this.lastItem;
  }

  updateDepartureDate(id, date) {
    return this.http
      .put(
        environment.urlApi + '/api/reserved_trips/' + id + '/departure_date',
        { departureDate: date }
      )
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          this.lastItem.next(item);
          return item;
        })
      );
  }

  updatePassengers(id, single, double, twin, triple, dorm) {
    return this.http
      .put(environment.urlApi + '/api/reserved_trips/' + id + '/passengers', {
        nbSingle: single,
        nbDouble: double,
        nbTwin: twin,
        nbTriple: triple,
        nbDorm: dorm,
      })
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          this.lastItem.next(item);
          return item;
        })
      );
  }

  customizeType(id, data) {
    return this.http
      .put(
        environment.urlApi + '/api/reserved_trips/' + id + '/customize_type',
        data
      )
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          this.lastItem.next(item);
          return item;
        })
      );
  }

  customizeMeal(id, data) {
    return this.http
      .put(
        environment.urlApi + '/api/reserved_trips/' + id + '/customize_meal',
        data
      )
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          this.lastItem.next(item);
          return item;
        })
      );
  }

  changeLuggage(id, data) {
    return this.http
      .put(
        environment.urlApi + '/api/reserved_trips/' + id + '/change_luggage',
        data
      )
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          this.lastItem.next(item);
          return item;
        })
      );
  }

  ownAccommodation(id, data) {
    return this.http
      .put(
        environment.urlApi + '/api/reserved_trips/' + id + '/own_accommodation',
        data
      )
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          this.lastItem.next(item);
          return item;
        })
      );
  }
  updateNights(id, data) {
    return this.http
      .put(environment.urlApi + '/api/reserved_trips/' + id + '/nights', data)
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          this.lastItem.next(item);
          return item;
        })
      );
  }

  createTaxi(
    id,
    date: Date,
    hour: number,
    minute: number,
    fromId: number,
    toId: number,
    pax: number,
    flightNumber: string
  ) {
    let data = {
      date: date,
      hour: hour,
      minute: minute,
      from: fromId,
      to: toId,
      pax: pax,
      flightNumber,
    };
    return this.http
      .post(environment.urlApi + '/api/reserved_trips/' + id + '/taxi', data)
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          this.lastItem.next(item);
          return item;
        })
      );
  }

  setFlightNumber(id, data) {
    return this.http
      .put(
        environment.urlApi + '/api/reserved_trips/' + id + '/flight_number',
        data
      )
      .pipe(
        map((res) => {
          //const item = this.unserializeItem(res);
          //this.lastItem.next(item);
          //return item;
        })
      );
  }

  setPickupTime(id, data) {
    return this.http
      .put(
        environment.urlApi + '/api/reserved_trips/' + id + '/pickup_time',
        data
      )
      .pipe(
        map((res) => {
          //const item = this.unserializeItem(res);
          //this.lastItem.next(item);
          //return item;
        })
      );
  }

  removeStages(id, data) {
    return this.http
      .put(
        environment.urlApi + '/api/reserved_trips/' + id + '/delete_stages',
        data
      )
      .pipe(
        map((res) => {
          const item = this.unserializeItem(res);
          this.lastItem.next(item);
          return item;
        })
      );
  }

  createOrder(id, data) {
    return this.http.post(
      environment.urlApi + '/api/reserved_trips/' + id + '/create_order',
      data
    );
  }

  getHolidayPack(
    id: string,
    downloadable: boolean = false
  ): Observable<HolidayPack> {
    return this.http
      .get(
        environment.urlApi +
        '/api/reserved_trips/' +
        id +
        '/hpi_summary?type=' +
        (downloadable ? 'download' : 'post')
      )
      .pipe(
        map((res) => {
          const item = this.unserializeHolidayPack(res);

          return item;
        })
      );
  }

  getVoucher(id: string, fileName: string) {
    this.http
      .get(environment.urlApi + '/api/reserved_trips/' + id + '/voucher', {
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .subscribe((response: HttpResponse<Blob>) => {
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(response.body);

        if (
          typeof window['webkit'] !== 'undefined' &&
          window['webkit'] !== null &&
          typeof window['webkit'].messageHandlers !== 'undefined' &&
          window['webkit'].messageHandlers !== null &&
          typeof window['webkit'].messageHandlers.sendBase64 !== 'undefined' &&
          typeof window['webkit'].messageHandlers.sendBase64 !== null
        ) {
          var reader = new FileReader();
          reader.readAsDataURL(response.body);
          reader.onloadend = function () {
            window['webkit'].messageHandlers.sendBase64.postMessage({
              base64: reader.result,
              mime: 'application/pdf',
              name: 'Voucher',
            });
          };

          return;
        }
        if (!fileName) {
          const contentDisposition = response.headers.get(
            'content-disposition'
          );
          if (contentDisposition) {
            const match = contentDisposition.match(
              /^attachment;\s*filename="([^"]*)"/
            );
            if (match) {
              fileName = match[1];
            }
          }
        }
        if (fileName) {
          downloadLink.setAttribute('download', fileName);
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
  }

  getElevationSvg(id: string, day: number) {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');

    return this.http.get(
      this.getElevationSvgUrl(id, day),
      { headers, responseType: 'text' }
    );
  }

  getElevationSvgUrl(id: string, day: number) {
    return environment.urlApi + '/api/reserved_trips/' + id + '/elevation/' + day;
  }

  getRouteData(id: string, day: number, params?): Observable<ListResult<RouteChunk>> {
    //reservedTrip
    if (params == null) {
      params = { itemsPerPage: "10", page: "1" };
    }

    return this.http.get(environment.urlApi + '/api/reserved_trips/' + id + '/data/' + day, { params: params }).pipe(map(
      res => {
        const result = new ListResult<RouteChunk>();

        for (let i = 0; i < res['items'].length; i++) {
          result.items.push(this.unserializeRouteChunk(res['items'][i]));
        }
        result.totalItems = res['totalItems'];
        return result;
      }
    ));
  }


  getWeatherData(id: string) {
    return this.http.get(
      environment.urlApi + '/api/reserved_trips/' + id + '/weather',
      { params: { type: 'map' } }
    );
  }

  getForecastData(id: string) {
    return this.http.get(
      environment.urlApi + '/api/reserved_trips/' + id + '/weather',
      { params: { type: 'forecast' } }
    );
  }

  getOrderError(id: string, paymentId: string): Observable<string> {
    return this.http
      .get(
        environment.urlApi +
        '/api/reserved_trips/' +
        id +
        '/order_errors/' +
        paymentId
      )
      .pipe(
        map((res) => {
          return res['error'];
        })
      );
  }
  unserializeRouteChunk(item: any): RouteChunk {
    if (item.fromPoint) {
      item.fromPoint = Object.assign(
        new LocationPoint(),
        item.fromPoint
      );
    }
    if (item.toPoint) {
      item.toPoint = Object.assign(
        new LocationPoint(),
        item.toPoint
      );
    }
    if (item.transportType) {
      item.transportType = Object.assign(
        new TransportType(),
        item.transportType
      );
    }
    return Object.assign(new Supplier(), item);
  }

  unserializeSupplier(item) {
    if (item.dataAccommodation) {
      if (item.dataAccommodation.breakfastFromTime) {
        item.dataAccommodation.breakfastFromTime = new Date(
          item.dataAccommodation.breakfastFromTime
        );
      }
      if (item.dataAccommodation.breakfastToTime) {
        item.dataAccommodation.breakfastToTime = new Date(
          item.dataAccommodation.breakfastToTime
        );
      }
      if (item.dataAccommodation.lunchFromTime) {
        item.dataAccommodation.lunchFromTime = new Date(
          item.dataAccommodation.lunchFromTime
        );
      }
      if (item.dataAccommodation.lunchToTime) {
        item.dataAccommodation.lunchToTime = new Date(
          item.dataAccommodation.lunchToTime
        );
      }
      if (item.dataAccommodation.dinnerFromTime) {
        item.dataAccommodation.dinnerFromTime = new Date(
          item.dataAccommodation.dinnerFromTime
        );
      }
      if (item.dataAccommodation.dinnerToTime) {
        item.dataAccommodation.dinnerToTime = new Date(
          item.dataAccommodation.dinnerToTime
        );
      }

      item.dataAccommodation = Object.assign(
        new SupplierAccommodation(),
        item.dataAccommodation
      );
    }

    if (item.dataRestaurant) {
      item.dataRestaurant = Object.assign(
        new SupplierRestaurant(),
        item.dataRestaurant
      );
    }

    if (item.ratings) {
      for (let k = 0; k < item.ratings.length; k++) {
        item.ratings[k] = Object.assign(new SupplierRating(), item.ratings[k]);
      }
    }

    if (item.dataBikeOptions) {
      for (let k = 0; k < item.dataBikeOptions.length; k++) {
        item.dataBikeOptions[k] = Object.assign(
          new BikeOption(),
          item.dataBikeOptions[k]
        );
      }
    }

    if (item.dataBikeOptionalItems) {
      for (let k = 0; k < item.dataBikeOptionalItems.length; k++) {
        item.dataBikeOptionalItems[k] = Object.assign(
          new BikeOptionalItem(),
          item.dataBikeOptionalItems[k]
        );
      }
    }

    return Object.assign(new Supplier(), item);
  }

  unserializeHolidayPack(item) {
    if (item.tripItems) {
      for (let k = 0; k < item.tripItems.length; k++) {
        item.tripItems[k] = this.unserializePackItem(item.tripItems[k]);
      }
    }
    if (item.reservedTripLinks) {
      for (let k = 0; k < item.reservedTripLinks.length; k++) {
        item.reservedTripLinks[k] = this.unserializePackItem(
          item.reservedTripLinks[k]
        );
      }
    }
    if (item.reservedTripActivities) {
      for (let k = 0; k < item.reservedTripActivities.length; k++) {
        if (item.reservedTripActivities[k].item) {
          item.reservedTripActivities[k].item = this.unserializePackItem(
            item.reservedTripActivities[k].item
          );
        }
        if (item.reservedTripActivities[k].dueDate) {
          item.reservedTripActivities[k].dueDate = new Date(
            this.tzString(item.reservedTripActivities[k].dueDate)
          );
        }
        item.reservedTripActivities[k] = Object.assign(
          new ReservedTripHolidayPackItemActivity(),
          item.reservedTripActivities[k]
        );
      }
    }
    return Object.assign(new HolidayPack(), item);
  }

  unserializePackItem(item): HolidayPackItem {
    if (item.type) {
      if (item.type.icon) {
        item.type.icon = Object.assign(new DBFile(), item.type.icon);
      }

      item.type = Object.assign(new HolidayPackItemType(), item.type);
    }
    if (item.link) {
      item.link = Object.assign(new HolidayPackItemLink(), item.link);
    }
    if (item.fileName) {
      item.fileName = Object.assign(
        new HolidayPackItemFileName(),
        item.fileName
      );
    }
    return Object.assign(new HolidayPackItem(), item);
  }
}
