import { Supplier } from "./supplier.model";

class LocationPointCategory {
    public name: string;
}
export class LocationPoint {
    public id: number;
    public latitude: number;
    public longitude: number;
    public name: string;
    public supplier: Supplier;
    public category: LocationPointCategory;
    public icon:string;

    setIcon(){
        if(this.category){
            switch(this.category.name){
                case 'City':
                    this.icon='city';
                    break;
                case 'Airport':
                    this.icon='plane-departure';
                    break;
                case 'Accommodation':
                    this.icon='h-square';
                    break;
                case 'Activity':
                    this.icon='camera';
                    break;
                case 'Restaurant':
                    this.icon='utensils';
                    break;
                default:
                    this.icon='map-pin';
            }
        } else{
            this.icon='map-pin';
        }

    }
}