import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from './translate.service';


@Pipe(
    {
        name: 'trans'
    }
)
export class TranslatePipe implements PipeTransform {
    //private values = {};

    constructor(
        private service:TranslateService,
        private _ref: ChangeDetectorRef
        ){
    }

    transform(value: string): Observable<string> {
        return this.service.getItem(value);
    }
}