import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { TranslatePipe } from "./tranlate.pipe";
import { TranslateConfig, TranslateServiceConfig } from "./translate-config.interface";
import { TranslateService } from "./translate.service";

@NgModule({
    declarations: [
        TranslatePipe
    ],
    imports:[
        HttpClientModule
    ],
    exports:[
        TranslatePipe
    ]
})
export class TranslateModule {
    static forRoot(config: TranslateConfig): ModuleWithProviders<TranslateModule> {
        return {
            ngModule: TranslateModule,
            providers: [
                TranslateService,
                {
                    provide: TranslateServiceConfig,
                    useValue: config
                }
            ]
        }
    }
}