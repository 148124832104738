import { ReservedPassenger } from "./reserved-passenger.model";

export class PassengerBalanceSelection {
    public passenger: ReservedPassenger;
    public amount: number;
    public paid: number;
    public balanceSelected: boolean;
    public depositSelected: boolean;
    public otherSelected: boolean;
    public depositValue: number;
    public otherValue: number;
}