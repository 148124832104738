import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { CurrencySymbolPipe } from "./currency-symbol.pipe";
import { ExchangePipe } from "./exchange.pipe";

@NgModule({
    declarations: [
        ExchangePipe,
        CurrencySymbolPipe,
    ],
    imports:[
        HttpClientModule
    ],
    exports:[
        ExchangePipe,
        CurrencySymbolPipe
    ]
})
export class ExchangeModule {

}