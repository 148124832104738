export class PhoneArea {
    public id: number;
    public isoCode: string;
    public countryNameKeyword: string;
    public number: string = "";
    public name: string = "";
    public flag: string;


    public setFlag() {
        if (!this.flag && this.isoCode) {
            let iso = this.isoCode;
            const pos = iso.indexOf('(');
            if (pos > -1) {
                iso = iso.substring(0, pos);
            }
            this.flag = "/assets/flags/4x3/" + iso.toLowerCase() + ".svg";
        }
    }
}