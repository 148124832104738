import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, finalize, map} from 'rxjs/operators'
import {LoadingService} from './loading.service';

/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 * @class {HttpRequestInterceptor}
 */
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor(
    private _loading: LoadingService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this._loading.setLoading(true, request.urlWithParams);
    
    return next.handle(request).pipe(
      finalize(() => {
        this._loading.setLoading(false, request.urlWithParams);
        // request completes, errors, or is cancelled
      }))
      /*
      .pipe(catchError((err) => {
        console.log(err);
        this._loading.setLoading(false, request.urlWithParams);
        return throwError(err);
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
      //  console.log(evt);
        if (evt instanceof HttpResponse) {
          this._loading.setLoading(false, request.urlWithParams);
        } else {
        //  console.log(evt);
        }
        return evt;
      }));
      */
  }
}