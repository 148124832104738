import { Pricing } from "./pricing.model";
import { ReservedDay } from "./reserved-day.model";
import { ReservedPassenger } from "./reserved-passenger.model";
import { ReservedStage } from "./reserved-stage.model";
import { Trip } from "./trip.model";

export class ReservedTrip {
    public id: string;
    public reference: string;
    public departureDate: Date;
    public trips: Array<Trip>;
    public stages: Array<ReservedStage>;
    public days: Array<ReservedDay>;
    public code: string;
    public bookingStatus: string;
    public bookingStatusKeyword: string;
    public name: string;
    public fullName: string;
    public titleEng: string;
    public subtitleEng: string;
    public lastPricing: Pricing;
    public lastDate: Date = null;
    public canUpdateDepartureDate: boolean;
    public forceHideAccommodationName: boolean;
    public nbSingle: number;
    public nbDouble: number;
    public nbTriple: number;
    public nbDorm: number;
    public nbTwin: number;
    public pax: number;
    public servicesStage: boolean;
    public passengers: Array<ReservedPassenger>;
    public useAutoPricing: boolean;
    public combinedDiscount: number;
    public dueDate: Date;
    public voucherGeneratedDatetime: Date;
    public isAccommodationConfirmed: boolean;
    public expired: boolean;
    public depositPercentage: number;



    getCircleColor() {
        if (
            this.bookingStatus == 'InProgress' ||
            this.bookingStatus == 'InProgressClientPaid' ||
            this.bookingStatus == 'Postponed'
        ) {
            return 'orange';
        }
        if (this.bookingStatus == 'Confirmed') {
            return 'emerald';
        }
        if (
            this.bookingStatus == 'Cancelled' ||
            this.bookingStatus == 'ExpiredQuote'
        ) {
            return 'red';
        }
        if (this.bookingStatus == 'NewQuote') {
            return 'teal';
        }
        if (this.bookingStatus == 'To Be Confirmed') {
            return 'blueGray';
        }
    }

    //TODO: PASS METHODS TO METHODS IN THE API
    getPaid() {
        return (this.lastPricing.paymentStatus.amountOwed <=
            this.lastPricing.paymentStatus.amountPaid)
    }



    getShowWarningInsteadDetails() {
        return (this.bookingStatus == 'NewQuote' || this.bookingStatus == 'InProgress' || this.bookingStatus == 'InProgressClientPaid')

    }

    getTitle() {
        let title = '';
        for (let i = 0; i < this.trips.length; i++) {
            title += ((i > 0) ? ' / ' : '') + this.trips[i].titleEng;
        }
        return title;
    }

    getSubtitle() {
        let subtitle = '';
        for (let i = 0; i < this.trips.length; i++) {
            subtitle += ((i > 0) ? ' / ' : '') + this.trips[i].subtitleEng;
        }
        return subtitle;
    }

    getShowVoucher() {
        return this.getPaid(); //|| this.isAccommodationConfirmed as Carmen's request 
    }

    isPayable() {
        return (
            this.bookingStatus == 'NewQuote' ||
            this.bookingStatus == 'To Be Confirmed' ||
            this.bookingStatus == 'Confirmed' ||
            this.bookingStatus == 'InProgress' ||
            this.bookingStatus == 'InProgressClientPaid'
        ) && !this.expired;
    }

    isExpired() {
        return (this.expired || this.bookingStatus == 'ExpiredQuote');
    }

    isInProgress() {
        return (this.bookingStatus == 'InProgress' || this.bookingStatus == 'InProgressClientPaid')
    }

    //we can use the departure date too
    getHideAccommodation() {
        return (this.forceHideAccommodationName);// || this.bookingStatus == 'InProgress' || this.bookingStatus == 'InProgressClientPaid')
    }


    isDepositPayable() {
        return this.isPayable() && this.dueDate > new Date();
    }

    getShowMap() {
        return (this.bookingStatus == 'To Be Confirmed' ||
            this.bookingStatus == 'Confirmed' ||
            this.bookingStatus == 'InProgress' ||
            this.bookingStatus == 'InProgressClientPaid'
        ) && !this.expired;
    }
}