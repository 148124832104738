import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class ExchangeService {
    private when: Date;
    private exchanges: Map<string, number> = new Map();
    private exchangeValues: Map<number, BehaviorSubject<number>> = new Map();
    private currency: string = null;
    private loaded = false;

    constructor(private http: HttpClient) {
        this.currency = localStorage.getItem('Other-Currency');
        if (!this.currency) {
            this.currency = 'EUR';
        }

        this.http.get(environment.urlApi + '/api/exchange_ratios').subscribe(
            res => {
                this.when = new Date(res['time']);
                for (let currency in res['map']) {
                    this.exchanges.set(currency, res['map'][currency]);
                }
                this.loaded = true;
                this.emit();
            }
        )
    }

    changeCurrency(currency: string) {
        this.currency = currency;
        localStorage.setItem('Other-Currency', currency);
        this.emit();
    }

    getCurrency() {
        return this.currency;
    }

    getWhen() {
        return this.when;
    }

    getExchangeValue(value: number): Observable<number> {
        if (!this.exchangeValues.has(value)) {
            this.exchangeValues.set(value, new BehaviorSubject<number>(0));
        }
        if (this.loaded) {
            this.exchangeValues.get(value).next(value*this.exchanges.get(this.currency));
        }
        return this.exchangeValues.get(value);
    }

    private emit() {
        for (let [key, value] of this.exchangeValues) {
            value.next(key * this.exchanges.get(this.currency));
        }
    }
}