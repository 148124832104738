import { Component, OnInit } from '@angular/core';
import { JwtService } from 'src/app/utils/jwt/jwt.service';
import { User } from 'src/app/utils/jwt/user.model';
import { ReservedTrip } from 'src/app/models/reserved-trip.model';
import { ReservedTripService } from 'src/app/services/reserved-trip.service';
import isWebview from 'is-ua-webview';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
})
export class LandingComponent implements OnInit {
  public name: string = '';
  private user: User;
  public nextTrips: Array<ReservedTrip> = [];
  private nextTripsPage: number = 1;
  private nextTripsTotalItems: number = 0;
  public previousTrips: Array<ReservedTrip>;
  private previousTripsPage: number = 1;
  private previousTripsTotalItems: number = 0;
  public nextTrip: ReservedTrip = null;
  private today: Date;
  private _MS_PER_DAY = 1000 * 60 * 60 * 24;
  public nextTripDaysInterval: number;
  public nextTripReference: string;
  public nextTripCode: string;
  public todayString: string;
  public nextTripId: string;

  constructor(
    private jwtService: JwtService,
    private rtService: ReservedTripService
  ) {}

  ngOnInit(): void {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    };

    this.today = new Date();
    this.todayString = this.today.toLocaleDateString('en-US', options);

    this.jwtService.getCurrentUserSubject().subscribe((res) => {
      if (res) {
        this.user = res;
        this.name = this.user.firstName;
      }
    });

    let isWebView = isWebview(window.navigator.userAgent);
    isWebView = false;

    this.rtService.getList({ itemPerPage: 10, page: 1, wich: 'new' }).subscribe(
      (res) => {
        this.nextTrips = res.items;
        this.nextTripsTotalItems = res.totalItems;

        if (this.nextTrips.length > 0) {
          // && this.trips[0].departureDate > this.today){

          for (let trip of this.nextTrips) {
            if (
              trip.bookingStatus.toUpperCase() !== 'EXPIREDQUOTE' &&
              trip.bookingStatus.toUpperCase() != 'CANCELLED'
            ) {
              if (!this.nextTrip) {
                this.nextTrip = trip;
                this.nextTripDaysInterval = this.dateDiffInDays(
                  this.today,
                  trip.departureDate
                );
                this.nextTripReference = trip.reference;
                this.nextTripCode = trip.getTitle();
                this.nextTripId = trip.id;
              }

              if (!isWebView) {
                break;
              }

              this.rtService.getOne(trip.id).subscribe();
              this.rtService.getItinerary(trip.id).subscribe();
            }
          }
        }
      },
      (err) => {}
    );

    this.rtService.getList({ itemPerPage: 10, page: 1, wich: 'old' }).subscribe(
      (res) => {
        this.previousTrips = res.items;
        this.previousTripsTotalItems = res.totalItems;
        if (isWebView) {
          for (let trip of this.previousTrips) {
            if (
              trip.bookingStatus.toUpperCase() !== 'EXPIREDQUOTE' &&
              trip.bookingStatus.toUpperCase() != 'CANCELLED'
            ) {
              this.rtService.getOne(trip.id).subscribe();
              this.rtService.getItinerary(trip.id).subscribe();
            }
          }
        }
      },
      (err) => {}
    );
  }

  // a and b are javascript Date objects
  dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / this._MS_PER_DAY);
  }
}
