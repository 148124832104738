import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class AcceptDenyService {

    constructor(private http: HttpClient) {
    }

    answer(passengerId, answer, token, password = null): Observable<any> {
        return this.http.put(environment.urlApi +
            '/public/reserved_passengers/' + passengerId + '/' + answer + '/' + token, { password: password});
    }
}