import { Component, Input, OnInit } from "@angular/core";
import { ReservedDay } from "src/app/models/reserved-day.model";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reserved-bike-card",
  templateUrl: "./reserved-bike-card.component.html",
})
export class ReservedBikeCardComponent implements OnInit {
  @Input()
  public day: ReservedDay;

  @Input()
  public stage: ReservedStage;


  @Input()
  public reservedTrip: ReservedTrip;

  @Input()
  public left = true;

  public downloadHost: string;
  public urlMaps: string;
  public stringMaps: string;

  constructor() {
    this.downloadHost = environment.urlApi;
  }

  ngOnInit(): void {
    this.urlMaps = null;
    this.stringMaps = '';

    if (this.stage && this.stage.dataBike) {

      if (this.stage.isBeginDay(this.day)) {
        this.stringMaps = this.stage.fromLocationName;

        if (this.stage.dataBike.collectionPoint && this.stage.dataBike.collectionPoint.longitude) {
          this.urlMaps = 'https://www.google.com/maps/search/?api=1&query=' + this.stage.dataBike.collectionPoint.latitude + ',' + this.stage.dataBike.collectionPoint.longitude;
        }
      }
      else if (this.stage.isEndDay(this.day)) {
        this.stringMaps = this.stage.toLocationName;

        if (this.stage.dataBike.deliveryPoint && this.stage.dataBike.deliveryPoint.longitude) {
          this.urlMaps = 'https://www.google.com/maps/search/?api=1&query=' + this.stage.dataBike.deliveryPoint.latitude + ',' + this.stage.dataBike.deliveryPoint.longitude;
        }
      }
    }

  }
}