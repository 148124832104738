import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { ReservedPassengerService } from "src/app/services/reserved-passenger.service";
import { Subject } from "rxjs";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { PassengerBalanceSelection } from "src/app/models/passenger-balance-selection.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// other imports are here...
@Component({
  selector: "app-reserved-passenger-invoice-card",
  templateUrl: "./reserved-passenger-invoice-card.component.html",
})
export class ReservedPassengerInvoiceCardComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  public modified = false;

  @Input()
  public passenger: ReservedPassenger;

  @Input()
  public reservedTrip: ReservedTrip;

  public isLeader: boolean;
  public isMe: boolean;

  @Output()
  public select: EventEmitter<PassengerBalanceSelection> = new EventEmitter();


  constructor(
  ) {

  }

  ngOnInit(): void {

    this.isLeader = this.passenger.number == 1;
    this.isMe = this.passenger.me;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}