import { animate, style, transition, trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Subject } from "rxjs";
import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
import { RoomAllocationProfile } from "src/app/models/room-allocation-profile.model";
import { RoomAllocationSelection } from "src/app/models/room-allocation-selection.model";
import { RoomAllocation } from "src/app/models/room-allocation.model";

@Component({
    selector: "app-room-allocation-selection-card",
    templateUrl: "./room-allocation-selection-card.component.html",
    animations: [
        trigger(
          'inOutAnimation', 
          [
            transition(
              ':enter', 
              [
                style({ height: 0, opacity: 0 }),
                animate('1s ease-out', 
                        style({ height: '*', opacity: 1 }))
              ]
            ),
            
            transition(
              ':leave', 
              [
                style({ height: '*', opacity: 1 }),
                animate('1s ease-in', 
                        style({ height: 0, opacity: 0 }))
              ]
            )
            
          ]
        )
      ]
})
export class RoomAllocationSelectionCardComponent implements OnInit {
    @Input()
    public selection: RoomAllocationSelection;

    @Input()
    public selected:boolean;

    @Output()
    public remove:EventEmitter<ReservedPassenger>= new EventEmitter();

    
    public types: Map<String, String> = new Map([['Single', 'single'], ['Double', 'double'], ['Twin', 'twin'], ['Triple', 'triple'], ['Dorm', 'dorm']]);

    constructor() {
    }
    //stages same day
    ngOnInit() {
     
    }


    removePassenger(passenger){
        this.remove.emit(passenger);
    }


}
