import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { GroupBooking } from "src/app/models/group-booking.model";
import { ReservedDay } from "src/app/models/reserved-day.model";
import { ReservedStage } from "src/app/models/reserved-stage.model";
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-reserved-group-day-card",
  templateUrl: "./reserved-group-day-card.component.html",
  styleUrls: ["./reserved-group-day-card.component.css"]
})
export class ReservedGroupDayCardComponent implements OnInit {
  @Input()
  public day: ReservedDay;

  @Input()
  public group: GroupBooking;

  public starsArray = [0, 1, 2, 3, 4];

  public hasAccommodation: boolean = false;

  public lightVersion: boolean;

  constructor() {
    this.lightVersion = environment.lightVersion
  }

  ngOnInit(): void {
    this.hasAccommodation = this.day.accommodationStages.length > 0
  }

}
