export class PaymentStatus{
    public id:string;
    public amountOwed:number;
    public amountPaid:number;

    public getCircleColor(): string {
        if (this.amountOwed == this.amountPaid) {
            return 'emerald';
        }
        if (
           this.amountPaid > 0
        ) {
            return 'orange';
        }
        
        return 'red';
        
    }

    public getPercentage(): number
    {
        return Math.round(this.amountPaid*100/this.amountOwed);
    }

}