import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { HolidayPack } from "src/app/models/holiday-pack.model"; import { ReservedPassenger } from "src/app/models/reserved-passenger.model";
;
import { ReservedTrip } from "src/app/models/reserved-trip.model";
import { ReservedPassengerService } from "src/app/services/reserved-passenger.service";
import { ReservedTripService } from "src/app/services/reserved-trip.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
})
export class InvoiceComponent implements OnInit, OnDestroy {
  public reservedTrip: ReservedTrip;
  public loaded = false;
  private destroy$ = new Subject();
  public color = "light";

  public passengers: Array<ReservedPassenger> = [];
  public hasDiscount: boolean;
  public singleBase: number;
  public sharedBase: number;
  public single: number;
  public shared: number;
  public sharedExtraBase: number;
  public singleExtraBase: number;
  public sharedExtra: number;
  public singleExtra: number;

  constructor(
    private reservedTripService: ReservedTripService,
    private reservedPassengersService: ReservedPassengerService
  ) {
  }

  ngOnInit(): void {

    this.reservedTripService.getLastItem().subscribe(
      res => {
        if (res) {
          this.reservedTrip = res;

          this.hasDiscount = (
            this.reservedTrip.combinedDiscount > 0 ||
            this.reservedTrip.lastPricing.discount && this.reservedTrip.lastPricing.discount.base > 0 ||
            this.reservedTrip.lastPricing.commission && this.reservedTrip.lastPricing.commission.base > 0
          );

          this.singleBase = this.reservedTrip.lastPricing.roomPrice + this.reservedTrip.lastPricing.singleSupplement;
          this.sharedBase = this.reservedTrip.lastPricing.roomPrice;

          let singleBase = this.singleBase;
          let sharedBase = this.sharedBase;


          this.sharedExtraBase = null
          this.sharedExtra = 0;
          if (this.reservedTrip.lastPricing.extrasSharedTot != null && this.reservedTrip.lastPricing.extrasSharedTot!=0) {
            this.sharedExtraBase = this.reservedTrip.lastPricing.extrasSharedTot;
            this.sharedExtra = this.sharedExtraBase;
          }
          this.singleExtraBase = null;
          this.singleExtra = 0;
          if (this.reservedTrip.lastPricing.extrasSingleTot != null && this.reservedTrip.lastPricing.extrasSingleTot!=0) {
            this.singleExtraBase = this.reservedTrip.lastPricing.extrasSingleTot;
            this.singleExtra = this.singleExtraBase;
          }

          if (this.reservedTrip.combinedDiscount > 0) {
            singleBase -= this.reservedTrip.combinedDiscount;
            sharedBase -= this.reservedTrip.combinedDiscount;
          }

          this.single = singleBase;
          this.shared = sharedBase;

         

          if (this.reservedTrip.lastPricing.discount.base > 0) {
            this.single -= this.reservedTrip.lastPricing.discount.base * singleBase / 100;
            this.shared -= this.reservedTrip.lastPricing.discount.base * sharedBase / 100;

            this.singleExtra -= this.reservedTrip.lastPricing.discount.base * this.singleExtraBase / 100;
            this.sharedExtra -= this.reservedTrip.lastPricing.discount.base * this.sharedExtraBase / 100;
          }

          if (this.reservedTrip.lastPricing.commission.base > 0) {
            this.single -= this.reservedTrip.lastPricing.commission.base * singleBase / 100;
            this.shared -= this.reservedTrip.lastPricing.commission.base * sharedBase / 100;


            this.singleExtra -= this.reservedTrip.lastPricing.commission.base * this.singleExtraBase / 100;
            this.sharedExtra -= this.reservedTrip.lastPricing.commission.base * this.sharedExtraBase
          }


          this.reservedPassengersService.getList(
            this.reservedTrip,
            { page: 1, group: "payment" }
          ).subscribe(
            res => {
              this.passengers = res.items;
              this.loaded = true;
            }
          );
        }
      }
    );

  }


  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
